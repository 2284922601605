export default function truncate (input, length = 120, suffix = '...') {
  if (!input) {
    return input
  }

  if (!length || input.length <= length) {
    return input
  }

  input.trim()
  suffix = (typeof suffix === 'object') ? '' : suffix

  const lastWordSeparatorIndex = input.lastIndexOf(' ', length + 1)
  const cutIndex = lastWordSeparatorIndex > 0 ? lastWordSeparatorIndex : length
  return input.substr(0, cutIndex).replace(/([ ,.-:])$/, '') + suffix
}
