import Vue from 'vue'
import engine from './scripts/engine'
import api from './scripts/api'
import data from './scripts/data'
import events from './scripts/events'

export default async ({ store, app }) => {
  if (!engine) {
    console.error('engine is not initiated')
    return
  }

  const { PAYMENTS, PAYMENT_CONFIRMED } = app.$dict.routes

  const tracking = {
    initiated: false,
    api,
    data,
    events,
    players: {},
    reset (routeName) {
      this.events.resolveQueue()
      engine.reset()
      this.data.clearCurrentPageDataLog()
      if (!routeName || ![PAYMENTS, PAYMENT_CONFIRMED].includes(routeName)) {
        this.data.clearCheckoutProductDataLog()
      }
      this.events.setEventsOnPageRefreshPriority(this.events.priorities.LOW)
    },
    enableQueuing () {
      this.events.enableQueuing()
    },
    setPlayerStateAndRoute (holderId, playerState, playerRoute) {
      this.players[holderId] = {
        state: playerState,
        route: playerRoute
      }
    },
    getPlayerState (holderId) {
      return this.players[holderId]?.state
    },
    getPlayerRoute (holderId) {
      return this.players[holderId]?.route
    },
    init () {
      if (this.initiated) {
        return this
      }

      api.init({ trackingEngine: engine, trackingData: data })
      data.init({ store, app })
      events.init({ trackingAPI: api, trackingData: data })

      this.initiated = true
    }
  }

  tracking.init()
  app.$tracking = store.$tracking = Vue.prototype.$tracking = tracking
}
