export default {
  computed: {
    isFormValid () {
      return Object.keys(this.validity).every(item => this.validity[item])
    }
  },
  methods: {
    setFormValidity (name, value) {
      this.validity[name] = value
    }
  }
}
