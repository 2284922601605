import { PlayerEvent } from 'redgalaxy-player-web'
import VPaidIma from './vpaid-ima'

class AdvertModule {
  constructor (player) {
    this.player = player
    const adUrl = player.options.get('vmapUrl')
    if (!adUrl) {
      return
    }
    this.init = this.start.bind(this)
    this.destroy = this.destroyFn.bind(this)
    this.resume = this.resumeFn.bind(this)
    this.init()
    player.on(PlayerEvent.PLAY_INVOKED, this.init)
    player.on(PlayerEvent.DESTROY, this.destroy)
    player.on(PlayerEvent.BREAK_COMPLETE, this.resume)
    player.on(PlayerEvent.AD_START, () => {
      this.adStarted = true
    })
    player.on(PlayerEvent.AD_COMPLETE, () => {
      if (!this.adStarted) {
        this.resume()
        this.adStarted = false
      }
    })
  }

  start () {
    if (!this.started) {
      const player = this.player
      this.started = true
      player.options.set('autoplay', false)
      if (window.google && window.google.ima) {
        this.vpaidIma = new VPaidIma(player, player.options.get('vmapUrl'))
      }
    }
  }

  destroyFn () {
    const player = this.player
    const vpaidIma = this.vpaidIma

    if (vpaidIma) {
      vpaidIma.destroy()
    }

    player.off(PlayerEvent.PLAY_INVOKED, this.init)
    player.off(PlayerEvent.DESTROY, this.destroy)
    player.off(PlayerEvent.BREAK_COMPLETE, this.resume)
  }

  resumeFn () {
    const player = this.player
    player.options.set('autoplay', true)
    player.unFreeze()
    player.play()
  }
}

AdvertModule.enabled = true
AdvertModule.moduleId = 'advert'
export default AdvertModule
