export const ACTORS = 'actors'
export const COUNTRIES = 'countries'
export const DIRECTORS = 'directors'
export const DURATION = 'duration'
export const EPISODE = 'episode'
export const SCRIPTWRITERS = 'scriptwriters'
export const GENRES = 'genres'
export const SEASON = 'season'
export const SUBTITLE = 'subtitle'
export const YEAR = 'year'
export const RATING = 'rating'
export const SEASONS = 'seasons'
