import Vue from 'vue'
import VueCookie from 'vue-cookie'
import { Api } from '@/api'
import { AbtShield } from '@/assets/javascript/abtshield'
import axios from 'axios'

Vue.use(VueCookie)

export default function ({ $axios, store, env }) {
  const $cookie = Vue.prototype.$cookie
  const abtShieldAxiosInstance = axios.create({})
  store.$cookie = $cookie
  store.$api = new Api($axios, store, env)
  store.$abtShield = new AbtShield(abtShieldAxiosInstance)
}
