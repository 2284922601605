export default {
  SET (state, playlist = []) {
    state.playlist = playlist
  },
  ADD (state, item) {
    state.playlist.unshift({
      item,
      modifiedAt: this.$time().format('YYYY-MM-DD HH:mm:ss')
    })
  },
  REMOVE (state, itemId) {
    state.playlist = state.playlist
      .filter(({ item }) => item.id !== itemId)
  }
}
