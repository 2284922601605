export const MOBILE = 'mobile'
export const TABLET = 'tablet'
export const DESKTOP = 'desktop'
export const LARGE = 'large'
export const XLARGE = 'xlarge'

export const IS_MOBILE = 'is-mobile'
export const IS_TABLET = 'is-tablet'
export const IS_DESKTOP = 'is-desktop'
export const IS_LARGE = 'is-large'
export const IS_XLARGE = 'is-xlarge'
