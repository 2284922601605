export default {
  SET_APP_VERSION (state, appVersion) {
    state.appVersion = appVersion
  },
  SET_MAX_PROFILES_COUNT (state, maxProfilesCount) {
    state.maxProfilesCount = maxProfilesCount
  },
  SET_CLIENT_SERVER_DELAY (state, serverDate) {
    const serverTimeStamp = this.$time(Date.parse(serverDate))
    const now = this.$time()
    state.clientServerDelay = now.diff(serverTimeStamp)
  },
  SET_CMP_URL (state, cmpUrl) {
    state.cmpUrl = cmpUrl
  },
  SET_CMP_TENANT (state, tenant) {
    state.tenant = tenant
  }
}
