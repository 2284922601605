import {
  API_PROFILE_UID
} from '@/assets/javascript/enums/headers'

const PROFILE_COOKIE_EXPIRE = 365

export default {
  computed: {
    currentProfile () {
      return this.$store.getters['user/ACTIVE_PROFILE']
    },
    activeProfileId () {
      const activeProfile = this.currentProfile
      return activeProfile
        ? activeProfile.id
        : ''
    }
  },
  methods: {
    switchProfile (profile) {
      const currentProfileHash = this.$cookie.get('profile_uid')
      if (process.env.USE_PROXY) {
        this.$cookie.set('profile_uid', profile.uid, { expires: PROFILE_COOKIE_EXPIRE })
      } else {
        this.$cookie.set('profile_uid', profile.uid, { expires: PROFILE_COOKIE_EXPIRE, domain: process.env.COOKIE_DOMAIN })
      }
      this.$axios.setHeader(API_PROFILE_UID, profile.uid)

      if (profile.uid !== currentProfileHash) {
        return location.reload()
      }
    }
  }
}
