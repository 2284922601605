export default {
  GET (_, alias) {
    return this.$api.resources
      .documents
      .get(alias)
  },
  GET_CONTENT (_, alias) {
    return this.$api.resources
      .documents
      .getContent(alias)
  }
}
