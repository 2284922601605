import { PlayerEvent } from 'redgalaxy-player-web'
import { POD_CHANGED, SEND_EVENT } from '@/plugins/player/modules/advert/enums'
import { checkAutoplaySupport } from '@/components/player/utils/check-autoplay-support'

const AdEventList = [
  'ALL_ADS_COMPLETED',
  'COMPLETE',
  'AD_BREAK_READY',
  'STARTED',
  'PAUSED',
  'RESUME',
  'RESUMED',
  'LOADED',
  'CONTENT_RESUME_REQUESTED',
  'CONTENT_PAUSE_REQUESTED',
  'SKIP',
  'SKIPPED',
  'CLICK',
  'IMPRESSION',
  'USER_CLOSE',
  'VIDEO_CLICKED',
  'FIRST_QUARTILE',
  'MIDPOINT',
  'THIRD_QUARTILE'
]

const AUTOPLAY_FAIL_TIMEOUT = 1000

function VPaidIMA (player, url) {
  const IMA = window.google.ima
  let adsInitialized, autoplayAllowed, autoplayRequiresMuted, wasClicked, playButtonListener
  let isEnded = false

  const contentPlayHeadTracker = {
    currentTime: 0,
    previousTime: 0,
    seeking: false,
    duration: 0
  }

  player.on(PlayerEvent.TIME, (time, duration) => {
    contentPlayHeadTracker.currentTime = time
    contentPlayHeadTracker.duration = duration
  })

  player.on(PlayerEvent.SEEK_START, () => {
    contentPlayHeadTracker.seeking = true
  })

  player.on(PlayerEvent.SEEK_END, () => {
    contentPlayHeadTracker.seeking = false
  })

  player.on(PlayerEvent.ENDED, onEnded)
  player.on(PlayerEvent.STOPPED, onEnded)

  let adsManager
  let currentPod
  let adWasClicked = false
  const adContainer = document.getElementById('ad-container')
  const playButton = document.getElementById('play-button')
  playButton.style.display = 'none'
  playButton.addEventListener('click', playButtonListener = () => {
    if (adWasClicked) {
      playButton.style.display = 'none'
      adWasClicked = false
      return adsManager.resume()
    }

    wasClicked = true
    adsInitialized = true
    playButton.style.display = 'none'
    adDisplayContainer.initialize()
    playAds()
  })
  checkAutoplaySupport(AUTOPLAY_FAIL_TIMEOUT)
    .then(({ isAutoplayAllowed, isMutedAutoplayAllowed }) => {
      if (!isMutedAutoplayAllowed && !isAutoplayAllowed) {
        autoplayAllowed = false
        autoplayRequiresMuted = true
      } else if (!isAutoplayAllowed) {
        autoplayAllowed = false
        autoplayRequiresMuted = false
      } else {
        autoplayAllowed = true
        autoplayRequiresMuted = false
      }

      autoplayChecksResolved()
    })

  IMA.settings.setLocale('pl')
  IMA.settings.setVpaidMode(IMA.ImaSdkSettings.VpaidMode.ENABLED)

  const adDisplayContainer = new IMA.AdDisplayContainer(adContainer, player.getRendererElm())
  const adsLoader = new IMA.AdsLoader(adDisplayContainer)
  const adsRequest = new IMA.AdsRequest()

  adsLoader.addEventListener(IMA.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onManagerLoaded)
  adsLoader.addEventListener(IMA.AdErrorEvent.Type.AD_ERROR, onAdsLoaderError)
  window.addEventListener('resize', resize)
  window.addEventListener('fullscreenchange', resize)

  adsRequest.adTagUrl = url
  let intervalTimer = 0

  function autoplayChecksResolved () {
    adsRequest.setAdWillAutoPlay(autoplayAllowed)
    adsRequest.setAdWillPlayMuted(autoplayRequiresMuted)
    adsLoader.requestAds(adsRequest)
  }

  function onAdsLoaderError () {
    destroy()
    destroyIma()
    playButton.removeEventListener('click', playButtonListener)

    player.trigger(PlayerEvent.AD_COMPLETE)
    player.trigger(PlayerEvent.AD_END)
  }

  function onEnded () {
    if (isEnded) {
      return
    }

    isEnded = true
    if (adsLoader) {
      adsLoader.contentComplete()
    }
  }

  function onManagerLoaded (adsManagerLoadedEvent) {
    const adsRenderingSettings = new IMA.AdsRenderingSettings()
    adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true
    adsRenderingSettings.loadVideoTimeout = 3000

    adsManager = adsManagerLoadedEvent.getAdsManager(contentPlayHeadTracker, adsRenderingSettings)
    adsManager.setVolume(0.5)

    AdEventList.forEach(evt => {
      adsManager.addEventListener(IMA.AdEvent.Type[evt], onAddEvent)
    })

    adsManager.addEventListener(IMA.AdEvent.AD_ERROR, onAddError)

    if (autoplayAllowed) {
      playAds()
    } else {
      playButton.style.display = 'block'
    }
  }

  const playerContainer = player.getRendererElm()

  function resize () {
    if (adsManager) {
      const width = playerContainer?.offsetWidth || window.innerWidth
      const height = playerContainer?.offsetHeight || window.innerHeight

      adsManager.resize(width, height, IMA.ViewMode.NORMAL)
    }
  }

  function pause () {
    adsManager.pause()
  }

  function onAddError (error) {
    console.log('onAddError: ', error)
  }

  function onAddEvent (adEvent) {
    const ad = adEvent.getAd()
    const AdEventType = IMA.AdEvent.Type
    const POST_ROLL_POD_INDEX = -1

    let adPodInfo
    let podIndex
    switch (adEvent.type) {
      case AdEventType.CONTENT_RESUME_REQUESTED:
        adPodInfo = ad.getAdPodInfo()
        player.trigger(PlayerEvent.AD_END, { wasClicked })

        try {
          podIndex = adPodInfo.getPodIndex()
        } catch {}

        if (podIndex !== POST_ROLL_POD_INDEX) {
          player.unFreeze()
          player.play()
        }

        currentPod = undefined

        if (!isEnded) {
          breakComplete()
        }
        break
      case AdEventType.CONTENT_PAUSE_REQUESTED:
        player.trigger(PlayerEvent.AD_START)
        player.pause()
        player.freeze()
        break
      // case AdEventType.LOADED:
        // adsManager.setVolume(player.getVolume() / 100)
        // break
      case AdEventType.PAUSED:
        adWasClicked = true
        playButton.style.display = 'block'
      break
      case AdEventType.CLICK:
        player.trigger(SEND_EVENT, { ad, eventType: 'ClickTracking' })
        break
      case AdEventType.USER_CLOSE:
      case AdEventType.VIDEO_CLICKED:
      case AdEventType.MIDPOINT:
      case AdEventType.FIRST_QUARTILE:
      case AdEventType.THIRD_QUARTILE:
      case AdEventType.IMPRESSION:
        player.trigger(SEND_EVENT, { ad, eventType: adEvent.type })
        break
      case AdEventType.SKIP:
      case AdEventType.SKIPPED:
        playButton.style.display = 'none'
        adWasClicked = false
        player.trigger(SEND_EVENT, { ad, eventType: AdEventType.SKIPPED })
        breakComplete()
        break
      case AdEventType.RESUME:
      case AdEventType.RESUMED:
        playButton.style.display = 'none'
        adWasClicked = false
        break
      case AdEventType.ALL_ADS_COMPLETED:
        playButton.style.display = 'none'
        adWasClicked = false
        destroy()
        destroyIma()
        player.trigger(PlayerEvent.AD_COMPLETE)
        break
      case AdEventType.COMPLETE:
        player.trigger(SEND_EVENT, { ad, eventType: AdEventType.COMPLETE })
        clearInterval(intervalTimer)
        adPodInfo = ad.getAdPodInfo()
        break
      case AdEventType.STARTED:
        player.trigger(SEND_EVENT, { ad, eventType: AdEventType.STARTED })
        adPodInfo = ad.getAdPodInfo()

        if (typeof currentPod === 'undefined' || adPodInfo.getPodIndex() !== currentPod.index) {
          currentPod = new Pod()
          currentPod.index = adPodInfo.getPodIndex()
          currentPod.duration = adPodInfo.getMaxDuration()
        }

        // adsManager.setVolume(player.getVolume() / 100)
        clearInterval(intervalTimer)

        intervalTimer = setInterval(
          () => {
            const remainingTime = adsManager.getRemainingTime()
            if (remainingTime >= 0) {
              currentPod.remainingDuration = remainingTime
              player.trigger(POD_CHANGED, currentPod)
            }
          },
          400)
        break
    }
  }

  function breakComplete () {
    clearInterval(intervalTimer)
    // player.trigger(PlayerEvent.BREAK_COMPLETE)
  }

  function destroy () {
    clearInterval(intervalTimer)
    setTimeout(() => {
      window.removeEventListener('resize', resize)
      window.removeEventListener('fullscreenchange', resize)
      player.off(PlayerEvent.ENDED, onEnded)
      player.off(PlayerEvent.STOPPED, onEnded)
    }, 1)
  }

  function destroyIma () {
    clearInterval(intervalTimer)
    if (adsManager) {
      adsManager.stop()
    }
  }

  function playAds () {
    try {
      if (!adsInitialized) {
        adDisplayContainer.initialize()
        adsInitialized = true
      }
      adsManager.init(window.innerWidth, window.innerHeight, IMA.ViewMode.NORMAL)
      adsManager.start()
    } catch (adError) {
      onAdsLoaderError()
    }
  }

  return {
    destroy: destroy,
    pause: pause
  }
}

function Pod () {
  this.remainingDuration = -1
  this.duration = -1
  this.index = -1
  this.total = -1
}

export default VPaidIMA
