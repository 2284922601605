import { SYNC_CLIENT_TIME_WITH_BO_TIME } from '@/api/enums'

export function Info ($http) {
  return {
    get () {
      return $http.get('/info', {
        headers: {
          [SYNC_CLIENT_TIME_WITH_BO_TIME]: true
        }
      })
    }
  }
}
