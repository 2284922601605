import { LIVE } from '@/assets/javascript/enums/video-types'
import { MIN_VOLUME, MAX_VOLUME } from 'atds-player-layout/src/enums'

const DEFAULT_CONFIG = {
  width: '100%',
  height: 'auto',
  controls: false,
  hlsjs: false,
  autoplayBlockedRule: 'mute'
}

export function defaultAudioLanguage (state) {
  return state?.settings?.audio?.lang || DEFAULT_CONFIG.defaultAudioLanguage
}

export function transformConfiguration ({ timeshiftDvrWindow, videoSession, tracking, bookmark, adSessionId }, { videoType }, state) {
  const timeshift = { dvrWindow: timeshiftDvrWindow }

  let config = {
    volume: state.mute ? MIN_VOLUME : state.volume || MAX_VOLUME
  }

  const settings = state.settings
  if (settings) {
    const { audio, profile, subtitle } = settings
    const subtitleLang = subtitle?.language
    config = {
      ...config,
      ...(audio?.lang && { defaultAudioLanguage: audio?.lang }),
      ...(profile?.bandwidth && typeof profile.bandwidth === 'number' && { defaultBitrate: profile.bandwidth / 1000 }),
      volume: settings.mute ? MIN_VOLUME : settings.volume || MAX_VOLUME,
      ...(subtitleLang && { defaultSubtitle: subtitleLang }),
      ...(profile && { autoAdaptation: profile.bandwidth === 'auto' })
    }
  }

  if (videoType === LIVE) {
    config.liveDelay = 20
  }

  if (timeshift?.dvrWindow && videoType === LIVE) {
    config.timeShift = timeshift
    config.liveDelay = 25
  }

  if (videoSession && videoSession.videoSessionId) {
    config.videoSessionId = videoSession.videoSessionId
  }

  if (tracking) {
    config.tracking = {
      customerId: tracking.customerId,
      collector: tracking.url,
      productId: tracking.contentId,
      subscriberId: tracking.subscriberId,
      sessionId: tracking.sessionId,
      customData: tracking.customData
    }
  }

  return {
    ...DEFAULT_CONFIG,
    ...config,
    videoSession,
    videoType,
    bookmark,
    adSessionId
  }
}
