import {
  ACTION,
  ECOMMERCE,
  FORM,
  PAGE,
  PAGE_VIEW,
  USER,
  VIDEO,
  SEARCH, SECONDARY
// eslint-disable-next-line import/no-duplicates
} from '@/assets/javascript/dictionaries/gtmEvents'

// eslint-disable-next-line import/no-duplicates
import * as ALL_EVENTS from '@/assets/javascript/dictionaries/gtmEvents'

import { PLAYING } from '@/assets/javascript/dictionaries/gtmEventsActions'

import {
  getEcommerceMetrics,
  getUserMetrics,
  getFormMetrics,
  getPageEventMetrics,
  getSearchMetrics,
  getActionMetrics,
  getVideoMetrics,
  getSecondaryMetrics
} from '@/plugins/tracking/scripts/mappers'
import {
  provideDefaultSecondParamsHelper
} from '@/assets/javascript/utils'

const API = Symbol('api')
const DATA = Symbol('data')
const EVENTS_ON_PAGE_REFRESH_PRIORITY = Symbol('eventsOnPageRefreshPriority')
const QUEUING_ENABLED = Symbol('enableQueue')
const QUEUE = Symbol('queue')
const SEND_EVENT = Symbol('sendEvent')

export const TRACKING_DELAY = 1000

const events = {
  [API]: null,
  [DATA]: null,
  [EVENTS_ON_PAGE_REFRESH_PRIORITY]: 0,
  [QUEUING_ENABLED]: false,
  [QUEUE]: [],
  priorities: {
    LOW: 0, // layout
    MEDIUM: 1, // beforeRouteEnter -> next
    HIGH: 2, // page
    MAX: 100 // after events
  },
  init ({ trackingAPI, trackingData }) {
    this[API] = trackingAPI
    this[DATA] = trackingData
  },
  isDev () {
    return process.env.NODE_ENV === 'development'
  },
  setEventsOnPageRefreshPriority (value) {
    this[EVENTS_ON_PAGE_REFRESH_PRIORITY] = value
  },
  getEventsOnPageRefreshPriority () {
    return this[EVENTS_ON_PAGE_REFRESH_PRIORITY]
  },
  enableQueuing () {
    this[QUEUING_ENABLED] = true
  },
  resolveQueue () {
    this[QUEUE].forEach(({ event, data }) => {
      this[SEND_EVENT](event, data)
    })
    this[QUEUING_ENABLED] = false
    this[QUEUE] = []
  },
  sendAllAndResolveQueue (events = []) {
    events.forEach(([event, data]) => {
      this.send(event, data, { freeOfTurn: true })
    })
    this.resolveQueue()
  },
  [SEND_EVENT] (event, data) {
    if (!this[event] || typeof this[event] !== 'function') {
      if (!this.isDev) {
        return
      }
      return console.error(`⛔⛔⛔ ${event} is not supported yet`)
    }
    this[event](data)
  },
  reportMissingParams (event, ...params) {
    const missingParams = params
      .filter(([_, value]) => value === undefined || value === null)
      .map(([label]) => label)
    if (missingParams.length) {
      if (process.env.NODE_ENV !== 'development') {
        return
      }
      console.error(`🚫 ${event} 🚫 → missing params:`, missingParams)
    }
  },
  [ACTION] ({ product, action }) {
    // todo finish populating data
    this.reportMissingParams(ACTION, ['product', product])
    const { data: pageLogData = {} } = this[DATA].getCurrentPageDataLog()
    const data = {
      ...getActionMetrics({
        pageViewId: pageLogData.pageViewId,
        ...product,
        action
      })
    }
    this[API].sendEvent(ACTION, data)
  },
  [ECOMMERCE] ({ product }) {
    // todo
    this.reportMissingParams(ECOMMERCE, ['product', product])
    const pageLogData = this[DATA].getCurrentPageDataLog()?.data || {}
    const data = {
      ...getEcommerceMetrics({
        pageViewId: pageLogData.pageViewId,
        ...product
      })
    }
    this[API].sendEvent(ECOMMERCE, data)
  },
  [FORM] ({ form, action }) {
    // todo fill remaining metrics
    this.reportMissingParams(FORM, ['form', form])
    const data = {
      ...getFormMetrics({
        action,
        ...form
      })
    }
    this[API].sendEvent(FORM, data)
  },
  [PAGE] ({ product, action }) {
    this.reportMissingParams(PAGE, ['product', product])
    const data = {
      ...getPageEventMetrics({
        action,
        ...product
      })
    }
    this[API].sendEvent(PAGE, data)
  },
  [PAGE_VIEW] ({ $route }) {
    const data = {
      ...this[DATA].populatePageViewData({
        pageAppLink: $route.path /* uniwersalny identyfikator strony */
      })
    }
    this[DATA].setCurrentPageDataLog({
      pageViewId: data.pageViewId
    })
    this[DATA].upsertSessionId()
    this[API].sendEvent(PAGE_VIEW, data)
  },
  [USER] ({ userData, action }) {
    const pageLogData = this[DATA].getCurrentPageDataLog()?.data || {}
    const data = {
      ...getUserMetrics({
        pageViewId: pageLogData.pageViewId,
        ...userData,
        action
      })
    }
    this[API].sendEvent(USER, data)
  },
  [VIDEO] ({ action, videoData }) {
    this.reportMissingParams(VIDEO,
      ['action', action],
      ['videoData', videoData]
    )

    const isPlayingAction = action === PLAYING
    const data = {
      action,
      ...this[DATA].populateVideoData({
        ...getVideoMetrics({
          isPlayingAction,
          ...videoData
        })
      })
    }

    if (videoData.videoOffset) {
      data.videoOffset = videoData.videoOffset
    }

    this[API].sendEvent(VIDEO, data)
  },
  [SEARCH] ({ searchData }) {
    const pageLogData = this[DATA].getCurrentPageDataLog()?.data || {}
    this.reportMissingParams(SEARCH, ['searchData', searchData])
    const data = getSearchMetrics({
      ...searchData,
      ...pageLogData
    })
    this[API].sendEvent(SEARCH, data)
  },
  [SECONDARY] ({ secondaryData }) {
    const pageLogData = this[DATA].getCurrentPageDataLog()?.data || {}
    this.reportMissingParams(SECONDARY, ['secondaryData', secondaryData])
    const data = {
        ...getSecondaryMetrics({
          ...pageLogData,
          ...secondaryData
        })
    }
    this[API].sendEvent(SECONDARY, data)
  },
  eventsOnPageRefresh ({ $route, priority = 0 } = {}) {
    if (this.getEventsOnPageRefreshPriority() > priority) {
      return
    }
    this.setEventsOnPageRefreshPriority(this.priorities.MAX)
    this.send(PAGE_VIEW, { $route })
  }
}

function sendFn (event, {
  $route,
  action,
  eventLabel,
  form,
  product,
  videoData,
  searchData,
  secondaryData,
  ...omittedProperties
} = {}, { freeOfTurn } = {}) {
  if (!Object.values(ALL_EVENTS).includes(event)) {
    if (process.env.NODE_ENV !== 'development') {
      return
    }
    return console.error(`⛔⛔⛔ ${event} is not supported`)
  }

  const data = Object.entries(arguments[1]).reduce(
    (data, [key, value]) => ({
      ...data,
      ...(Object.prototype.hasOwnProperty.call(omittedProperties, key)
        ? {}
        : { [key]: value })
    }),
    {}
  )

  if (this[QUEUING_ENABLED] && !freeOfTurn) {
    this[QUEUE].push({ event, data })
    return
  }
  this[SEND_EVENT](event, data)
}

const sendFnWithEventContext = sendFn.bind(events)

events.send = provideDefaultSecondParamsHelper(
  sendFnWithEventContext,
  {
    videoData: {}
  }
)

export default events
