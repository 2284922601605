import { DESKTOP } from '@/assets/javascript/responsive/enums'
import { getCurrentBreakpoint } from '@/assets/javascript/responsive'
import { DeviceInfo } from '@/components/compatibility-test/device-info'

export default {
  computed: {
    mobileOrTablet () {
      return this.$route.name !== this.$dict.routes.VERIFY &&
        (DeviceInfo.isMobile() || DeviceInfo.isIOS() || DeviceInfo.isAndroid() || getCurrentBreakpoint() !== DESKTOP)
    }
  }
}
