import { ResponsiveObservable } from './observable'
import {
  DESKTOP,
  IS_DESKTOP,
  IS_MOBILE,
  IS_TABLET,
  IS_LARGE,
  IS_XLARGE,
  MOBILE,
  TABLET,
  LARGE,
  XLARGE
} from './enums'

export const breakpoints = {
  [MOBILE]: () => ResponsiveObservable.width >= 0 && ResponsiveObservable.width < 768 && MOBILE,
  [TABLET]: () => ResponsiveObservable.width >= 640 && ResponsiveObservable.width < 1024 && TABLET,
  [DESKTOP]: () => ResponsiveObservable.width >= 1024 && DESKTOP,
  [LARGE]: () => ResponsiveObservable.width >= 1280 && LARGE,
  [XLARGE]: () => ResponsiveObservable.width >= 1024 && ResponsiveObservable.width < 1440 && XLARGE
}

export const breakpointGetters = {
  [IS_MOBILE]: () => breakpoints[MOBILE]() === MOBILE,
  [IS_TABLET]: () => breakpoints[TABLET]() === TABLET,
  [IS_DESKTOP]: () => breakpoints[DESKTOP]() === DESKTOP,
  [IS_LARGE]: () => breakpoints[LARGE]() === LARGE,
  [IS_XLARGE]: () => breakpoints[XLARGE]() === XLARGE
}

export function getCurrentBreakpoint () {
  const breakpoint = Object
    .values(breakpoints)
    .find(breakpoint => breakpoint() !== false)
  if (breakpoint) {
    return breakpoint()
  }
}
