export default {
  PROMPT: (state, prompt) => {
    state.prompt = prompt
  },
  SET_AUTOPLAY: (state, autoplay) => {
    state.autoplay = autoplay
  },
  SET_ERROR: (state, error) => {
    state.error = error
  }
}
