export default function ({ app, store }) {
  if (app.$fireMess) {
    Notification.requestPermission()

    app.$fireMess.onMessage((payload) => {
      if (Notification.permission === 'granted') {
        const { title, body } = payload.notification

        return new Notification(title, { body })
      }
    })

    app.$fireMess.onTokenRefresh(() => {
      app.$fireMess.getToken()
          .then((token) => store.dispatch('user/SET_FCM_TOKEN', token))
          .catch((e) => console.warn('Unable to retrieve token ', e))
    })
  }
}
