import PlayCardFactory from '@/assets/javascript/play/factory/card'
import TvodCardFactory from '@/assets/javascript/play/factory/tvod-card'
import { TVOD } from '@/assets/javascript/enums/product-types'

export default {
  GET_LIST_BY_CATEGORY ({ commit, rootState }, params) {
    const { firstResult, type } = params
    const isTvod = type?.indexOf(TVOD) > -1
    const url = isTvod
      ? '/products/tvods'
      : '/products/vods'
    return this.$axios
      .get(url, { params })
      .then(data => {
        commit('SET_LIST_BY_CATEGORY', {
          category: {
            meta: data.meta,
            items: data.items.map(product => isTvod
              ? TvodCardFactory(product, rootState.user)
              : PlayCardFactory(product))
          },
          firstResult
        })
        return data
      })
  }
}
