export const ADD_COMMENT = 'AddComment'
export const ADD_REACTION = 'AddReaction'
export const ADD_REMINDER = 'AddRemainder'
export const ADD_TO_FAVOURITES = 'AddToFovorites'
export const ADD_TO_MY_LIST = 'AddToMyList'
export const BUFFERING = 'BUFFERING'
export const CLICK = 'click'
export const CLOSED = 'CLOSED'
export const COMPLETED = 'COMPLETED'
export const ERROR = 'ERROR'
export const FOCUS = 'focus'
export const KEEP_ALIVE = 'KEEP_ALIVE'
export const LIKE = 'Like'
export const PAUSED = 'PAUSED'
export const PLAY = 'Play'
export const PLAYING = 'PLAYING'
export const REMOVE_FROM_FAVOURITES = 'RemoveFromeFovorites'
export const REMOVE_FROM_MY_LIST = 'RemoveFromMyList'
export const REMOVE_REMINDER = 'RemoveReminder'
export const SECONDARY_START = 'start'
export const SECONDARY_STOP = 'stop'
export const SEEKING = 'SEEKING'
export const SHARE_EMAIL = 'ShareByEmail'
export const SHARE_FB = 'ShareOnFb'
export const SHARE_TWITTER = 'ShareOnTwitter'
export const STOPPED = 'STOPPED'
export const SUBMISSION_FAILED = 'submitted_failed'
export const SUBMISSION_SUCCESS = 'submitted'
export const TRACK_CHANGED = 'TRACK_CHANGED'
export const UNLIKE = 'Unlike'
export const USER_ACCOUNT_DELETE = 'AccountDelete'
export const USER_CHANGE_PASSWORD = 'ChangePassword'
export const USER_FB_LOGIN = 'FacebookLogin'
export const USER_GOOGLE_LOGIN = 'GoogleLogin'
export const USER_LOGIN = 'Login'
export const USER_LOGOUT = 'Logout'
export const USER_REGISTER = 'Register'
export const USER_SESSION_PROLONG = 'SessionProlong'
export const USER_SOCIAL_LOGIN = 'SocialLogin'
export const VALIDATION_FAILED = 'validation_error'
export const VIEWPORT = 'view_port'
