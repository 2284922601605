import { LIVE } from '@/assets/javascript/enums/product-types'

export default {
  FIND_BY_ID: (state) => (id) => {
    return state.items
      .find(channel => channel.id === parseInt(id, 10)) || {}
  },
  FIND_BY_NAME: (state) => (keyword) => {
    // RETURN SIMILAR TO API SEARCH
    const results = state.items
      .filter(({ title }) => title
        .toLowerCase()
        .match(keyword.toLowerCase()))
    return {
      [LIVE]: {
        items: results,
        meta: {
          totalCount: results.length
        }
      }
    }
  },
  MY_CHANNELS: (state, _, rootState) => {
    const products = rootState.user.products?.availableProductIds
    if (!products) {
      return []
    }
    return state.items
      .filter(({ id }) => products.find((productId) => productId === id))
  },
  AVAILABLE_CHANNELS: (state, getters) => {
    const myChannels = getters.MY_CHANNELS
    return myChannels.length
      ? myChannels
      : state.items
  }
}
