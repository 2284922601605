export default {
  GET_RECORDINGS ({ commit }) {
    return this.$api
      .resources
      .products
      .lives
      .recordings
      .get()
      .then(({ recordings }) => {
        commit('SET_RECORDINGS', recordings)
      })
  },
  ADD_RECORDING ({ commit }, id) {
    return this.$api
      .resources
      .products
      .lives
      .recordings
      .record(id)
      .then((data) => {
        commit('ADD_RECORDING', data)
        this.$toast.success(this.$i18n.t('recordings.added', { title: data.name }))
        return data
      },
      ({ data }) => {
          this.$toast.error(this.$i18n.t(`recordings.errors.${data.code.toLowerCase()}`))
      })
  },
  DELETE_RECORDING ({ commit, state }, { id, title }) {
    return this.$api
      .resources
      .products
      .lives
      .recordings
      .delete(id)
      .then(
        () => {
          commit('REMOVE_RECORDING', id)
          this.$toast.success(this.$i18n.t('recordings.deleted', { title }))
        },
        () => this.$toast.error(this.$i18n.t('recordings.errorOnDelete'))
      )
  }
}
