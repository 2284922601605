export const ACTION_CLEAR_EVENT_DATA = 'CLEAR_EVENT_DATA'
export const ACTION_GET_IUID = 'GET_IUID'
export const ACTION_GET_UID = 'GET_UID'
export const ACTION_SET_EVENT_DATA = 'SET_EVENT_DATA'
export const ACTION_SET_IUID = 'SET_IUID'
export const ACTION_SET_UID = 'SET_UID'
export const ACTION_SET_SESSID = 'SET_SESSID'
export const ACTION_SET_SESSION_TIMESTAMP = 'SESSION_TIMESTAMP'
export const MUTATION_CLEAR_EVENT_DATA = 'CLEAR_EVENT_DATA'
export const MUTATION_SET_IUID = 'SET_IUID'
export const MUTATION_SET_UID = 'SET_UID'
export const MUTATION_SET_EVENT_DATA = 'SET_EVENT_DATA'
export const MUTATION_SET_SESSID = 'SET_SESSID'
export const MUTATION_SET_SESSION_TIMESTAMP = 'SET_SESSION_TIMESTAMP'
export const GETTER_EVENT_DATA = 'EVENT_DATA'
export const GETTER_SESSID = 'SESSID'
export const GETTER_SESSION_TIMESTAMP = 'SESSION_TIMESTAMP'
