import Vue from 'vue'
import { USER_SESSION_PROLONG, USER_LOGOUT } from '@/assets/javascript/dictionaries/gtmEventsActions'
import { LOGIN_PROVIDER, USER } from 'assets/javascript/dictionaries/gtmEvents'

class HttpSession {
  constructor ($store, $time) {
    this.$time = $time
    this.$store = $store

    this.prolongExpire = 0
  }

  init (session = {}, successCallback, errorCallback) {
    this.successCallback = successCallback
    this.handleError = errorCallback
    this.stop()

    this.prolongIntervalTick(session.till)

    this.prolongSession()
  }

  prolongSession () {
    const expire = this.prolongExpire
    if (expire && !(this.$time().isSameOrAfter(expire))) {
      this.callNextIntervalTick()
      return
    }

    return this.$store.dispatch('httpsession/PROLONG_HTTP_SESSION')
      .then(({ till }) => {
        this.successCallback?.()
        this.prolongIntervalTick(till)
        this.callNextIntervalTick()
      })
      .catch(() => {
        this.handleError?.()
      })
  }

  setProlongInterval (prolongExpire) {
    this.prolongInterval = (prolongExpire - this.$time().valueOf()) / 2
  }

  setProlongExpire () {
    const expire = this.$time().valueOf() + this.prolongInterval
    this.prolongExpire = expire
  }

  prolongIntervalTick (till) {
    this.setProlongInterval(till)
    this.setProlongExpire()
  }

  callNextIntervalTick () {
    this.sessionTimeout = window.setTimeout(() => this.prolongSession(), this.prolongInterval)
  }

  stop () {
    window.clearTimeout(this.sessionTimeout)
  }
}

export default ({ app, store }) => {
  const httpSession = new HttpSession(store, app.$time)

  window.addEventListener('focus', () => {
    if (store.getters['user/LOGGED_IN']) {
      httpSession.prolongSession()
    }
  })

  store.watch((state) => {
    return !!state.user.data.id
  }, (isLoggedIn) => {
    if (isLoggedIn) {
      return httpSession.init(store.state.user.data.httpSession, () => {
        app.$tracking.events.send(USER, { USER_SESSION_PROLONG })
      }, () => {
        app.$tracking.events.send(USER, { USER_LOGOUT })
        app.$tracking.data.clearEventData(LOGIN_PROVIDER)
        store.dispatch('user/LOGOUT', { timestamp: app.$time().valueOf() })
          .finally(() => {
            location.reload()
          })
      })
    }

    return httpSession.stop()
  })

  Vue.prototype.$httpSession = app.$httpSession = httpSession
}
