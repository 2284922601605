export default {
  SET (state, watched = []) {
    state.watched = watched
  },
  ADD (state, payload) {
    payload.item = {
      ...payload.item,
      status: {
        progressWatching: window.parseInt(payload.playTime * 100 / payload.item.duration)
      }
    }

    const index = state.watched.findIndex(watched => watched.item.id === payload.item.id)

    if (index !== -1) {
      return state.watched.splice(index, 1, payload)
    }

    return state.watched.unshift(payload)
  },
  REMOVE (state, itemId) {
    state.watched = state.watched
      .filter(({ item }) => item.id !== itemId)
  }
}
