export function Player ($http) {
  return {
    events: {
      get (itemId) {
        return $http.get(`/items/${itemId}/player/events`)
      }
    },
    configuration: {
      get (productId, videoType) {
        return $http.get(`products/${productId}/videos/player/configuration`, {
          params: {
            videoType
          }
        })
      }
    },
    session: {
      prolong (videoSessionId) {
        return $http.post(`/products/videosessions/${videoSessionId}`, {
          progress: false
        })
      },
      delete (videoSessionId) {
        return $http.delete(`/products/videosessions/${videoSessionId}`, {
          progress: false
        })
      }
    }
  }
}
