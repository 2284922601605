export default {
  GET_ADDITIONAL_MEDIA (_, itemId) {
    return this.$api
      .resources
      .items
      .additionalMedia
      .get(itemId)
  },
  GET_CATEGORIES (_, { categoryId, kids = false }) {
    return this.$api
      .resources
      .items
      .categories
      .get(categoryId, kids)
  },
  GET_SECONDARY_EVENT (_, { id, type }) {
    return this.$api
      .resources
      .items
      .secondaryEvent
      .get(id, type)
  },
  GET_SECONDARY_EVENTS (_, itemId) {
    return this.$api
      .resources
      .items
      .secondaryEvents
      .get(itemId)
  }
}
