const DATA = Symbol('data')
const ENGINE = Symbol('engine')
const SEND_TO_ENGINE = Symbol('sendToEngine')

export default {
  [DATA]: null,
  [ENGINE]: null,
  init ({ trackingEngine, trackingData }) {
    this[DATA] = trackingData
    this[ENGINE] = trackingEngine
  },
  isDev () {
    return process.env.NODE_ENV !== 'development'
  },
  send ({ data, logLabel }) {
    if (!this[ENGINE] || !data.pageViewId) {
      return
    }
    this[SEND_TO_ENGINE](data, logLabel)
  },
  async sendEvent (event, params = {}) {
    const data = await this[DATA]
      .populateCommonData({
        event,
        ...params
      })
    this.send({ data })
  },
  [SEND_TO_ENGINE] (data, logLabel = 'unknown_event') {
    if (!data) {
      if (!this.isDev) {
        return
      }
      return console.error(`⛔⛔⛔ ${logLabel} Attempt to send empty data to tracking engine!`)
    }
    this[ENGINE].push({
      ...data
    })

    // → following console.log comment left for future debugging
    // console.log(`> ${(data.event || logLabel).toUpperCase()} (size: ${this[ENGINE].length}) ← data:`, data)
  }
}
