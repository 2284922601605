export const DEFAULT_MAX_ITEMS = 20

export default {
  GET (_, { id, type }) {
    return this.$api
      .resources
      .products
      .get({ productId: id, type })
  },
  GET_SERIAL_EPISODES (_, id, opts) {
    return this.$api
      .resources
      .products
      .serials
      .getEpisodes(id, opts)
  },
  GET_SERIAL_SEASONS (_, serialId) {
    return this.$api
      .resources
      .products
      .serials
      .getSeasons(serialId)
  },
  GET_ALL_BUNDLES () {
    return this.$api
      .resources
      .products
      .bundles
      .getAll()
  },
  GET_PROGRAMMES (_, productIds) {
    if (!productIds.length) {
      return []
    }

    return this.$api
      .resources
      .products
      .programmes
      .get(productIds)
  },
  GET_VODS (_, {
    query,
    kids = false,
    productId = [],
    categoryId = [],
    mainCategoryId = [],
    itemType = [],
    cancelToken
  }) {
    return this.$api
      .resources
      .products
      .vods
      .get({
        ...query,
        kids,
        productId,
        categoryId,
        mainCategoryId,
        itemType,
        maxResults: DEFAULT_MAX_ITEMS,
        cancelToken
      })
  }
}
