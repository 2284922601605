import * as PRODUCT_TYPES from '@/assets/javascript/enums/product-types'
import * as VIDEO_TYPES from '@/assets/javascript/enums/video-types'

export const PRODUCT_TYPE_TO_VIDEO_TYPE = {
  [PRODUCT_TYPES.VOD]: VIDEO_TYPES.MOVIE,
  [PRODUCT_TYPES.TVOD]: VIDEO_TYPES.MOVIE,
  [PRODUCT_TYPES.VOD_SERIAL]: VIDEO_TYPES.SERIAL,
  [PRODUCT_TYPES.SERIAL]: VIDEO_TYPES.MOVIE,
  [PRODUCT_TYPES.EPISODE]: VIDEO_TYPES.MOVIE,
  [PRODUCT_TYPES.PROGRAMME]: VIDEO_TYPES.MOVIE,
  [PRODUCT_TYPES.RECORDING]: VIDEO_TYPES.RECORDING,
  [PRODUCT_TYPES.LIVE]: VIDEO_TYPES.LIVE,
  [PRODUCT_TYPES.TRAILER]: VIDEO_TYPES.TRAILER
}
