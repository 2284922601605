export const DEFAULT_MAX_RESULTS = 18

export default {
  GET_BY_TYPE (_, { query, type, kids = false, firstResult = 0, maxResults = DEFAULT_MAX_RESULTS }) {
    return this.$api
      .resources
      .searchResults
      .get({ query, type, kids, firstResult, maxResults })
  },
  GET_ALL (_, { query, kids = false, firstResult = 0, maxResults = DEFAULT_MAX_RESULTS }) {
    return this.$api
      .resources
      .searchResults
      .getAll({ query, kids, firstResult, maxResults })
  }
}
