export default {
  SET_LIST_BY_CATEGORY (state, { category, firstResult, page = state.body.page }) {
    if (firstResult === 0) {
      state.byCategory = category
      state.body.page = 1
    } else {
      state.byCategory.meta = category.meta
      state.byCategory.items = state.byCategory.items
        .concat(category.items)
    }
    state.body.page = page
  },
  NEXT_PAGE (state) {
    state.body.page += 1
  }
}
