export default {
  SUBSCRIBE (_, { type, itemId, token }) {
    return this.$api
      .resources
      .notifications
      .subscribe(type, itemId, token)
  },
  UNSUBSCRIBE (_, { type, itemId, token }) {
    return this.$api
      .resources
      .notifications
      .unsubscribe(type, itemId, token)
  }
}
