import { Adverts } from '@/api/resources/adverts'
import { Authorizations } from '@/api/resources/authorizations'
import { Broadcast } from '@/api/resources/broadcast'
import { BroadcastProfiles } from '@/api/resources/broadcastProfiles'
import { Contacts } from '@/api/resources/contacts'
import { Epg } from '@/api/resources/epg'
import { Documents } from '@/api/resources/documents'
import { Info } from '@/api/resources/info'
import { Items } from '@/api/resources/items'
import { Notifications } from '@/api/resources/notifications'
import { Payments } from '@/api/resources/payments'
import { Player } from '@/api/resources/player'
import { Products } from '@/api/resources/products'
import { Profiles } from '@/api/resources/profiles'
import { Recommendations } from '@/api/resources/recommendations'
import { SearchResults } from '@/api/resources/search-results'
import { SecondaryEventAnswers } from '@/api/resources/secondaryEventAnswers'
import { Subscribers } from '@/api/resources/subscribers'
import { Events } from '@/api/resources/events'
import { CMP } from '@/api/resources/cmp'

export const resources = {
  adverts: Adverts,
  authorizations: Authorizations,
  broadcast: Broadcast,
  broadcastProfiles: BroadcastProfiles,
  cmp: CMP,
  contacts: Contacts,
  epg: Epg,
  events: Events,
  documents: Documents,
  info: Info,
  items: Items,
  notifications: Notifications,
  payments: Payments,
  player: Player,
  products: Products,
  profiles: Profiles,
  recommendations: Recommendations,
  searchResults: SearchResults,
  secondaryEventAnswers: SecondaryEventAnswers,
  subscribers: Subscribers
}
