const hasMeta = (route, field) => {
  return route.matched.some(record => record.meta[field])
}

export default function ({ redirect, from, app, route, store }) {
  if (hasMeta(route, 'auth') && !store.getters['user/LOGGED_IN']) {
    app.router.push({
      name: app.$dict.routes.LOGIN,
      query: {
        redirect: from.path
      }
    })
  } else if (hasMeta(route, 'redirectIfLoggedIn') && store.getters['user/LOGGED_IN']) {
    redirect('/')
  }
}
