export function Notifications ($http) {
  const BASE_URL = '/notifications'

  return {
    subscribe (type, itemId, token) {
      return $http.post(`${BASE_URL}/${type}s/${itemId}/token/subscribe`, { token })
    },
    unsubscribe (type, itemId, token) {
      return $http.delete(`${BASE_URL}/${type}s/${itemId}/token/unsubscribe`, { data: { token } })
    }
  }
}
