import { PLAYLIST } from '@/assets/javascript/enums/bookmarks-types'

export default {
  GET_PLAYLIST ({ dispatch, commit }) {
    return dispatch('user/GET_BOOKMARKS', {
      type: PLAYLIST,
      maxResults: 0
    }, {
      root: true
    })
      .then(playlist => {
        commit('SET', playlist)
        return playlist
      })
  },
  ADD_TO_PLAYLIST ({ dispatch, commit }, item) {
    const { id, title, videoType } = item

    return dispatch('user/POST_BOOKMARK', {
      id,
      title,
      videoType,
      type: PLAYLIST
    }, { root: true })
      .then(
        () => {
          commit('ADD', item)
          this.$toast.success(this.$i18n.t('playlist.added', { title }))
        },
        () => this.$toast.error(this.$i18n.t('playlist.errorOnAdd'))
      )
  },
  REMOVE_FROM_PLAYLIST ({ commit, state, dispatch }, item) {
    const { id, title } = item

    return dispatch('user/DELETE_BOOKMARK', {
      id,
      title,
      type: PLAYLIST
    }, {
      root: true
    })
      .then(
        () => {
          commit('REMOVE', item.id)
          this.$toast.success(this.$i18n.t('playlist.deleted', { title }))
        },
        () => this.$toast.error(this.$i18n.t('playlist.errorOnDelete'))
      )
  }
}
