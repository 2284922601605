import { AUTHENTICATION_REQUIRED } from '@/api/enums'

export function addAuthRequiredInterceptorHandler (interceptors, store) {
  interceptors.response
    .use((response) => response,
      async ({ response }) => {
        if (response?.data?.message === AUTHENTICATION_REQUIRED) {
          await store.dispatch('CLEAR_USER_CTX', {})
          await store.cache.delete('user/GET_USER_CTX')
          location.reload()
        }
        return Promise.reject(response)
      }
    )
}
