import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_pluginrouting_2ca38eb3 from 'nuxt_plugin_pluginrouting_2ca38eb3' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_4160119c from 'nuxt_plugin_pluginmain_4160119c' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_main_54fcd1cf from 'nuxt_plugin_main_54fcd1cf' // Source: ./firebase-module/main.js (mode: 'all')
import nuxt_plugin_router_5bdc52dc from 'nuxt_plugin_router_5bdc52dc' // Source: ./router.js (mode: 'all')
import nuxt_plugin_axios_36f41f33 from 'nuxt_plugin_axios_36f41f33' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_localStorage_830ec59e from 'nuxt_plugin_localStorage_830ec59e' // Source: ../plugins/localStorage.js (mode: 'client')
import nuxt_plugin_index_6e2d49cc from 'nuxt_plugin_index_6e2d49cc' // Source: ../plugins/beforeNuxtInit/index.js (mode: 'client')
import nuxt_plugin_tracking_31194aaa from 'nuxt_plugin_tracking_31194aaa' // Source: ../plugins/tracking.js (mode: 'client')
import nuxt_plugin_index_86a730f2 from 'nuxt_plugin_index_86a730f2' // Source: ../plugins/tracking/index.js (mode: 'client')
import nuxt_plugin_captcha_4a5d2d33 from 'nuxt_plugin_captcha_4a5d2d33' // Source: ../plugins/captcha.js (mode: 'client')
import nuxt_plugin_dompurifyhtml_52536f0c from 'nuxt_plugin_dompurifyhtml_52536f0c' // Source: ../plugins/dompurify-html.js (mode: 'client')
import nuxt_plugin_loader_7a10d364 from 'nuxt_plugin_loader_7a10d364' // Source: ../plugins/loader.js (mode: 'client')
import nuxt_plugin_notifications_15a88b85 from 'nuxt_plugin_notifications_15a88b85' // Source: ../plugins/notifications.js (mode: 'client')
import nuxt_plugin_webfontloader_507d9392 from 'nuxt_plugin_webfontloader_507d9392' // Source: ../plugins/webfontloader.js (mode: 'client')
import nuxt_plugin_index_ab6975c6 from 'nuxt_plugin_index_ab6975c6' // Source: ../plugins/player/index.js (mode: 'client')
import nuxt_plugin_fbsdk_5db116f8 from 'nuxt_plugin_fbsdk_5db116f8' // Source: ../plugins/fb-sdk.js (mode: 'client')
import nuxt_plugin_fcm_68ac50c6 from 'nuxt_plugin_fcm_68ac50c6' // Source: ../plugins/fcm.js (mode: 'client')
import nuxt_plugin_vuelazyload_d07c1d40 from 'nuxt_plugin_vuelazyload_d07c1d40' // Source: ../plugins/vue-lazyload.js (mode: 'client')
import nuxt_plugin_httpsession_4f01fb1f from 'nuxt_plugin_httpsession_4f01fb1f' // Source: ../plugins/httpsession.js (mode: 'client')
import nuxt_plugin_infiniteloading_dd65fd44 from 'nuxt_plugin_infiniteloading_dd65fd44' // Source: ../plugins/infinite-loading.js (mode: 'client')
import nuxt_plugin_cmp_71ccd432 from 'nuxt_plugin_cmp_71ccd432' // Source: ../plugins/cmp.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp (ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"WOW","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"script":[{"src":"\u002F\u002Fr.dcs.redcdn.pl\u002Ffile\u002Fo2\u002Fweb\u002Ftracking\u002FatdsTracking.min.js"},{"src":"https:\u002F\u002Fpagead2.googlesyndication.com\u002Fpagead\u002Fjs\u002Fadsbygoogle.js","data-ad-client":"ca-pub-6503933299850976","async":true}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico?v=2"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_pluginrouting_2ca38eb3 === 'function') {
    await nuxt_plugin_pluginrouting_2ca38eb3(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_4160119c === 'function') {
    await nuxt_plugin_pluginmain_4160119c(app.context, inject)
  }

  if (typeof nuxt_plugin_main_54fcd1cf === 'function') {
    await nuxt_plugin_main_54fcd1cf(app.context, inject)
  }

  if (typeof nuxt_plugin_router_5bdc52dc === 'function') {
    await nuxt_plugin_router_5bdc52dc(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_36f41f33 === 'function') {
    await nuxt_plugin_axios_36f41f33(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localStorage_830ec59e === 'function') {
    await nuxt_plugin_localStorage_830ec59e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_index_6e2d49cc === 'function') {
    await nuxt_plugin_index_6e2d49cc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tracking_31194aaa === 'function') {
    await nuxt_plugin_tracking_31194aaa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_index_86a730f2 === 'function') {
    await nuxt_plugin_index_86a730f2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_captcha_4a5d2d33 === 'function') {
    await nuxt_plugin_captcha_4a5d2d33(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_dompurifyhtml_52536f0c === 'function') {
    await nuxt_plugin_dompurifyhtml_52536f0c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_loader_7a10d364 === 'function') {
    await nuxt_plugin_loader_7a10d364(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_15a88b85 === 'function') {
    await nuxt_plugin_notifications_15a88b85(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webfontloader_507d9392 === 'function') {
    await nuxt_plugin_webfontloader_507d9392(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_index_ab6975c6 === 'function') {
    await nuxt_plugin_index_ab6975c6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fbsdk_5db116f8 === 'function') {
    await nuxt_plugin_fbsdk_5db116f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fcm_68ac50c6 === 'function') {
    await nuxt_plugin_fcm_68ac50c6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuelazyload_d07c1d40 === 'function') {
    await nuxt_plugin_vuelazyload_d07c1d40(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_httpsession_4f01fb1f === 'function') {
    await nuxt_plugin_httpsession_4f01fb1f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infiniteloading_dd65fd44 === 'function') {
    await nuxt_plugin_infiniteloading_dd65fd44(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_cmp_71ccd432 === 'function') {
    await nuxt_plugin_cmp_71ccd432(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
