export const state = () => ({
  data: {
    firebaseAccessToken: '',
    profiles: []
  },
  activeSubscriptions: [],
  notifications: [],
  devices: [],
  favourites: [],
  payments: [],
  products: [],
  agreements: [],
  available: []
})
