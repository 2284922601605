import priceFormat from '@/assets/javascript/filters/price-format'
import getValidSchedules from '@/assets/javascript/helpers/get-valid-schedules'
import { CYCLIC } from '@/assets/javascript/enums/payment-schedule-types'

const CARD = 'CARD'
const PREPAID_ONLINE = 'PREPAID_ONLINE'

export default function getLowestPrice (product, time, type) {
  const validSchedules = getValidSchedules(product, time, type)

  if (!validSchedules.length) {
    return 0
  }

  const priceType = type === CYCLIC ? CARD : PREPAID_ONLINE

  if (validSchedules.length === 1) {
    return {
      price: priceFormat(validSchedules[0].prices[priceType]),
      duration: validSchedules[0].duration
    }
  }

  const bestSchedule = validSchedules
    .sort((a, b) => a.prices[priceType] - b.prices[priceType])

  const bestPrice = bestSchedule[0].prices[priceType]

  return {
    price: bestPrice ? priceFormat(bestPrice) : 0,
    duration: bestSchedule[0].duration
  }
}
