import EnumsPlugin from './plugins/enums'
import DictionariesPlugin from './plugins/dictionaries'
import TimePlugin from './plugins/time'
import AxiosPlugin from './plugins/axios'
import WindowListeners from './plugins/window-listeners'

export default async ({ $axios, store, app, env }) => {
  AxiosPlugin({ $axios, store, app, env })
  EnumsPlugin({ app, store })
  DictionariesPlugin({ store, app })
  TimePlugin({ store, app })
  WindowListeners({ store, app })
  await store.dispatch('api/GET_API_INFO')

  try {
    const user = await store.cache.dispatch('user/GET_DETAILS')

    if (user) {
      await store.cache.dispatch('user/GET_USER_CTX')
    }
  } catch {
    store.dispatch('user/CLEAR_USER_CTX', {})
  }

  await store.dispatch('menu/GET_MENU', 'menu-web')

  app.router.beforeEach((to, from, next) => {
    if (app.$tracking) {
      app.$tracking.reset(to.name)
    }
    if (to.name === from.name && app.$tracking) {
      const { events } = app.$tracking
      events.eventsOnPageRefresh({
        $route: to,
        priority: events.priorities.LOW
      })
    }
    if (!from.name) {
      next()
      return
    }
    if (store.getters['user/LOGGED_IN']) {
      store.cache.dispatch('user/GET_USER_CTX')
        .then(() => {
          next()
        })
    } else {
      next()
    }
  })
}
