import BaseProduct from '@/assets/javascript/factories/product/base'
// import { ASPECT_LOGO_COLOR } from '@/assets/javascript/enums/image-scale-name'

export default function (product, extend) {
  const Channel = {
    ...BaseProduct(product),
    // logo: product.logos?.[ASPECT_LOGO_COLOR]?.[0].url,
    rank: product.rank
  }

  if (extend instanceof Function) {
    return { ...Channel, ...extend(product) }
  }

  return Channel
}
