export const EPISODE = 'EPISODE'
export const VOD = 'VOD'
export const TVOD = 'TVOD'
export const VOD_SERIAL = 'VOD_SERIAL'
export const VOD_EPISODE = 'VOD_EPISODE'
export const PROGRAMME = 'PROGRAMME'
export const RECORDING = 'RECORDING'
export const REMINDER = 'REMINDER'
export const SERIAL = 'SERIAL'
export const FAVOURITE = 'FAVOURITE'
export const LIVE = 'LIVE'
export const TRAILER = 'TRAILER'
export const PACKET = 'PACKET'
export const BANNER = 'BANNER'
export const PROGRAM = 'PROGRAM'
export const BUNDLE = 'BUNDLE'
export const N_PVR = 'N_PVR'
