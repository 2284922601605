import { READY } from '@/assets/javascript/enums/states'

export default {
  IS_READY: (state) => (id) => {
    return state.recordings
      .find((item) => item.id === id)
      .status === READY
  },
  RECORD_ID: state => (id) => {
    const productId = parseInt(id, 10)
    return state.recordings?.find(recording => recording.programmeId === productId)?.id
  }
}
