export default {
  ADD_QUESTION_ANSWER (_, { eventId, answer }) {
    return this.$api
      .resources
      .secondaryEventAnswers
      .question
      .post({ eventId, answer })
  },
  // Banner response in progress, not ready yet
  ADD_BANNER_CLICK (_, { eventId, answer }) {
    return this.$api
      .resources
      .secondaryEventAnswers
      .question
      .post({ eventId, answer })
  }
}
