import {
  CURRENT_PAGE_DATA_LOG,
  PREVIOUS_PAGE_DATA_LOG,
  CHECKOUT_PRODUCT_DATA_LOG,
  LOGIN_PROVIDER
} from '@/assets/javascript/dictionaries/gtmEvents'

import {
  ACTION_CLEAR_EVENT_DATA,
  ACTION_GET_IUID,
  ACTION_SET_EVENT_DATA,
  ACTION_SET_SESSID,
  ACTION_SET_SESSION_TIMESTAMP,
  GETTER_EVENT_DATA,
  GETTER_SESSID,
  GETTER_SESSION_TIMESTAMP
} from '@/store/tracking/types'

import * as platform from 'platform'
import { uuid } from 'uuidv4'
import { dependencies } from '@/package.json'

const { 'redgalaxy-player-web': playerVersion } = dependencies

const STORE = Symbol('store')
const APP = Symbol('app')
const SESSION_STORAGE = Symbol('session_storage')
const NONE = undefined

const trackingStoreMethod = (method) => `tracking/${method}`

export default {
  [STORE]: null,
  [APP]: null,
  [SESSION_STORAGE]: null,
  init ({ store, app }) {
    this[STORE] = store
    this[APP] = app
    this[SESSION_STORAGE] = window.sessionStorage
  },
  setEventData ({ event, data }) {
    const dstData = {
      pageSection: this[APP].router.app?.$route?.name,
      ...data
    }
    return this[STORE].dispatch(trackingStoreMethod(ACTION_SET_EVENT_DATA), { event, data: dstData })
  },
  getEventData (event) {
    return this[STORE].getters[trackingStoreMethod(GETTER_EVENT_DATA)](event)
  },
  clearEventData (event) {
    return this[STORE].dispatch(trackingStoreMethod(ACTION_CLEAR_EVENT_DATA), { event })
  },
  setCheckoutProductDataLog (data) {
    return this.setEventData({ event: CHECKOUT_PRODUCT_DATA_LOG, data })
  },
  setCurrentPageDataLog (data) {
    return this.setEventData({ event: CURRENT_PAGE_DATA_LOG, data })
  },
  setCurrentPageDataLogIfEmpty (data) {
    const currentPageDataLog = this.getCurrentPageDataLog()
    if (!currentPageDataLog) {
      this.setCurrentPageDataLog(data)
    }
  },
  setLoginProvider (data) {
    return this.setEventData({ event: LOGIN_PROVIDER, data })
  },
  getCheckoutProductDataLog () {
    return this.getEventData(CHECKOUT_PRODUCT_DATA_LOG)
  },
  getCurrentPageDataLog () {
    return this.getEventData(CURRENT_PAGE_DATA_LOG)
  },
  getLoginProvider () {
    return this.getEventData(LOGIN_PROVIDER)
  },
  clearCheckoutProductDataLog () {
    this.clearEventData(CURRENT_PAGE_DATA_LOG)
  },
  clearCurrentPageDataLog () {
    this.clearEventData(CURRENT_PAGE_DATA_LOG)
  },
  setPreviousPageDataLog (data) {
    return this.setEventData({ event: PREVIOUS_PAGE_DATA_LOG, data })
  },
  savePreviousPageCommonData ({ $route, sectionData, slideItemData }) {
    this.setPreviousPageDataLog(
      this.getCommonData({ $route, data: { ...sectionData, ...slideItemData } })
    )
  },
  upsertSessionId () {
    const sessionId = this.getSessionId()
    if (sessionId) {
      return sessionId
    }

    const newSessionId = uuid()
    const timeStamp = String(new Date().getTime())
    if (this[SESSION_STORAGE]) {
      this[SESSION_STORAGE].setItem('sessionId', newSessionId)
      this[SESSION_STORAGE].setItem('sessionTimeStamp', timeStamp)
    } else {
      this[STORE].dispatch(trackingStoreMethod(ACTION_SET_SESSID), newSessionId)
      this[STORE].dispatch(trackingStoreMethod(ACTION_SET_SESSION_TIMESTAMP), timeStamp)
    }
  },
  getSessionId () {
    if (this[SESSION_STORAGE]) {
      return this[SESSION_STORAGE].getItem('sessionId')
    } else {
      return this[STORE].getters[trackingStoreMethod(GETTER_SESSID)]()
    }
  },
  getSessionDurationInMs () {
    const sessionTimeStamp = this[SESSION_STORAGE]
      ? this[SESSION_STORAGE].getItem('sessionTimeStamp')
      : this[STORE].getters[trackingStoreMethod(GETTER_SESSION_TIMESTAMP)]()

    return (new Date().getTime() - Number(sessionTimeStamp)) / 1000
  },
  getCommonData ({ $route: { name: routeName, path } = {}, data: { name: sectionName = '', layout: layoutType = '', type: sectionType = '', position } = {} }) {
    return {
      list: `${path !== '/' ? path : ''}${sectionName ? `/${sectionName}` : ''}`,
      layoutType,
      sectionType,
      pageSection: routeName,
      ...(position && { position })
    }
  },
  async populateCommonData (data = {}) {
    const { data: { pageViewId } = {} } = this.getCurrentPageDataLog() || {}
    const { userAgent, language } = window.navigator
    const userData = await this.populateUserData(data)

    return {
      ...userData,
      pageViewId: pageViewId || data.pageViewId,
      userSessionId: this.getSessionId(),
      timestamp: (new Date()).getTime(), /* data utworzenia zdarzenia */
      platform: `${platform.name} ${platform.version}`, /* Platforma */
      terminal: 'PC', /* terminal: PC, phone, tablet, TV */
      playerVersion, /* wersja playera */
      os: platform.os.family || NONE, /* system operacyjny urządzenia */
      osVersion: platform.os.version || NONE, /* wersja systemu operacyjnego */
      maker: platform.producent || NONE, /* producent urządzenia */
      agent: userAgent, /* user agent klienta httpagent */
      language, /* język urządzenia */
      screenWidth: window.innerWidth, /* szerokość ekranu */
      screenHeight: window.innerHeight /* wysokość ekranu */
    }
  },
  populatePageViewData (data = {}) {
    return {
      pageViewId: uuid(), /* alfanumeryczny string o długości 36 znaków */
      ...data
    }
  },
  populateVideoData (data = {}) {
    return {
      streamingSessionId: this.getSessionId(), /* identyfikator sesji */
      ...data
    }
  },
  async populateUserData (data) {
    const { id: userId } = this[STORE].getters['user/GET_ACTIVE_PROFILE'] || {}
    const iuid = await this[STORE].dispatch(trackingStoreMethod(ACTION_GET_IUID))

    return {
      userId,
      iuid,
      ...data
    }
  }
}
