export default {
  EVENT_DATA: state => event => {
    return state.collection.find(item => item.event === event)
  },
  SESSID: state => () => {
    return state.sessid
  },
  SESSION_TIMESTAMP: state => () => {
    return state.sessionTimeStamp
  }
}
