const profilesCategoriesProperties = {
  HIGHEST: 'highest',
  HIGH: 'high',
  STANDARD: 'standard',
  LOW: 'low'
}

export const profilesCategories = [
  {
    category: profilesCategoriesProperties.HIGHEST,
    name: 'najwyższa',
    minHeight: 1080
  },
  {
    category: profilesCategoriesProperties.HIGH,
    name: 'wysoka',
    minHeight: 720
  },
  {
    category: profilesCategoriesProperties.STANDARD,
    name: 'średnia',
    minHeight: 540
  },
  {
    category: profilesCategoriesProperties.LOW,
    name: 'niska',
    minHeight: 0
  }
]
