export default {
  SET (state, favourites = []) {
    state.favourites = favourites
  },
  ADD (state, item) {
    state.favourites.unshift({
      item,
      modifiedAt: this.$time().format('YYYY-MM-DD HH:mm:ss')
    })
  },
  REMOVE (state, itemId) {
    state.favourites = state.favourites
      .filter(({ item }) => item.id !== itemId)
  }
}
