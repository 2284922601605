import Vue from 'vue'
import Router from 'vue-router'
import RoutesDictionary from '@/assets/javascript/dictionaries/routes'

Vue.use(Router)

const ADD_DEVICE = () => import('~/pages/my-account/add-device/index.vue').then(m => m.default || m)
const ADD_PROFILE = () => import('@/pages/profiles-page/add/index.vue').then(m => m.default || m)
const BOUGHT = () => import('~/pages/my-list/bought/index.vue').then(m => m.default || m)
const CATALOG = () => import('~/pages/catalog/index.vue').then(m => m.default || m)
const COMPATIBILITY_TEST = () => import('~/pages/compatibility-test/index.vue').then(m => m.default || m)
const CONTACT = () => import('~/pages/contact/index.vue').then(m => m.default || m)
const DEVICES = () => import('~/pages/my-account/devices/index.vue').then(m => m.default || m)
const EDIT_PROFILE = () => import('@/pages/profiles-page/edit/index.vue').then(m => m.default || m)
const FAVOURITES = () => import('~/pages/my-list/favourites/index.vue').then(m => m.default || m)
const ISSUES = () => import('~/pages/my-account/issues/index.vue').then(m => m.default || m)
const BROADCAST = () => import('~/pages/my-account/broadcast/index.vue').then(m => m.default || m)
const BROADCAST_PROFILE = () => import('~/pages/my-account/broadcast/profile/index.vue').then(m => m.default || m)
const MESSAGES = () => import('~/pages/my-account/messages/index.vue').then(m => m.default || m)
const MANAGE_PROFILES = () => import('@/pages/profiles-page/index.vue').then(m => m.default || m)
const MY_ACCOUNT = () => import('~/pages/my-account/index.vue').then(m => m.default || m)
const MY_LIST = () => import('~/pages/my-list/index.vue').then(m => m.default || m)
const PAYMENTS = () => import('~/pages/payments/index.vue').then(m => m.default || m)
const PAYMENT_CONFIRMED = () => import('~/pages/payment-confirmed/index.vue').then(m => m.default || m)
const MY_ACCOUNT_PROFILE = () => import('~/pages/my-account/profile/index.vue').then(m => m.default || m)
const SEARCH = () => import('~/pages/search/index.vue').then(m => m.default || m)
const SECTIONS = () => import('~/pages/sections/index.vue').then(m => m.default || m)
const SHOW_PROFILES = () => import('@/pages/profiles-page/show/index.vue').then(m => m.default || m)
const STATIC_PAGE = () => import('~/pages/static/index.vue').then(m => m.default || m)
const DOCUMENT = () => import('~/pages/document/index.vue').then(m => m.default || m)
const VERIFY = () => import('~/pages/verify/index.vue').then(m => m.default || m)
const TRANSACTIONS = () => import('~/pages/my-account/transactions/index.vue').then(m => m.default || m)
const WATCH = () => import('~/pages/watch/index.vue').then(m => m.default || m)
const WATCH_ON_MOBILE = () => import('~/pages/watch-on-mobile/index.vue').then(m => m.default || m)
const WATCHED = () => import('~/pages/my-list/watched/index.vue').then(m => m.default || m)
const GOOGLE_LOGIN = () => import('~/pages/google-login/index.vue').then(m => m.default || m)
const TRANSMISSION = () => import('~/pages/transmission/index.vue').then(m => m.default || m)
const REDIRECT = () => import('~/pages/redirect/index.vue').then(m => m.default || m)
const LOGIN = () => import('~/pages/login/index.vue').then(m => m.default || m)
const REGISTER = () => import('~/pages/register/index.vue').then(m => m.default || m)
const RESET_PASSWORD = () => import('~/pages/reset-password/index.vue').then(m => m.default || m)
const SET_NEW_PASSWORD = () => import('~/pages/set-new-password/index.vue').then(m => m.default || m)

export function createRouter () {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior () {
      return { x: 0, y: 0 }
    },
    routes: [
      {
        path: '/',
        component: SECTIONS,
        name: RoutesDictionary.HOME
      },
      {
        path: '/login',
        component: LOGIN,
        name: RoutesDictionary.LOGIN,
        meta: {
          redirectIfLoggedIn: true
        }
      },
      {
        path: '/register',
        component: REGISTER,
        name: RoutesDictionary.REGISTER,
        meta: {
          redirectIfLoggedIn: true
        }
      },
      {
        path: '/search',
        component: SEARCH,
        name: RoutesDictionary.SEARCH
      },
      {
        path: '/google-login',
        component: GOOGLE_LOGIN,
        name: RoutesDictionary.GOOGLE_LOGIN
      },
      {
        path: '/subscriber/password/reset',
        component: RESET_PASSWORD,
        name: RoutesDictionary.RESET_PASSWORD
      },
      {
        path: '/subscriber/password/new',
        component: SET_NEW_PASSWORD,
        name: RoutesDictionary.SET_NEW_PASSWORD
      },
      {
        path: '/contact',
        component: CONTACT,
        name: RoutesDictionary.CONTACT
      },
      {
        path: '/my-account/devices/add-device',
        component: ADD_DEVICE,
        name: RoutesDictionary.ADD_DEVICE,
        meta: {
          auth: true
        }
      },
      {
        path: '/manage-profiles',
        component: MANAGE_PROFILES,
        name: RoutesDictionary.MANAGE_PROFILES,
        children: [
          {
            path: '',
            component: SHOW_PROFILES,
            name: RoutesDictionary.SHOW_PROFILES,
            meta: {
              auth: true
            }
          },
          {
            path: 'edit-profile',
            component: EDIT_PROFILE,
            name: RoutesDictionary.EDIT_PROFILE,
            meta: {
              auth: true
            }
          },
          {
            path: 'add-profile',
            component: ADD_PROFILE,
            name: RoutesDictionary.ADD_PROFILE,
            meta: {
              auth: true
            }
          }
        ]
      },
      {
        path: '/my-account',
        component: MY_ACCOUNT,
        name: RoutesDictionary.MY_ACCOUNT,
        children: [
          {
            path: '',
            component: MY_ACCOUNT_PROFILE,
            name: RoutesDictionary.MY_ACCOUNT_PROFILE,
            meta: {
              auth: true
            }
          },
          {
            path: 'transactions',
            component: TRANSACTIONS,
            name: RoutesDictionary.TRANSACTIONS,
            meta: {
              auth: true
            }
          },
          {
            path: 'devices',
            component: DEVICES,
            name: RoutesDictionary.DEVICES,
            meta: {
              auth: true
            }
          },
          {
            path: 'issues',
            component: ISSUES,
            name: RoutesDictionary.ISSUES,
            meta: {
              auth: true
            }
          },
          {
            path: 'broadcast',
            component: BROADCAST,
            name: RoutesDictionary.BROADCAST,
            meta: {
              auth: true
            }
          },
          {
            path: 'broadcast-profile',
            component: BROADCAST_PROFILE,
            name: RoutesDictionary.BROADCAST_PROFILE,
            meta: {
              auth: true
            }
          },
          {
            path: 'messages',
            component: MESSAGES,
            name: RoutesDictionary.MESSAGES,
            meta: {
              auth: true
            }
          }
        ]
      },
      {
        path: '/my-list',
        component: MY_LIST,
        children: [
          {
            path: '',
            component: FAVOURITES,
            name: RoutesDictionary.FAVOURITES,
            meta: {
              auth: true
            }
          },
          {
            path: 'watched',
            component: WATCHED,
            name: RoutesDictionary.WATCHED,
            meta: {
              auth: true
            }
          },
          {
            path: 'bought',
            component: BOUGHT,
            name: RoutesDictionary.BOUGHT,
            meta: {
              auth: true
            }
          }
        ]
      },
      {
        path: '/:label?,:id?',
        name: RoutesDictionary.SECTIONS,
        component: SECTIONS
      },
      {
        path: '/watch-on-mobile/',
        name: RoutesDictionary.WATCH_ON_MOBILE,
        component: WATCH_ON_MOBILE
      },
      {
        path: '/watch/:type/:productId',
        name: RoutesDictionary.WATCH,
        component: WATCH
      },
      {
        path: '/buy-and-watch/:type/:productId',
        name: RoutesDictionary.BUY_AND_WATCH,
        component: WATCH,
        meta: {
          buy: true
        }
      },
      {
        path: '/:label,:id?/catalog/:categoryId',
        name: RoutesDictionary.CATALOG,
        component: CATALOG
      },
      {
        path: '/compatibility-test',
        name: RoutesDictionary.COMPATIBILITY_TEST,
        component: COMPATIBILITY_TEST,
        meta: {
          auth: true
        }
      },
      {
        path: '/payments',
        name: RoutesDictionary.PAYMENTS,
        component: PAYMENTS,
        meta: {
          auth: true
        }
      },
      {
        path: '/page/:alias',
        name: RoutesDictionary.STATIC_PAGE,
        component: STATIC_PAGE
      },
      {
        path: '/document/:alias',
        name: RoutesDictionary.DOCUMENT,
        component: DOCUMENT
      },
      {
        path: '/payments/status',
        name: RoutesDictionary.PAYMENT_CONFIRMED,
        component: PAYMENT_CONFIRMED
      },
      {
        path: '/subscriber/mail/verify',
        name: RoutesDictionary.VERIFY,
        component: VERIFY
      },
      {
        path: '/transmission',
        name: RoutesDictionary.TRANSMISSION,
        component: TRANSMISSION,
        meta: {
          auth: true
        }
      },
      {
        path: '/redirect',
        name: RoutesDictionary.REDIRECT,
        component: REDIRECT
      }
    ]
  })
}
