import { WATCHED } from '@/assets/javascript/enums/bookmarks-types'
import { PlayerEvent } from 'redgalaxy-player-web'
import { MOVIE, RECORDING, SERIAL } from '@/assets/javascript/enums/video-types'
import _throttle from 'lodash.throttle'
import { defaultAudioLanguage } from '@/plugins/player/configuration'
import { checkAutoplaySupport } from '@/components/player/utils/check-autoplay-support'

import {
  STATUS_PLAYING,
  TIME_SHIFT_DISABLED,
  STATUS_ENDED,
  STATUS_ERROR
} from 'atds-player-layout/src/enums'
import { LIVE, PROGRAMME } from '@/assets/javascript/enums/product-types'
import { mapProfiles, mapAudioTracks, mapSubtitles } from './settingsMapping'
import { POD_CHANGED, SEND_EVENT } from '@/plugins/player/modules/advert/enums'

const MIN_BOOKMARK_DURATION = 5 // [s]
const BOOKMARK_SEND_INTERVAL = 60 // [s]
const AUTOPLAY_FAIL_TIMEOUT = 5000
const AUTOPLAY_BLOCKED = 'autoplay-blocked'
const AUTOPLAY_MUTED_BLOCKED = 'autoplay-muted-blocked'
const AUTOPLAY_CLICKED = 'autoplay-clicked'

export class Listeners {
  constructor (player, getters, commit, dispatch, $time, $tracking, playerConfig) {
    const instance = player.instance
    const store = player.store
    const state = store.state
    this.$time = $time
    this.$tracking = $tracking
    this.commit = commit
    this.dispatch = dispatch
    this.getters = getters
    this.player = instance
    this.state = state
    this.playerConfig = playerConfig

    const videoSession = this.playerConfig.videoSession
    const prolongInterval = videoSession?.prolongInterval * 1000
    if (prolongInterval) {
      this.sessionProlong = setInterval(() => {
        dispatch('player/PROLONG_SESSION', videoSession)
          .catch((error) => {
            if (error.status === 403 && error.data.code === 'VIDEO_SESSION_EXPIRED') {
              store.setPlayerStatus(STATUS_ERROR)
            }
          })
      }, prolongInterval / 2)
      this.sendBookmark = _throttle(this.saveBookmark.bind(this), BOOKMARK_SEND_INTERVAL * 1000, { leading: false })
      instance.on(PlayerEvent.TIME, this.sendBookmark)
    }
    instance.on(PlayerEvent.TIME, _throttle((currentTime) => {
      if (!currentTime || state.isSeeking) {
        return
      }

      if (state.currentLiveProgram?.type === PROGRAMME) {
        const TEN_HOURS_IN_SEC = 36000
        const isTimestamp = currentTime > TEN_HOURS_IN_SEC
        const currentProgram = state.currentLiveProgram

        if (!isTimestamp) {
          currentTime = this.$time(currentProgram.since).add(Math.round(currentTime), 'seconds').valueOf() / 1000
        }
      }

      if (store.setCurrentEventOnTimeline) {
        state.currentLiveProgram?.type === PROGRAMME
          ? store.setCurrentEventOnTimeline(state.head)
          : store.setCurrentEventOnTimeline(currentTime)
      }
      if (state.currentLiveProgram?.type === PROGRAMME) {
        const currentProgram = state.currentLiveProgram
        if (currentProgram.since) {
          if (state.timeshiftMode !== TIME_SHIFT_DISABLED) {
            const timeShiftStart = instance.timeShift.getStart()
            const currentLiveStart = this.$time(currentProgram.since).valueOf() / 1000
            const timeShiftOffset = currentLiveStart - timeShiftStart
            const progress = currentTime - timeShiftOffset
            store.setProgress(progress)
            try {
              store.setHead(instance.getDuration() - timeShiftOffset)
            } catch {}
          } else {
            const progress = $time($time()).diff(currentProgram.since, 's')
            store.setProgress(progress)
            store.setHead(progress)
          }
        }
      } else {
        store.setProgress(currentTime)
      }
    }, 1000))
    instance.on(PlayerEvent.SEEK_START, () => store.setSeeking?.(true))
    instance.on(PlayerEvent.SEEK_END, () => {
      const setSeeking = store.setSeeking
      if (setSeeking) {
        store.setSeeking(false)
      }
      return this.saveBookmark({ force: true })
    })
    instance.on(PlayerEvent.READY, () => {
      checkAutoplaySupport(AUTOPLAY_FAIL_TIMEOUT)
        .then(({ isAutoplayAllowed, isMutedAutoplayAllowed }) => {
          if (!isMutedAutoplayAllowed && !isAutoplayAllowed) {
            commit('player/SET_AUTOPLAY', AUTOPLAY_BLOCKED)
          } else if (!isAutoplayAllowed) {
            commit('player/SET_AUTOPLAY', AUTOPLAY_MUTED_BLOCKED)
          } else {
            commit('player/SET_AUTOPLAY', AUTOPLAY_CLICKED)
          }
        })
    })
    instance.on(PlayerEvent.METADATA, () => {
      const duration = instance.getDuration()
      if (state.product.type !== LIVE) {
        store.setDuration(duration)
      }
    })
    instance.on(PlayerEvent.WAITING, () => {
      store.setBuffering(true)
    })
    instance.on(PlayerEvent.WAITING_END, () => {
      store.setBuffering(false)
    })
    instance.on(PlayerEvent.CAN_PLAY, () => store.setBuffering(false))
    instance.on(PlayerEvent.PLAYING, () => {
      store.setBuffering(false)
      store.setPlayerStatus(STATUS_PLAYING)
    })
    // instance.on(VOLUME_CHANGED, (volume) => window.localStorage.setItem('p4PlayerVolume', volume))
    instance.on(PlayerEvent.ENDED, () => {
      store.setPlayerStatus(STATUS_ENDED)
    })
    instance.on(PlayerEvent.PAUSED, this.saveBookmark.bind(this))
    instance.on(PlayerEvent.STOPPED, this.destroy.bind(this))
    instance.on(PlayerEvent.ERROR, (e) => {
      console.log('playerError', e) // LEFT JUST IN CASE IF PLAYER THROW ANY ERROR
    })
    instance.on(PlayerEvent.SUBTITLES, (subtitles) => store.setSubtitles(mapSubtitles(subtitles)))
    instance.on(PlayerEvent.AUDIO_TRACK_LIST, (audioTracks) => {
      const setAudioTracks = store.setAudioTracks
      const mappedAudioTracks = mapAudioTracks(audioTracks)

      if (setAudioTracks) {
        store.setAudioTracks(mappedAudioTracks)
      }

      if (!state.settings?.audio?.lang) {
        const audioLanguage = defaultAudioLanguage(state)
        const audio = mappedAudioTracks.find(({ lang }) => lang === audioLanguage)
        if (audio) {
          store.setAudio?.(audio)
        }
      }
    })
    instance.on(PlayerEvent.AUDIO_TRACK_CHANGED, (audio) => {
      if (audio.id !== state.settings?.audio?.id) {
        store.setAudio?.(audio)
      }
    })

    instance.on(PlayerEvent.PROFILE_LIST, (profiles) => store.setProfiles?.(mapProfiles(profiles)))

    instance.on(PlayerEvent.SUBTITLE, () => store.switchSubtitles([]))
    instance.on(PlayerEvent.SUBTITLE_DISABLED, () => store.switchSubtitles([]))
    instance.on(PlayerEvent.SUBTITLE_CUE_CHANGED, (cue) => {
      const text = cue.text.trim()
      if (text) {
        const lines = text.split('\n')
        store.switchSubtitles(lines)
      } else {
        store.switchSubtitles([])
      }
    })
    instance.on(PlayerEvent.AD_START, () => {
      store.setAdvertIsRunning(true)
    })
    instance.on(PlayerEvent.AD_COMPLETE, () => {
      store.setAdvertIsRunning(false)
    })
    instance.on(PlayerEvent.AD_END, (data) => {
      if (data?.wasClicked) {
        commit('player/SET_AUTOPLAY', AUTOPLAY_CLICKED)
      }
      store.setAdvertIsRunning(false)
    })
    instance.on(PlayerEvent.STREAM_EVENT, (event) => {
      const eventUrl = window.atob(event.messageData).replace('[TIMESTAMP]', $time().format('YYYY-MM-DDTHH:mm:ss.sssZ'))
      window.fetch(eventUrl)
    })

    instance.on(POD_CHANGED, (pod) => {
      store.setPod(pod)
    })

    instance.on(SEND_EVENT, (eventData) => {
      this.$tracking.data.populateCommonData().then(commonData => {
        const adWrapperIds = eventData.ad.getWrapperAdIds()

        this.dispatch('events/SEND', {
          timestamp: this.$time().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
          event: eventData.eventType,
          uid: commonData.iuid,
          mpuid: this.getters['user/ACTIVE_PROFILE']?.id,
          platform: commonData.platform,
          terminal: commonData.terminal,
          os: commonData.os,
          osversion: commonData.osVersion,
          maker: commonData.maker,
          agent: commonData.agent,
          language: commonData.language,
          screenwidth: commonData.screenWidth,
          screenheight: commonData.screenHeight,
          contextvideoid: store.state.product.id,
          adservingid: adWrapperIds.length
            ? adWrapperIds[0]
            : eventData.ad.getAdId(),
          duration: eventData.ad.getDuration(),
          usersessionid: commonData.userSessionId
        })
      })
    })

    this.unloadfn = this.destroy.bind(this)
    window.addEventListener('beforeunload', this.unloadfn, false)
  }

  async saveBookmark ({ force = false } = {}) {
    if (!this.player) {
      return
    }

    const VALID_TYPES = [MOVIE, RECORDING]
    const profile = this.getters['user/ACTIVE_PROFILE']
    let videoType = this.playerConfig.videoType

    if (videoType === SERIAL) {
      videoType = MOVIE
    }

    if (!VALID_TYPES.includes(videoType)) {
      return
    }

    const playTime = Math.round(this.player.getCurrentTime())
    if (playTime < MIN_BOOKMARK_DURATION) {
      return
    }

    await this.dispatch('watched/ADD_WATCHED', {
      item: this.state.product,
      playTime,
      profile,
      videoType,
      type: WATCHED,
      force
    })
  }

  refreshBookmarks () {
    return this.dispatch('watched/GET_WATCHED')
  }

  async destroy () {
    this.stopSession()
    if (this.sendBookmark) {
      this.sendBookmark.cancel()
      await this.saveBookmark({ force: true })
      this.refreshBookmarks()
    }

    this.player = undefined
    window.removeEventListener('beforeunload', this.unloadfn, false)
  }

  stopSession () {
    if (this.sessionProlong) {
      clearInterval(this.sessionProlong)
      this.dispatch('player/STOP_SESSION', this.playerConfig.videoSession)
    }
  }

  isNowPlaying () {
    return this.$time().isBetween(this.state.currentLiveProgram.since, this.state.currentLiveProgram.till)
  }
}
