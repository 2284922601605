import { DEFAULT_PLATFORM } from '@/api/enums'

export function addDefaultPlatformInterceptor (interceptors) {
  interceptors.request.use(config => {
    if (!config.params) {
      config.params = {}
    }
    config.params.platform = DEFAULT_PLATFORM
    return config
  })
}
