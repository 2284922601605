export default {
  SET_SECTIONS (state, { sections, label, isComplete }) {
    const view = state.collection.find(view => view.label === label)
    if (!view) {
      return state.collection.push({
        label,
        sections,
        isComplete
      })
    }
    state.collection = state.collection.map(view => {
      if (view.label !== label) {
        return view
      }

      return {
        ...view,
        sections: [...view.sections, ...sections],
        isComplete
      }
    })
  },
  RESET_SECTIONS (state, label) {
    state.collection = state.collection.filter(view => view.label !== label)
  }
}
