export default (() => {
  if (!process.env.GTM) {
    return
  }
  /* eslint-disable */
  (function (w, d, s, l, i) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    let f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}`
    f.parentNode.insertBefore(j, f)
  }).call(this, window, document, 'script', 'dataLayer', process.env.GTM)

  window.dataLayer.reset = () => {
    if (window.google_tag_manager) {
      const gtm = window.google_tag_manager[process.env.GTM]
      gtm.dataLayer?.reset?.()
    }
    if (window.dataLayer) {
      window.dataLayer.length = 0
    }
  }

  return window.dataLayer
})()
