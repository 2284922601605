import { MOVIE, SERIAL } from '@/assets/javascript/enums/video-types'

export default {
  GET ({ commit }, { productId, videoType }) {
    return this.$api
      .resources
      .player
      .configuration
      .get(productId, videoType)
  },
  GET_EVENTS (_, itemId) {
    return this.$api
      .resources
      .player
      .events
      .get(itemId)
  },
  GET_PLAYLIST (_, { videoType, productId, videoSession }) {
    const videoSessionId = videoSession?.videoSessionId
    const type = videoType === SERIAL
      ? MOVIE
      : videoType
    const id = videoSession?.productId || productId

    return this.$axios
      .get(`/products/${id}/videos/playlist`, {
        params: {
          videoType: type,
          version: 3,
          videoSessionId
        },
        progress: false
      })
  },
  PROLONG_SESSION ({ commit }, { videoSessionId }) {
    return this.$api
      .resources
      .player
      .session
      .prolong(videoSessionId)
  },
  STOP_SESSION (_, { videoSessionId }) {
    return this.$api
      .resources
      .player
      .session
      .delete(videoSessionId)
  }
}
