import Vue from 'vue'
import LabelLayoutsDictionary from '@/assets/javascript/dictionaries/label-layouts'
import { PopupsDictionary } from '@/assets/javascript/dictionaries/popups'
import RoutesDictionary from '@/assets/javascript/dictionaries/routes'
import * as gtmEventsDictionary from '@/assets/javascript/dictionaries/gtmEvents'
import * as gtmEventsActionsDictionary from '@/assets/javascript/dictionaries/gtmEventsActions'

export default ({ store, app }) => {
  const dictionaries = {
    labelLayouts: LabelLayoutsDictionary,
    popups: PopupsDictionary,
    routes: RoutesDictionary,
    tracking: gtmEventsDictionary,
    trackingActions: gtmEventsActionsDictionary
  }
  app.$dict = store.$dict = Vue.prototype.$dict = dictionaries
}
