export function BroadcastProfiles ($http) {
  const BASE_URL = '/broadcast/profiles'
  return {
    get ({ firstResult, maxResults }) {
      return $http.get(BASE_URL, {
        progress: false,
        params: {
          firstResult,
          maxResults
        }
      })
    }
  }
}
