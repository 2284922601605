export const ACTION = 'action'
export const ECOMMERCE = 'ecommerce'
export const FORM = 'form'
export const PAGE = 'page'
export const PAGE_VIEW = 'page_view'
export const USER = 'user'
export const VIDEO = 'video'
export const SEARCH = 'search'
export const SECONDARY = 'secondary'
// events not used in real gtm, just for store purpose:
export const CURRENT_PAGE_DATA_LOG = 'currentPageDataLog'
export const PREVIOUS_PAGE_DATA_LOG = 'previousPageDataLog'
export const CHECKOUT_PRODUCT_DATA_LOG = 'checkoutProductDataLog'
export const LOGIN_PROVIDER = 'loginProvider'
export const PLAYER_INSTANCE_DATA_LOG = 'playerInstanceDataLog'
