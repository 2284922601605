import { ACTIVE, CANCELLED } from '@/assets/javascript/enums/cyclic-payment-status'
import Vue from 'vue'
const ITEM = 'ITEM'
const CANCELLED_BY_SUBSCRIBER = 'CANCELLED_BY_SUBSCRIBER'
const CARD_DELETED_BY_SUBSCRIBER = 'CARD_DELETED_BY_SUBSCRIBER'

export default {
  SET_ACCESS_TOKEN (state, accessToken) {
    state.firebaseAccessToken = accessToken
  },
  SET_AGREEMENTS_ACCEPTED (state) {
    state.data.status.missingAgreements = false
  },
  SET_ACTIVE_SUBSCRIPTIONS (state, subscriptions) {
    const activeSubscriptions = []

    subscriptions.forEach(item => {
      if (item.status === ACTIVE ||
        (item.status === CANCELLED &&
          (item?.cancelReason === CANCELLED_BY_SUBSCRIBER || item?.cancelReason === CARD_DELETED_BY_SUBSCRIBER) &&
          this.$time().isBefore(this.$time(item.nextChargeAt)))) {
        activeSubscriptions.push(item)
      }
    })

    state.activeSubscriptions = activeSubscriptions
  },
  SET_SUBSCRIPTION_AS_TERMINATED (state, cyclicPaymentId) {
    const subscriptionIndex = state.activeSubscriptions.findIndex(({ id }) => id === cyclicPaymentId)

    if (subscriptionIndex !== -1) {
      state.activeSubscriptions[subscriptionIndex].status = CANCELLED
    }
  },
  ADD_NOTIFICATION (state, notification) {
    state.notifications.push({ type: ITEM, item: notification })
  },
  REMOVE_NOTIFICATION (state, notificationId) {
    state.notifications = state.notifications.filter(({ item }) => item.id !== notificationId)
  },
  SET_USER (state, user) {
    state.data = user
  },
  SET_USER_PHONE (state, phone) {
    Vue.set(state.data, 'phone', phone)
  },
  SET_NOTIFICATIONS (state, notifications) {
    state.notifications = notifications
  },
  SET_AVAILABLE_PRODUCTS (state, products) {
    state.available = products
  },
  SET_PAYMENTS (state, payments) {
    state.payments = payments
  },
  SET_USER_PHONE_VERIFIED (state, isVerified) {
    state.data.verifiedPhone = isVerified
  },
  SET_USER_PHONE_TIMEOUT (state, isVerifyTimeout) {
    state.data.phoneVerifyTimeout = isVerifyTimeout
  },
  SET_DEVICES (state, devices) {
    state.devices = devices
  },
  SET_FAVOURITES_OPTIONS (state, favourites) {
    state.favourites = favourites
  },
  DELETE_DEVICE (state, deviceUid) {
    const deviceIndex = state.devices.findIndex(({ uid }) => uid === deviceUid)
    state.devices.splice(deviceIndex, 1)
  },
  SET_ACTIVE_PROFILE (state, profile) {
    state.data.activeProfileId = profile.id
  },
  UPDATE_PROFILE (state, updatedProfile) {
    state.data.profiles = state.data.profiles.map((profile) => {
      if (updatedProfile.id === profile.id) {
        return updatedProfile
      }
      return profile
    })
  },
  ADD_PROFILE (state, profile) {
    state.data.profiles.push(profile)
  },
  DELETE_PROFILE (state, profileId) {
    const index = state.data.profiles.findIndex((stateProfile) => stateProfile.id === profileId)
    state.data.profiles.splice(index, 1)
  },
  SET_AGREEMENTS (state, agreements) {
    state.agreements = agreements
  }
}
