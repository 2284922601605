import { WATCHED } from '@/assets/javascript/enums/bookmarks-types'

export default {
  GET_WATCHED ({ dispatch, commit }) {
    return dispatch('user/GET_BOOKMARKS', {
      type: WATCHED,
      maxResults: 0
    }, {
      root: true
    })
      .then(watched => {
        commit('SET', watched)
        return watched
      })
  },
  ADD_WATCHED ({ dispatch, commit }, { item, type, playTime, videoType, profile, force }) {
    return dispatch('user/POST_BOOKMARK', {
      id: item.id,
      type,
      playTime,
      videoType,
      profile,
      force
    }, { root: true })
      .then(() => {
        commit('ADD', {
          modifiedAt: this.$time().format('YYYY-MM-DD HH:mm:ss'),
          item,
          playTime
        })
      })
  },
  REMOVE_FROM_WATCHED ({ commit, state, dispatch }, item) {
    const { id, title, profileToken, profileId } = item

    return dispatch('user/DELETE_BOOKMARK', {
      id,
      profileId,
      profileToken,
      title,
      type: WATCHED
    }, {
      root: true
    })
      .then(
        () => {
          commit('REMOVE', item.id)
          this.$toast.success(this.$i18n.t('watched.deleted', { title }))
        },
        () => this.$toast.error(this.$i18n.t('watched.errorOnDelete'))
      )
  }
}
