import ResponseMap from '@/assets/javascript/dictionaries/responses'

export default function errorMapper ($t, e) {
  const errors = {}

  const messages = {
    AUTHENTICATION_REQUIRED: $t('errors.authenticationRequired'),
    AGREEMENTS_NOT_ALL_ACCEPTED: $t('errors.agreementsNotAccepted'),
    BROADCAST_PROFILE_NOT_EXISTS: $t('errors.broadcastProfileNotExists'),
    CAPTCHA_BLANK: $t('errors.captchaBlank'),
    CAPTCHA_MISMATCH: $t('errors.captchaMismatch'),
    COMMON_INVALID_EMAIL: $t('errors.invalidEmail'),
    CONTACT_CONTENT_BLANK: $t('errors.contentBlank'),
    CONTACT_SENDER_EMAIL_BLANK: $t('errors.emailBlank'),
    CONTACT_TITLE_BLANK: $t('errors.titleBlank'),
    INTERNAL_ERROR: $t('errors.internalError'),
    GEOIP_EEA_FILTER_FAILED: $t('errors.geoip'),
    PASSWORD_COMMON: $t('errors.passwordCommon'),
    PASSWORD_INVALID_LENGTH: $t('errors.passwordInvalidLength'),
    PAYMENT_CHANNEL_NOT_SUPPORTED: $t('errors.paymentChannelNotSupported'),
    PAYMENT_EXISTS: $t('errors.paymentExists'),
    PAYMENT_SESSION_ID_INVALID: $t('errors.paymentInvalidSession'),
    PAYMENT_SIGN_GENERATION_ERROR: $t('errors.paymentSignError'),
    PAYMENT_INVALID_SIGN: $t('errors.paymentSignInvalid'),
    PAYMENT_BROKER_SERVICE_UNAVAILABLE: $t('errors.paymentBrokerServiceUnavailable'),
    PAYMENT_BROKER_RESPONSE_ERROR: $t('errors.paymentBrokerResponseError'),
    PAYMENT_SCHEDULE_NOT_BELONG_TO_PRODUCT: $t('errors.paymentScheduleNotBelongToProduct'),
    PAYMENT_UNSUPPORTED_TRANSACTION_TYPE: $t('errors.paymentUnsupportedTransactionType'),
    PHONE_NUMBER_NOT_UNIQUE: $t('errors.phoneNumberNotUnique'),
    PHONE_PROVIDER_WRONG_DETAILS: $t('errors.wrongPhoneNumber'),
    PRODUCT_HAS_NO_ACTIVE_SCHEDULE: $t('errors.productHasNoActiveSchedule'),
    PRODUCT_NOT_EXISTS: $t('errors.productNotExists'),
    SUBSCRIBER_EMAIL_BLANK: $t('errors.emailBlank'),
    SUBSCRIBER_EMAIL_EXISTS: $t('errors.emailExists'),
    SUBSCRIBER_PHONE_EXISTS: $t('errors.phoneExists'),
    SUBSCRIBER_EMAIL_NOT_ACCESSIBLE: $t('errors.subscriberEmailNotAccessible'),
    SUBSCRIBER_INVALID_LOGIN_OR_PASSWORD: $t('errors.invalidLoginOrPassword'),
    SUBSCRIBER_INVALID_OLD_PASSWORD: $t('errors.invalidOldPassword'),
    SUBSCRIBER_NOT_ACTIVE: $t('errors.subscriberNotActive'),
    SUBSCRIBER_NOT_EXISTS: $t('errors.subscriberNotExists'),
    SUBSCRIBER_NOT_VERIFIED: $t('errors.notVerified'),
    SUBSCRIBER_PASSWORD_BLANK: $t('errors.passwordBlank'),
    SUBSCRIBER_PHONE_BLANK: $t('errors.phoneBlank'),
    SUBSCRIBER_PHONE_TOKEN_BLANK: $t('errors.phoneTokenBlank'),
    SUBSCRIBER_PHONE_TOKEN_EXPIRED: $t('errors.phoneTokenExpired'),
    SUBSCRIBER_PHONE_TOKEN_NOT_EXISTS: $t('errors.phoneTokenNotExists'),
    SUBSCRIBER_PASSWORD_TOKEN_NOT_EXISTS: $t('errors.subscriberPasswordTokenNotExists'),
    UNKNOWN_ERROR: $t('errors.unknownError'),
    OTC_BLANK: $t('errors.otcBlank'),
    OTC_EXPIRED: $t('errors.otcExpired'),
    OTC_NOT_EXISTS: $t('errors.otcNotExists'),
    OTC_NOT_VERIFIED: $t('errors.otcNotVerified'),
    PROMOTION_CODE_NOT_EXISTS: $t('errors.promotionCodeNotExists'),
    PROMOTION_CODE_VALUE_NULL: $t('errors.promotionCodeValueNull'),
    PROFILE_NAME_BLANK: $t('errors.profileNameBlank'),
    PROFILE_WRONG_BIRTH_DAY: $t('errors.profileWrongBirthDay'),
    PROFILE_WRONG_BIRTH_MONTH: $t('errors.profileWrongBirthMonth'),
    PROFILE_WRONG_BIRTH_YEAR: $t('errors.profileWrongBirthYear'),
    PROFILE_BROADCAST_NOT_EXISTS: $t('errors.broadcastProfileNotExists'),
    PROFILE_BROADCAST_ALREADY_EXISTS: $t('errors.broadcastProfileAlreadyExists'),
    PROFILE_BROADCAST_NAME_BLANK: $t('errors.broadcastProfileNameBlank'),
    PROFILE_BROADCAST_CITY_BLANK: $t('errors.broadcastProfileCityBlank'),
    PROFILE_BROADCAST_BIRTH_DATE_BLANK: $t('errors.broadcastProfileBirthDateBlank')
  }

  const message = (code) => {
    const message = messages[code]

    if (message) {
      return message
    }

    errors.closable = true
    return messages.UNKNOWN_ERROR
  }

  const get = () => {
    let data = {}
    if (e?.response?.data) {
      data = e.response.data
    } else if (e?.data) {
      data = e.data
    }

    data.errors = data.errors || []

    if (data.code) {
      errors.global = message(data.code)
      errors.code = data.code
    }

    data.errors.forEach((error) => {
      errors[error.field] = message(error.code)
    })

    if (Object.entries(errors).length === 0) {
      throw new Error('Unknown error')
    }

    return errors
  }

  if (e?.response?.status === 403) {
    const code = e?.response?.data?.code
    if (ResponseMap[code]) {
      return Promise.resolve({})
    }
  }

  // When error in error handling occurs...
  try {
    return Promise.resolve(get())
  } catch (ignore) {
    return Promise.resolve({ global: messages.UNKNOWN_ERROR })
  }
}
