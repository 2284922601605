import Vue from 'vue'

export default ({ app }) => {
  const loader = {
    show (parent, opts = {}) {
        const loader = document.createElement('div')
        const position = getComputedStyle(parent).position

        if (!position || position === 'static') {
          parent.style.position = 'relative'
        }

        loader.classList.add('loading')

        if (opts.small) {
          loader.classList.add('loading--small')
        }

        if (opts.noBackground) {
          loader.classList.add('loading--no-background')
        }

        parent.appendChild(loader)
    },
    hide (parent) {
      Vue.nextTick(() => {
        if (!parent) {
          return
        }

        const loader = parent.querySelector(':scope > .loading')
        if (!loader) {
          return
        }

        parent.removeChild(loader)
        parent.style.position = ''
      })
    }

  }

  app.$loader = Vue.prototype.$loader = loader
}
