import { FORM, PAGE, SECONDARY, USER } from '@/assets/javascript/dictionaries/gtmEvents'
import { TRACKING_DELAY } from '@/plugins/tracking/scripts/events'

export default {
  data () {
    return {
      timeoutId: null,
      trackingFormData: {
        formName: this.$options.name,
        validationError: null,
        submissionError: null
      },
      trackingSecondaryData: {
          itemId: null,
          displayTime: null,
          value: null,
          url: null,
          videoSessionId: this.$tracking.data.getSessionId()
      }
    }
  },
  methods: {
    sendFormTrackingData (action) {
      return this.$tracking.events.send(FORM, {
        form: this.trackingFormData,
        action
      })
    },
    sendPageTrackingData (data) {
      this.$tracking.events.send(PAGE, { product: data })
    },
    sendUserTrackingData (action) {
      this.$tracking.events.send(USER, { action })
    },
    sendSecondaryTrackingData (data) {
      this.$tracking.events.send(SECONDARY, { secondaryData: data })
    },
    sendTrackingEventWithTimeout (event, data) {
      this.timeoutId = window.setTimeout(() => {
        this.$tracking.events.send(event, data)
      }, TRACKING_DELAY)
    },
    resetTrackingEventTimeout () {
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId)
      }
    }
  }
}
