export function CMP ($http, env) {
  const [username, password] = env.PROXY_BASE_AUTH?.split(':')
  const params = {
    withCredentials: false,
    auth: {
      username,
      password
    },
    params: {
      tenantUuid: '',
      lang: 'POL'
    }
  }

  return {
    getCmpInfo (cmpUrl, tenant) {
      params.params.tenantUuid = tenant
      return $http.get(`${cmpUrl}`, params)
    },
    getConsentList (tenant, cmpUrl) {
      params.params.tenantUuid = tenant
      return $http.get(`${cmpUrl}`, params)
    },
    updateTCString (payload, cmpUrl, tenant) {
      params.params.tenantUuid = tenant
      return $http.post(`${cmpUrl}`, payload, params)
    }
  }
}
