import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  if (window.localStorage) {
    createPersistedState({
      paths: [
        'adverts.adSessionId',
        'compatibility-test.checked',
        'user.type',
        'user.data',
        'user.redirectUri',
        'cmp.TCString',
        'cmp.deviceUID'
      ]
    })(store)
  }
}
