import { FAVOURITE } from '@/assets/javascript/enums/bookmarks-types'

export default {
  GET_FAVOURITES ({ dispatch, commit }) {
    return dispatch('user/GET_BOOKMARKS', {
      type: FAVOURITE
    }, {
      root: true
    })
      .then(favourites => {
        commit('SET', favourites)
        return favourites
      })
  },
  ADD_FAVOURITE ({ dispatch, commit }, item) {
    const { id, title, videoType, profile } = item
    return dispatch('user/POST_BOOKMARK', {
      id,
      title,
      videoType,
      profile,
      type: FAVOURITE
    }, { root: true })
      .then(
        () => {
          commit('ADD', item)
          this.$toast.success(this.$i18n.t('favourites.added', { title }))
        },
        () => this.$toast.error(this.$i18n.t('favourites.errorOnAdd'))
      )
  },
  REMOVE_FAVOURITE ({ commit, state, dispatch }, item) {
    const { id, title, profileId, profileToken } = item

    return dispatch('user/DELETE_BOOKMARK', {
      id,
      title,
      profileId,
      profileToken,
      type: FAVOURITE
    }, {
      root: true
    })
      .then(
        () => {
          commit('REMOVE', item.id)
          this.$toast.success(this.$i18n.t('favourites.deleted', { title }))
        },
        () => this.$toast.error(this.$i18n.t('favourites.errorOnDelete'))
      )
  }
}
