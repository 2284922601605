import Vue from 'vue'
import * as metadata from '@/assets/javascript/enums/metadata-types'
import * as products from '@/assets/javascript/enums/product-types'
import * as layouts from '@/assets/javascript/enums/section-layouts'
import * as sectionTypes from '@/assets/javascript/enums/section-types'

export default ({ app, store }) => {
  app.$enums = store.$enums = Vue.prototype.$enums = {
    metadata,
    products,
    layouts,
    sectionTypes
  }
}
