const format = 'YYYY-MM-DDTHH:mmZZ'

export default {
  GET (_, { id, since = '', till = '' }) {
    const now = this.$time().format(format)
    const next24Hours = this.$time().add('24', 'hours').format(format)
    return this.$api
      .resources
      .epg
      .get({ productId: id, since: since || now, till: till || next24Hours })
  },
  GET_LIVE_NOW ({ commit, dispatch, rootGetters }, opts = {}) {
    return dispatch('GET_EPG_FOR_HOUR', opts)
      .then(programs => {
        const programme = programs
          .find(({ since, till }) => this.$time().isBetween(since, till))
        if (programme?.live?.active) {
          commit('SET_CURRENT_EPG_PROGRAMME', programme)
        }
        return programme || {}
      })
  },
  GET_EPG_FOR_HOUR ({ dispatch }, { id, since }) {
    const _since = this.$time(since)
    const startOfHour = _since.startOf('hour').format(format)
    const endOfHour = _since.add(1, 'hour').startOf('hour').format(format)
    return dispatch('GET', {
      id,
      since: startOfHour,
      till: endOfHour
    }, {
      timeout: 60 * 60 * 1000
    })
  },
  GET_NEXT_PROGRAMME ({ dispatch }, { live, till, type }) {
    return dispatch('GET_EPG_FOR_HOUR', {
      id: live.id,
      since: till
    })
      .then(products => products.find(({ since }) => since === till))
  }
}
