import { checkProductAvailabilityForUser, isBackEpg, isCatchup } from '@/assets/javascript/utils'
import ChannelFactory from '@/assets/javascript/play/factory/channel'

export default {
  GET ({ commit }, userProducts) {
    return this.$api.resources.products.lives
      .get()
      .then(data => {
        commit('SET', data.map((channel, index) => {
          const uid = `${channel.id}${index}`
          if (userProducts) {
            const paid = !!checkProductAvailabilityForUser(channel, userProducts?.availableProductIds)
            return Object.freeze({
              ...ChannelFactory(channel),
              paid,
              npvrAvailable: userProducts.hasNpvr && channel.npvrAvailable,
              isBackEPG: isBackEpg(channel.id, { products: userProducts }) && channel.backwardsEpgAvailable,
              isCatchup: isCatchup(channel.id, { products: userProducts }) && channel.catchUpAvailable,
              uid
            })
          }

          return Object.freeze({ ...ChannelFactory(channel), uid })
        }))
      })
  },
  GET_BY_CATEGORY ({ commit }, { slug }) {
    return this.$axios
      .get('/products/lives', {
        params: {
          categorySlug: [slug]
        }
      })
      .then(data => data.map(channel => Object.freeze(ChannelFactory(channel))))
  },
  GET_AVAILABLE_BY_TV_CATEGORY ({ rootState, state, getters, dispatch }) {
    const category = rootState.tv.category

    if (!category.name) {
      return Promise.resolve(state.items)
    }
    if (category.filter === 'AVAILABLE') {
      return Promise.resolve(getters.availableChannels)
    }
    return dispatch('GET_BY_CATEGORY', category)
  }
}
