import {
  ACTORS,
  COUNTRIES,
  DIRECTORS,
  DURATION,
  EPISODE,
  GENRES,
  RATING,
  SCRIPTWRITERS,
  SEASON,
  SUBTITLE,
  YEAR
} from '@/assets/javascript/enums/metadata-types'

const keys = [
  ACTORS,
  COUNTRIES,
  DIRECTORS,
  DURATION,
  EPISODE,
  SCRIPTWRITERS,
  GENRES,
  SEASON,
  SUBTITLE,
  YEAR,
  RATING
]

export default function (product) {
  return keys.map(key => ({ [key]: product[key] }))
    .filter(obj => {
      const value = Object.values(obj)[0]
      return value && (value.length || value > 0 || value.number)
    })
    .reduce((prev, current) => {
      return { ...prev, ...current }
    }, {})
}
