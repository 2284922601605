export default {
  ADD_BROADCAST_PROFILE ({ commit }, profile) {
    return this.$api.resources
      .broadcast
      .profile
      .post(profile)
      .then((data) => {
        commit('SET_BROADCAST_PROFILE', profile)
        return data
      })
  },
  GET_BROADCAST_PROFILE ({ commit }) {
    return this.$api.resources
      .broadcast
      .profile
      .get()
      .then(profile => {
        commit('SET_BROADCAST_PROFILE', profile)
        return profile
      })
  },
  IS_USER_ALLOWED (_, programmeId) {
    return this.$api
      .resources
      .products
      .programmes
      .profiles
      .get(programmeId)
  },
  SUBMIT_FILE_PHOTO ({ commit }, file) {
    return this.$api.resources
      .broadcast
      .profile
      .photo
      .post(file)
  },
  UPDATE_BROADCAST_PROFILE ({ commit }, profile) {
    return this.$api.resources
      .broadcast
      .profile
      .update(profile)
  }
}
