if (!document.getElementById('fb-root')) {
  // create div required for fb
  const fbDiv = document.createElement('div')
  fbDiv.id = 'fb-root'
  document.body.appendChild(fbDiv)
}

window.fbAsyncInit = function () {
  window.FB.init({
    appId: process.env.FACEBOOK_APP_ID,
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v5.0'
  })
  // eslint-disable-next-line no-undef
  window.FB.AppEvents.logPageView()
};

(function () {
  const id = 'facebook-jssdk'
  if (document.getElementById(id)) { return }
  const fjs = document.querySelector('script')
  const js = document.createElement('script')
  js.async = true
  js.id = id
  js.src = 'https://connect.facebook.net/en_US/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
}())
