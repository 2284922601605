export default {
  SET_RECORDINGS (state, recordings) {
    state.recordings = recordings
  },
  ADD_RECORDING (state, recording) {
    state.recordings.push(recording)
  },
  REMOVE_RECORDING (state, id) {
    state.recordings = state.recordings
      .filter(recording => recording.id !== id)
  }
}
