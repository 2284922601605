export function checkProductAvailabilityForUser ({ id, live, liveId }, products) {
  if (products) {
    return products.find((productId) => productId === (liveId || live?.id || id))
  }
}

export function duration (since, till) {
  return (till - since) / 1000 / 60 // convert to min
}

export function isBackEpg (liveId, user) {
  const livesWithBEPG = user.products.liveWithBackwardsEpgIds
  if (livesWithBEPG) {
    return !!livesWithBEPG.find(id => id === liveId)
  }
}

export function isCatchup (liveId, user) {
  const livesWithCatchup = user.products.liveWithCatchUpIds
  if (livesWithCatchup) {
    return !!livesWithCatchup.find(id => id === liveId)
  }
}

export function isGift (product, paid, promotions) {
  if (paid || !promotions) {
    return
  }
  return !!promotions
    .find(({ productIds }) => productIds
      .find(id => id === product.id))
}

export function asyncLeadingThrottle (func, wait) {
  let inThrottle
  return async function () {
    if (inThrottle) {
      return
    }

    inThrottle = true
    setTimeout(() => (inThrottle = false), wait)

    const args = arguments
    const context = this
    return func.apply(context, args)
  }
}

export const provideDefaultSecondParamsHelper = (fn, defaultParams) => (firstParam, secondParam) => {
  fn.call(this, firstParam, { ...defaultParams, ...secondParam })
}

export function getRandomHexString () {
  const buffer = crypto.getRandomValues(new Uint8Array(16))
  const array = new Uint8Array(buffer)
  let result = ''
  for (let i = 0, max = array.length; i < max; i++) {
    const value = array[i].toString(16)
    result += (value.length === 1 ? '0' + value : value)
  }
  return result
}
