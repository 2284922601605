
import { uuid } from 'uuidv4'

export default {
  CLEAR_EVENT_DATA ({ commit }, event) {
    commit('CLEAR_EVENT_DATA', event)
  },
  SET_EVENT_DATA ({ commit }, { event, data }) {
    commit('SET_EVENT_DATA', { event, data })
  },
  GET_UID ({ state, commit, rootGetters }) {
    if (!state.uid) {
      const { id: userId } = rootGetters['user/GET_ACTIVE_PROFILE'] || {}
      const uid = localStorage?.getItem('uid') || userId || uuid()
      commit('SET_UID', uid)
    }
    return state.uid
  },
 SET_UID ({ commit }, uid) {
    commit('SET_UID', uid)
  },
  async GET_IUID ({ state, dispatch, commit }, uid) {
    if (uid) {
      await dispatch('SET_UID', uid)
    }

    if (!state.iuid) {
      const uid = await dispatch('GET_UID')
      const iuid = localStorage?.getItem('iuid') ||
        await this.$abtShield
          .getIuid(uid)
          .then(({ data }) => {
            return data.iuid
          })
      commit('SET_IUID', iuid)
    }
    return state.iuid
  },
  SET_IUID ({ commit }, iuid) {
    commit('SET_IUID', iuid)
  },
  SET_SESSID ({ commit }, sessid) {
    commit('SET_SESSID', sessid)
  },
  SET_SESSION_TIMESTAMP ({ commit }, timeStamp) {
    commit('SET_SESSION_TIMESTAMP')
  }
}
