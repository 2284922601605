export function Broadcast ($http) {
  const BASE_URL = '/subscribers/broadcast/profiles'
  return {
    profile: {
      get () {
        return $http.get(BASE_URL, {
          progress: false
        })
      },
      post (profile) {
        return $http.post(BASE_URL, profile)
      },
      update (profile) {
        return $http.put(BASE_URL, profile)
      },
      photo: {
        post (file) {
          return $http.post(`${BASE_URL}/photo`, file, {
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          })
        }
      }
    }
  }
}
