export function Contacts ($http) {
  const BASE_URL = '/contacts'
    return {
      submitForm (payload) {
        return $http.post(`${BASE_URL}?isCaptchaV3=true`, payload)
      },
      threads ({ firstResult, maxResults }) {
        return $http.get(`${BASE_URL}/threads`, {
          params: {
            firstResult,
            maxResults
          }
      })
    }
  }
}
