export default {
  CAPTCHA: /\w{6}/,
  USERNAME: /^.{3,}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
  PHONE_CODE: /[a-z0-9]{6}/,
  POLISH_PHONE: /\d{9}/,
  POLISH_PHONE_OPTIONAL: /^$|\d{9}/,
  MIN_LENGTH_3: /^.{3,}$/,
  MIN_LENGTH_5: /^.{5,}$/,
  ZIP_CODE: /\d{2}-\d{3}/
}
