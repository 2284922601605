export default {
  SET_REMINDERS (state, reminders) {
    state.items = reminders
  },
  ADD (state, reminder) {
    state.items.push(reminder)
  },
  REMOVE (state, remindId) {
    state.items = state.items
      .filter(remind => remind.id !== remindId)
  }
}
