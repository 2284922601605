import { USER_LOGOUT } from 'assets/javascript/dictionaries/gtmEventsActions'
import { LOGIN_PROVIDER } from 'assets/javascript/dictionaries/gtmEvents'

export default {
  methods: {
    logoutUser () {
      this.sendUserTrackingData(USER_LOGOUT)
      this.$tracking.data.clearEventData(LOGIN_PROVIDER)
      this.$store.dispatch('user/LOGOUT', { timestamp: this.$time().valueOf() })
        .finally(() => {
          return this.$router.go(0)
        })
    }
  }
}
