export const PopupsDictionary = {
  ACCEPT_CONSENTS: 'accept-consents',
  ADD_CARD: 'add-card',
  AGE_CONFIRMATION: 'age-confirmation',
  ADD_VOUCHER: 'add-voucher',
  BROADCAST_CONFIRMATION: 'broadcast-confirmation',
  BROADCAST_CONSENT: 'broadcast-consent',
  BUY_PRODUCT: 'buy-product',
  CMP: 'cmp',
  COMPATIBILITY_TEST: 'compatibility-test',
  CANCEL_SUBSCRIPTION: 'cancel-subscription',
  CHANGE_PASSWORD: 'change-password',
  CHANGE_PHONE: 'change-phone',
  CONSENTS_NOTICE: 'consents-notice',
  DELETE_PHONE: 'delete-phone',
  DOCUMENT: 'document',
  LOGIN: 'login',
  PAYMENT_SUCCESS: 'payment-success',
  PAYMENT_FAILURE: 'payment-failure',
  PRODUCT_IN_BUNDLE: 'product-in-bundle',
  PROFILE: 'profile',
  RANKING: 'ranking',
  REGISTER_SUCCESS: 'register-success',
  REMOVE_CARD: 'remove-card',
  REMOVE_DEVICE: 'remove-device',
  REMOVE_PROFILE: 'remove-profile',
  SESSION_EXPIRED: 'session-expired',
  VERIFY_PHONE: 'verify-phone'
}
