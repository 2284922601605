import { mp4Blob } from '@/components/player/asset/blobs'

const createVideo = function (options = {}) {
  const video = document.createElement('video')

  if (options.muted) {
    video.volume = 0
    video.muted = true
  }

  video.setAttribute('playsinline', '1')
  video.setAttribute('src', URL.createObjectURL(mp4Blob))
  video.load()

  return video
}

export const checkAutoplaySupport = (timeout = 300) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    setTimeout(() => {
      resolve({
        isAutoplayAllowed: false,
        isMutedAutoplayAllowed: false
      })
    }, timeout)

    const isAutoplayAllowed = await createVideo().play()
      .then(() => true)
      .catch(() => false)

    const isMutedAutoplayAllowed = await createVideo({ muted: true }).play()
      .then(() => true)
      .catch(() => false)

    resolve({
      isAutoplayAllowed,
      isMutedAutoplayAllowed
    })
  })
}
