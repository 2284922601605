import { BROADCAST_PARTICIPATION } from '@/pages/my-account/profile/enums/agreement-types'

export default {
  ACTIVE_PROFILE: (state) => {
    return state.data.profiles?.find(({ id }) => id === state.data.activeProfileId)
  },
  PROFILE_BY_ID: (state) => (profileId) => {
    return state.data.profiles?.find(({ id }) => id === profileId)
  },
  IS_IN_NOTIFICATIONS: (state) => (id) => {
    return state.notifications.find(({ item }) => item.id === id)
  },
  LOGGED_IN: (state) => {
    return !!state.data.id
  },
  BROADCAST_AGREEMENT: (state) => {
    const broadcastAgreement = state.agreements.find(agreement => agreement.agreement.type === BROADCAST_PARTICIPATION)
    return broadcastAgreement ? broadcastAgreement.value : false
  },
  AVAILABLE_PRODUCTS: (state) => {
    return state.available
  },
  AD_BREAK_FREE_TILL: (state) => {
    return state.data?.status?.adBreakFreeTill
  }
}
