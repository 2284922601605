import { API_DEVICE_HEADER } from '@/api/enums'
import { getRandomHexString } from '@/assets/javascript/utils'

export function addDefaultHeadersInterceptor (interceptors, store) {
  interceptors.request.use(config => {
    let uid = store.$cookie.get('uid')

    if (!uid) {
      uid = getRandomHexString()

      if (process.env.USE_PROXY) {
        store.$cookie.set('uid', uid)
      } else {
        store.$cookie.set('uid', uid, { domain: process.env.COOKIE_DOMAIN })
      }
    }

    config.headers[API_DEVICE_HEADER] = uid
    return config
  })
}
