const DEFAULT_MAX_PROFILES = 10

export default {
  GET_BROADCAST_PROFILES ({ commit }, { firstResult = 0, maxResults = DEFAULT_MAX_PROFILES }) {
    return this.$api
      .resources
      .broadcastProfiles
      .get({ firstResult, maxResults })
      .then(({ items }) => {
        commit('SET_BROADCAST_PROFILES', items)
        return items
      })
  }
}
