import { isGift, checkProductAvailabilityForUser } from '@/assets/javascript/utils'
import ChannelFactory from '@/assets/javascript/factories/channel'
import { LIVE, PACKET } from '@/assets/javascript/enums/product-types'

export default function (packet, user) {
  const products = user.products
  const paid = checkProductAvailabilityForUser(packet, products.availableProductIds)
  const Packet = {
    id: packet.id,
    title: packet.title,
    description: packet.description,
    paid,
    channels: packet.products?.filter(product => product.type === LIVE)
      .map(ChannelFactory) || [],
    collections: packet.products?.filter(product => product.type === PACKET)
      .map(ChannelFactory) || [],
    isGift: isGift(packet, paid, products.promotions),
    price: packet.priceWithVat || packet.price,
    family: packet.family,
    canBuy: products.packetPurchaseAvailable
  }
  return Packet
}
