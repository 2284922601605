export function Authorizations ($http) {
  return {
    loggedIn: {
      tokens: {
        post (payload) {
          return $http.post('subscribers/authorizations/tokens', payload)
        }
      }
    },
    tokens: {
      post (payload) {
        return $http.post('authorizations/tokens', payload)
      }
    }
  }
}
