export const state = () => ({
  body: {
    order: 'desc',
    page: 1,
    sort: 'createdAt',
    firstResult: 0,
    maxResults: 18
  },
  byCategory: {}
})
