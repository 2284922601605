import { STUDIO } from '@/pages/transmission/enums'

export default {
  GET_ACCESS_TOKEN ({ commit }, { itemId, type, room }) {
    return this.$api
      .resources
      .authorizations
      .loggedIn
      .tokens
      .post({ itemId, type, room })
      .then(({ token }) => {
        if (type !== STUDIO) {
          commit('user/SET_ACCESS_TOKEN', token, { root: true })
        }
        return token
      })
  },
  GET_ACCESS_TOKEN_FOR_NOT_LOGGED_IN ({ commit }, { itemId, type, room }) {
    return this.$api
      .resources
      .authorizations
      .tokens
      .post({ itemId, type, room })
      .then(({ token }) => {
        commit('user/SET_ACCESS_TOKEN', token, { root: true })
        return token
      })
  }
}
