import Vue from 'vue'

let rAF

// TODO REFAKTOR NA komponent
export const ResponsiveObservable = Vue.observable({
  height: -1,
  width: -1
})

window.addEventListener('resize', (e) => {
  if (rAF) {
    window.cancelAnimationFrame(rAF)
  }
  rAF = window.requestAnimationFrame(() => setDimensions(e.target))
}, { passive: true })

window.setTimeout(() => {
  setDimensions(window)
}, 1)

function setDimensions (window) {
  ResponsiveObservable.height = window.innerHeight
  ResponsiveObservable.width = window.innerWidth
}
