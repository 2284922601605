export const DEFAULT_MAX_THREADS = 20

export default {
    GET_THREADS (_, { firstResult = 0, maxResults = DEFAULT_MAX_THREADS }) {
        return this.$api
            .resources
            .contacts
            .threads({ firstResult, maxResults })
    },
    SUBMIT_FORM (_, payload) {
        return this.$api
            .resources
            .contacts
            .submitForm(payload)
    }
}
