export function Epg ($http) {
  const BASE_URL = '/products'

  return {
    get ({ productId, since, till }) {
      return $http.get(`${BASE_URL}/lives/programmes`, {
        params: {
          liveId: [productId],
          since,
          till
        }
      })
    }
  }
}
