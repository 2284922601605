export function Documents ($http) {
  const BASE_URL = '/documents'
  return {
    get (alias) {
      return $http.get(`${BASE_URL}/${alias}`)
    },
    getContent (alias) {
      return $http.get(`${BASE_URL}/${alias}/content`)
    }
  }
}
