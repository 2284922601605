import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/watched/index.js'), 'watched/index.js')
  resolveStoreModules(require('../store/user/index.js'), 'user/index.js')
  resolveStoreModules(require('../store/tv/index.js'), 'tv/index.js')
  resolveStoreModules(require('../store/tracking/index.js'), 'tracking/index.js')
  resolveStoreModules(require('../store/sections/index.js'), 'sections/index.js')
  resolveStoreModules(require('../store/scores/index.js'), 'scores/index.js')
  resolveStoreModules(require('../store/reminders/index.js'), 'reminders/index.js')
  resolveStoreModules(require('../store/recordings/index.js'), 'recordings/index.js')
  resolveStoreModules(require('../store/recommendations/index.js'), 'recommendations/index.js')
  resolveStoreModules(require('../store/profiles/index.js'), 'profiles/index.js')
  resolveStoreModules(require('../store/products/index.js'), 'products/index.js')
  resolveStoreModules(require('../store/playlist/index.js'), 'playlist/index.js')
  resolveStoreModules(require('../store/player/index.js'), 'player/index.js')
  resolveStoreModules(require('../store/payments/index.js'), 'payments/index.js')
  resolveStoreModules(require('../store/menu/index.js'), 'menu/index.js')
  resolveStoreModules(require('../store/list/index.js'), 'list/index.js')
  resolveStoreModules(require('../store/layout/index.js'), 'layout/index.js')
  resolveStoreModules(require('../store/favourites/index.js'), 'favourites/index.js')
  resolveStoreModules(require('../store/epg/index.js'), 'epg/index.js')
  resolveStoreModules(require('../store/compatibility-test/index.js'), 'compatibility-test/index.js')
  resolveStoreModules(require('../store/cmp/index.js'), 'cmp/index.js')
  resolveStoreModules(require('../store/channels/index.js'), 'channels/index.js')
  resolveStoreModules(require('../store/broadcast/index.js'), 'broadcast/index.js')
  resolveStoreModules(require('../store/broadcast-profiles/index.js'), 'broadcast-profiles/index.js')
  resolveStoreModules(require('../store/api/index.js'), 'api/index.js')
  resolveStoreModules(require('../store/adverts/index.js'), 'adverts/index.js')
  resolveStoreModules(require('../store/adblock/index.js'), 'adblock/index.js')
  resolveStoreModules(require('../store/adblock/mutations.js'), 'adblock/mutations.js')
  resolveStoreModules(require('../store/adverts/actions.js'), 'adverts/actions.js')
  resolveStoreModules(require('../store/adverts/getters.js'), 'adverts/getters.js')
  resolveStoreModules(require('../store/adverts/mutations.js'), 'adverts/mutations.js')
  resolveStoreModules(require('../store/api/actions.js'), 'api/actions.js')
  resolveStoreModules(require('../store/api/mutations.js'), 'api/mutations.js')
  resolveStoreModules(require('../store/authorizations/actions.js'), 'authorizations/actions.js')
  resolveStoreModules(require('../store/broadcast-profiles/actions.js'), 'broadcast-profiles/actions.js')
  resolveStoreModules(require('../store/broadcast-profiles/mutations.js'), 'broadcast-profiles/mutations.js')
  resolveStoreModules(require('../store/broadcast/actions.js'), 'broadcast/actions.js')
  resolveStoreModules(require('../store/broadcast/getters.js'), 'broadcast/getters.js')
  resolveStoreModules(require('../store/broadcast/mutations.js'), 'broadcast/mutations.js')
  resolveStoreModules(require('../store/channels/actions.js'), 'channels/actions.js')
  resolveStoreModules(require('../store/channels/getters.js'), 'channels/getters.js')
  resolveStoreModules(require('../store/channels/mutations.js'), 'channels/mutations.js')
  resolveStoreModules(require('../store/cmp/actions.js'), 'cmp/actions.js')
  resolveStoreModules(require('../store/cmp/mutations.js'), 'cmp/mutations.js')
  resolveStoreModules(require('../store/compatibility-test/actions.js'), 'compatibility-test/actions.js')
  resolveStoreModules(require('../store/compatibility-test/mutations.js'), 'compatibility-test/mutations.js')
  resolveStoreModules(require('../store/contacts/actions.js'), 'contacts/actions.js')
  resolveStoreModules(require('../store/documents/actions.js'), 'documents/actions.js')
  resolveStoreModules(require('../store/epg/actions.js'), 'epg/actions.js')
  resolveStoreModules(require('../store/epg/getters.js'), 'epg/getters.js')
  resolveStoreModules(require('../store/epg/mutations.js'), 'epg/mutations.js')
  resolveStoreModules(require('../store/events/actions.js'), 'events/actions.js')
  resolveStoreModules(require('../store/favourites/actions.js'), 'favourites/actions.js')
  resolveStoreModules(require('../store/favourites/getters.js'), 'favourites/getters.js')
  resolveStoreModules(require('../store/favourites/mutations.js'), 'favourites/mutations.js')
  resolveStoreModules(require('../store/httpsession/actions.js'), 'httpsession/actions.js')
  resolveStoreModules(require('../store/items/actions.js'), 'items/actions.js')
  resolveStoreModules(require('../store/layout/mutations.js'), 'layout/mutations.js')
  resolveStoreModules(require('../store/list/actions.js'), 'list/actions.js')
  resolveStoreModules(require('../store/list/mutations.js'), 'list/mutations.js')
  resolveStoreModules(require('../store/menu/actions.js'), 'menu/actions.js')
  resolveStoreModules(require('../store/menu/mutations.js'), 'menu/mutations.js')
  resolveStoreModules(require('../store/notifications/actions.js'), 'notifications/actions.js')
  resolveStoreModules(require('../store/packets/actions.js'), 'packets/actions.js')
  resolveStoreModules(require('../store/payments/actions.js'), 'payments/actions.js')
  resolveStoreModules(require('../store/payments/mutations.js'), 'payments/mutations.js')
  resolveStoreModules(require('../store/player/actions.js'), 'player/actions.js')
  resolveStoreModules(require('../store/player/mutations.js'), 'player/mutations.js')
  resolveStoreModules(require('../store/playlist/actions.js'), 'playlist/actions.js')
  resolveStoreModules(require('../store/playlist/getters.js'), 'playlist/getters.js')
  resolveStoreModules(require('../store/playlist/mutations.js'), 'playlist/mutations.js')
  resolveStoreModules(require('../store/products/actions.js'), 'products/actions.js')
  resolveStoreModules(require('../store/profiles/actions.js'), 'profiles/actions.js')
  resolveStoreModules(require('../store/profiles/mutations.js'), 'profiles/mutations.js')
  resolveStoreModules(require('../store/recommendations/actions.js'), 'recommendations/actions.js')
  resolveStoreModules(require('../store/recommendations/mutations.js'), 'recommendations/mutations.js')
  resolveStoreModules(require('../store/recordings/actions.js'), 'recordings/actions.js')
  resolveStoreModules(require('../store/recordings/getters.js'), 'recordings/getters.js')
  resolveStoreModules(require('../store/recordings/mutations.js'), 'recordings/mutations.js')
  resolveStoreModules(require('../store/reminders/actions.js'), 'reminders/actions.js')
  resolveStoreModules(require('../store/reminders/getters.js'), 'reminders/getters.js')
  resolveStoreModules(require('../store/reminders/mutations.js'), 'reminders/mutations.js')
  resolveStoreModules(require('../store/scores/actions.js'), 'scores/actions.js')
  resolveStoreModules(require('../store/scores/getters.js'), 'scores/getters.js')
  resolveStoreModules(require('../store/scores/mutations.js'), 'scores/mutations.js')
  resolveStoreModules(require('../store/search/actions.js'), 'search/actions.js')
  resolveStoreModules(require('../store/secondaryEventAnswers/actions.js'), 'secondaryEventAnswers/actions.js')
  resolveStoreModules(require('../store/sections/actions.js'), 'sections/actions.js')
  resolveStoreModules(require('../store/sections/getters.js'), 'sections/getters.js')
  resolveStoreModules(require('../store/sections/mutations.js'), 'sections/mutations.js')
  resolveStoreModules(require('../store/tracking/actions.js'), 'tracking/actions.js')
  resolveStoreModules(require('../store/tracking/getters.js'), 'tracking/getters.js')
  resolveStoreModules(require('../store/tracking/mutations.js'), 'tracking/mutations.js')
  resolveStoreModules(require('../store/tracking/types.js'), 'tracking/types.js')
  resolveStoreModules(require('../store/tv/mutations.js'), 'tv/mutations.js')
  resolveStoreModules(require('../store/tvod/actions.js'), 'tvod/actions.js')
  resolveStoreModules(require('../store/user/actions.js'), 'user/actions.js')
  resolveStoreModules(require('../store/user/getters.js'), 'user/getters.js')
  resolveStoreModules(require('../store/user/mutations.js'), 'user/mutations.js')
  resolveStoreModules(require('../store/watched/actions.js'), 'watched/actions.js')
  resolveStoreModules(require('../store/watched/getters.js'), 'watched/getters.js')
  resolveStoreModules(require('../store/watched/mutations.js'), 'watched/mutations.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
