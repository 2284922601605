import { checkInputValidation, checkInputCharacters } from '@/assets/javascript/helpers/validationService'

export default {
  props: {
    maxLength: {
      type: Number,
      default: 40
    },
    restrictionRules: {
      type: Array,
      default: () => ([])
    },
    validationRules: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      isValid: false,
      hasError: false
    }
  },
  methods: {
    checkInput (target) {
      const trimStartValue = target.value.trimStart()
      const originalValue = target.value
      const rules = this.validationRules
      const restrictionData = {
        value: trimStartValue,
        maxLength: this.maxLength,
        restrictionRules: this.restrictionRules
      }
      const parsedValue = checkInputCharacters(restrictionData)

      if (rules.length) {
        const validationData = {
          value: parsedValue,
          validationRules: rules
        }
        this.isValid = checkInputValidation(validationData)
        this.hasError = !this.isValid
      }

      if (parsedValue !== originalValue) {
        target.value = parsedValue
      }
      this.$emit('input', parsedValue)
    }
  }
}
