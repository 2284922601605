import errorMapper from 'assets/javascript/helpers/error-mapper'

export default {
  data () {
    return {
      agreements: [],
      checkedAgreements: [],
      loaded: false
    }
  },
  methods: {
    getAgreements (opts = {}) {
      if (opts.showLoader) {
        this.$loader.show(this.$el.parentElement)
      }

      return this.$store.dispatch('user/GET_AGREEMENTS')
        .then((agreements) => {
          this.agreements = agreements
          this.checkedAgreements = this.agreements
            .filter((record) => record.value)
            .map((record) => record.id)
        })
        .finally(() => {
          if (opts.showLoader) {
            this.$loader.hide(this.$el.parentElement)
          }
          this.loaded = true
        })
    },
    saveAgreements (callback) {
      this.$loader.show(this.$refs.submit, { small: true })
      return this.$store.dispatch('user/POST_AGREEMENTS', this.setAgreementsToUpdate())
        .then(() => {
          this.$toast.success(this.$t('views.myAccount.agreements.success'))
          this.$store.commit('user/SET_AGREEMENTS_ACCEPTED')
          if (callback && typeof callback === 'function') {
            callback()
          }
        })
        .catch((error) => {
          return errorMapper(this.$t.bind(this), error)
            .then((errors) => {
              return this.$toast.error(errors.global)
            })
        })
        .finally(() => {
          this.$loader.hide(this.$refs.submit)
        })
    },
    setAgreementsToUpdate () {
      return this.agreements.map((agreement) => {
        const found = this.checkedAgreements.find((checkedAgreement) => checkedAgreement === agreement.id)
        if (found !== undefined) {
          return {
            id: agreement.id,
            value: true,
            agreement: agreement.agreement
          }
        }

        return {
            id: agreement.id,
            value: false,
            agreement: agreement.agreement
          }
      })
    }
  }
}
