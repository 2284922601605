import { DISABLE_SUBTITLES } from 'atds-player-layout/src/store/settings'

const DE = 'niemiecki'
const EN = 'angielski'
const FR = 'francuski'
const IT = 'włoski'
const PL = 'polski'
const SP = 'hiszapński'
const SW = 'szwedzki'

export const TRANSLATION = {
  deu: DE,
  eng: EN,
  fra: FR,
  ita: IT,
  pol: PL,
  spa: SP,
  swe: SW,
  und: 'oryginalny',
  de: DE,
  en: EN,
  fr: FR,
  it: IT,
  pl: PL,
  sp: SP,
  turn_off: 'wyłącz napisy'
}

export const TURN_OFF = {
  ...DISABLE_SUBTITLES,
  name: TRANSLATION.turn_off
}
