import VMAPparser from 'vmap'
import { DEFAULT_PLATFORM } from '@/api/enums'

export function Adverts ($http) {
  return {
    VMAP: {
      DAI: {
        get ({ programmeId, profileId, abtShieldId }) {
          if (!programmeId) {
            return Promise.reject(new Error('programmeId is required'))
          }

          return $http
            .get(`/items/${programmeId}/adbreaks/dai/vmap`, {
              params: {
                profileId,
                abtShieldId
              },
              responseType: 'document' // responseType document returns xml document
            })
            .then(vmap => new VMAPparser(vmap)) // PARSE VMAP XML to js Object https://github.com/dailymotion/vmap-js)
        }
      },
      url: {
        get ({ programId, profileId, abtShieldId, tcString }) {
          const location = process.env.USE_PROXY
            ? window.location.origin
            : process.env.PROXY_URI

          let url = `${location}/api/items/${programId}/adbreaks/normal/vmap?platform=${DEFAULT_PLATFORM}`.replace('//api', '/api')

          if (profileId) {
            url += `&profileId=${profileId}`
          }

          if (abtShieldId) {
            url += `&abtShieldId=${abtShieldId}`
          }

          if (tcString) {
            url += `&tcString=${tcString}`
          }

          return url
        }
      }
    },
    vast: {
      proxy: {
        post (vast, { adSessionId, adBreakId }) {
          return $http.post(`/adverts/vast/proxy/ad/breaks/${adBreakId}`, vast, {
            headers: {
              'Content-Type': 'application/xml'
            },
            params: {
              adSessionId
            },
            progress: false
          })
        }
      }
    }
  }
}
