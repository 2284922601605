const DRM_NAMES = {
  FAIRPLAY: 'FAIRPLAY'
}

function PlaylistTransform (playlist) {
  return {
    ...playlist,
    ...{
      drm: transformDrm(playlist.drm),
      sources: transformSources(playlist.sources)
    }
  }
}

function transformSources (sources) {
  return Object
    .entries(sources)
    .reduce((obj, item) => {
      obj[item[0].toLowerCase()] = item[1][0].src
      return obj
    }, {})
}

function transformDrm (drms) {
  return Object.entries(drms)
    .map(changeDrmSourceOrigin)
    .reduce((obj, [name, drm]) => {
      if (name === DRM_NAMES.FAIRPLAY) {
        obj[name.toLowerCase()] = drm
      } else {
        obj[name.toLowerCase()] = drm.src
      }
      return obj
    }, {})
}

function changeDrmSourceOrigin ([name, drm]) {
  const location = process.env.USE_PROXY
    ? window.location.origin
    : process.env.PROXY_URI

  const src = `${location}/api/${drm.src.split('/api/')[1]}`.replace('//api', '/api')
  return [name, { ...drm, src }]
}

export default PlaylistTransform
