export default {
  PROLONG_HTTP_SESSION ({ rootState, commit }) {
    return this.$api
      .resources
      .subscribers
      .session
      .put()
      .then((session) => {
        const user = rootState.user.data
        commit('user/SET_USER', {
          ...user,
          httpSession: session
        }, { root: true })
        return session
      })
  }
}
