// import { ASPECT_16X9 } from '@/assets/javascript/enums/image-scale-name'
import { BANNER, TVOD } from '@/assets/javascript/enums/product-types'
export default function (product) {
  const type = product.type
  if (type === BANNER) {
    // return product.banners?.[ASPECT_16X9]?.[0]
  }
  if (type === TVOD) {
    // return product.billboards?.[ASPECT_16X9]?.[0]
  }
  // return product.covers?.[ASPECT_16X9]?.[0]
}
