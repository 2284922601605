import PlayCardFactory from '@/assets/javascript/play/factory/card'

export default function (product, user) {
  const schedules = product.schedules?.items || []
  const currentSchedule = findSchedule(schedules)
  const purchaseType = currentSchedule?.purchaseType

  return {
    ...PlayCardFactory(product),
    paid: !!user.products.availableProductIds?.find(id => id === product.id),
    currentSchedule,
    ...(currentSchedule && /^PAYABLE/.test(purchaseType) && { isPayable: true }),
    ...(currentSchedule && /^WALLET/.test(purchaseType) && { isWallet: true }),
    ...(currentSchedule && /^FREE/.test(purchaseType) && { isGift: true })
  }
}

export function findSchedule (schedules) {
  if (schedules.length === 0) {
    return
  }
  const giftSchedule = schedules
    .find(({ purchaseType }) => /^FREE/.test(purchaseType))
  if (giftSchedule) {
    return giftSchedule
  }
  const currentSchedule = schedules
    .reduce((cheapest, current) => {
      if (Math.min(cheapest.price, current.price) === cheapest.price) {
        return cheapest
      }
      return current
    })
  currentSchedule.price = `${(currentSchedule.price / 100).toFixed(2)} zł`
  return currentSchedule
}
