// "label" - is a label of a view which has collection of sections
export const DEFAULT_SECTIONS_MAX_RESULTS = 3
export const DEFAULT_SECTION_MAX_ELEMENTS = 100

export default {
  GET (context, { label, kids = false, firstResult = 0, maxResults = 0, elementsLimit = DEFAULT_SECTION_MAX_ELEMENTS }) {
    const { commit } = context
    return this.$api.resources
      .products
      .sections
      .get({ label, kids, firstResult, maxResults, elementsLimit })
      .then((sections) => {
        commit('SET_SECTIONS', {
          sections,
          label,
          isComplete: maxResults > sections.length
        })
      })
  },
  LOAD_MORE ({ dispatch, getters }, { label, maxResults = DEFAULT_SECTIONS_MAX_RESULTS }) {
    const view = getters.getByLabel(label)
    return dispatch('GET', {
      label,
      firstResult: view?.sections?.length || 0,
      maxResults
    })
  }
}
