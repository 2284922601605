import { LIVE } from '@/assets/javascript/enums/product-types'
import {
  STATUS_ENDED,
  STATUS_PAUSE,
  STATUS_PLAYING
} from 'atds-player-layout/src/enums'
import {
  BUFFERING,
  CLOSED,
  COMPLETED,
  ERROR,
  KEEP_ALIVE,
  PAUSED,
  PLAYING,
  SEEKING,
  STOPPED,
  TRACK_CHANGED
} from '@/assets/javascript/dictionaries/gtmEventsActions'
import {
  VIDEO,
  PLAYER_INSTANCE_DATA_LOG
} from '@/assets/javascript/dictionaries/gtmEvents'
import { PlayerEvent } from 'redgalaxy-player-web'

const prepareVideoTrackingData = ({
                                    holderId,
                                    $tracking,
                                    playerState,
                                    isResumed,
                                    bufferingDurationInMs
                                  }) => {
  const {
    product: { id, type } = {},
    progress,
    settings: {
      profile: {
        bandwidth
      } = {}
    } = {},
    options: {
      profiles = []
    } = {}
  } = playerState

  const bandwidths = profiles
    .map(({ bandwidth }) => Number(bandwidth))
    .filter(Boolean)
    .sort((a, b) => a - b)

  const { data: { pageViewId } = {} } = $tracking.data.getEventData(
    `${PLAYER_INSTANCE_DATA_LOG}/${holderId}`
  ) || {}
  return {
    pageViewId,
    itemId: parseInt(id, 10),
    liveItemId: type === LIVE ? parseInt(id, 10) : undefined,
    progress,
    sessionDurationInMs: $tracking.data.getSessionDurationInMs(),
    bandwidth: bandwidth?.toString(),
    isResumed,
    type,
    bufferingDurationInMs,
    minAvailableBandwidth: bandwidths[0],
    maxAvailableBandwidth: bandwidths[bandwidths.length - 1]
  }
}

export const createTrackingWatches = ({ holderId, isResumed }) => ({
  created () {
    const { data: { pageViewId } = {} } = this.$tracking.data.getCurrentPageDataLog() || {}
    this.$tracking.data.setEventData({
      event: `${PLAYER_INSTANCE_DATA_LOG}/${holderId}`,
      data: { pageViewId }
    })
  },
  data () {
    return {
      playerState: this.$tracking.getPlayerState(holderId),
      playerRoute: this.$tracking.getPlayerRoute(holderId),
      completeSent: false,
      isInitialized: false,
      wasPaused: false,
      isResumed,
      startBufferingTimeStamp: null,
      bufferingDurationInMs: 0
    }
  },
  computed: {
    videoTrackingData () {
      return prepareVideoTrackingData({
        holderId,
        $tracking: this.$tracking,
        playerState: this.playerState,
        isResumed: this.isResumed,
        bufferingDurationInMs: this.bufferingDurationInMs
      })
    }
  },
  watch: {
    'playerState.isBuffering':
      function (isBuffering) {
        if (isBuffering) {
          this.startBufferingTimeStamp = new Date().getTime()
          this.$tracking.events.send(VIDEO, {
            action: BUFFERING,
            videoData: this.videoTrackingData
          })
        } else {
          this.bufferingDurationInMs = Math.max(
            0, (new Date().getTime() - Number(this.startBufferingTimeStamp))
          )
        }
      },

    'playerState.playerStatus':
      function (status, prevStatus) {
        if (status === prevStatus) {
          return
        }

        if (status === STATUS_PAUSE) {
          this.$tracking.events.send(VIDEO, {
            action: PAUSED,
            videoData: this.videoTrackingData
          })
          this.wasPaused = true
          this.isPaused = true
        }

        if (status === STATUS_PLAYING) {
          this.isInitialized = true
          this.isPaused = false
          this.$tracking.events.send(VIDEO, {
            action: PLAYING,
            videoData: this.videoTrackingData
          })
          this.startBufferingTimeStamp = null
          this.bufferingDurationInMs = 0
        }

        if (status === STATUS_ENDED) {
          this.completeSent = false
          this.isInitialized = false
        }
      },

    'playerState.progress':
      function (progress) {
        const CREDITS = 'CREDITS'
        const { eventsOnTimeline = [], duration } = this.playerState
        const progressPercent = progress / duration * 100
        let completedPoint = 90

        if (eventsOnTimeline.length > 0) {
          const credits = eventsOnTimeline
            .find(({ action }) => action === CREDITS)
          if (credits) {
            const creditsSinceInSec = credits.since / 1000
            completedPoint = creditsSinceInSec / duration * 100
          }
        }

        const newCompleteSent = progressPercent > completedPoint

        if (!this.completeSent && newCompleteSent) {
          this.$tracking.events.send(VIDEO, {
            action: COMPLETED,
            videoData: this.videoTrackingData
          })
        }
        this.completeSent = newCompleteSent
      },

    'playerState.settings.profile': function (newValue, oldValue) {
      if (oldValue === newValue) {
        return
      }
      this.$tracking.events.send(VIDEO, {
        action: TRACK_CHANGED,
        videoData: this.videoTrackingData
      })
    }
  }
})

export const createTrackingListeners = ({ holderId, isResumed }) => {
  return class TrackingListeners {
    keepAliveInterval = null

    constructor (player, $tracking) {
      const KEEP_ALIVE_DEFAULT_INTERVAL = 150 * 1000 // 150s
      const playerState = $tracking.getPlayerState(holderId)
      const $route = $tracking.getPlayerRoute(holderId)
      const { settings: { profile, audio, subtitle } = {} } = playerState
      const product = { videoProduct: playerState.product, profile, audio, subtitle }
      const events = $tracking.events

      this.$tracking = $tracking
      this.$route = $route
      this.product = product
      this.isResumed = isResumed
      this.isPaused = false
      this.playerState = playerState
      this.player = player

      player.on(PlayerEvent.STOPPED, () => {
        events.send(VIDEO, {
          action: STOPPED,
          videoData: this.videoTrackingData()
        })
      })

      player.on(PlayerEvent.PAUSED, () => {
        this.isPaused = true
      })

      player.on(PlayerEvent.PLAYING, () => {
        this.isPaused = false
      })

      player.on(PlayerEvent.SEEK_START, (seek) => {
        events.send(VIDEO, {
          action: SEEKING,
          videoData: {
            ...this.videoTrackingData(),
            videoOffset: parseInt(seek).toString()
          }
        })
      })

      player.on(PlayerEvent.SEEK_END, () => {
       if (this.playerState.playerStatus === 'playing') {
         events.send(VIDEO, {
           action: PLAYING,
           videoData: this.videoTrackingData()
         })
       }
      })

      player.on(PlayerEvent.INITIALIZED, () => {
        this.keepAliveInterval = setInterval(() => {
          if (!this.isPaused) {
            events.send(VIDEO, {
              action: KEEP_ALIVE,
              videoData: this.videoTrackingData()
            })
          }
        }, KEEP_ALIVE_DEFAULT_INTERVAL)
      })

      player.on(PlayerEvent.ERROR, () => {
        events.send(VIDEO, {
          action: ERROR,
          videoData: this.videoTrackingData()
        })
      })

      player.on(PlayerEvent.DESTROY, () => {
        events.send(VIDEO, {
          action: CLOSED,
          videoData: this.videoTrackingData()
        })

        clearInterval(this.keepAliveInterval)
      })
    }

    videoTrackingData () {
      return prepareVideoTrackingData({
        holderId,
        $tracking: this.$tracking,
        playerState: this.playerState,
        isResumed: this.isResumed
      })
    }

    destroy () {
      clearInterval(this.keepAliveInterval)
    }
  }
}
