import { PROGRAM, VOD } from '@/assets/javascript/enums/product-types'
export function SearchResults ($http) {
  const BASE_URL = '/products'
  return {
    get ({ query, type, kids, firstResult, maxResults, includeEpisodes }) {
      const params = {
        episodes: !!includeEpisodes,
        firstResult: firstResult || 0,
        maxResults: maxResults || 0,
        keyword: query
      }

      if (kids) {
        params.kids = true
      }

      const obj = {
        [PROGRAM]: () => $http.get(`${BASE_URL}/lives/programmes/search/`, { params }),
        [VOD]: () => $http.get(`${BASE_URL}/vods/search/`, { params })
      }
      return obj[type]()
    },
    getAll ({ query, kids, firstResult, maxResults }) {
      const params = {
        query, firstResult, maxResults
      }

      if (kids) {
        params.kids = true
      }

      return Promise.all([
        this.get({ ...params, type: VOD })
      ])
    }
  }
}
