export default {
  GET_UPCOMING_AD_BREAK: (state) => (currentTime, programmeId) => {
    const timeline = state.timeline.find(item => item.programmeId === programmeId)

    if (timeline) {
      return timeline.adBreaks.find(({ timeOffset }) => {
        if (state.upcomingAdBreak.timeOffset !== timeOffset) {
          const diff = timeOffset - currentTime
          return diff > 45 && diff <= 60
        }
      })
    }
  }
}
