import { resources } from '@/api/resources'
import { interceptors } from '@/api/interceptors'

export class Api {
  constructor ($http, store, env) {
    interceptors.forEach((interceptor) => interceptor($http.interceptors, store))
    this.resources = new Proxy(resources, {
      get (target, name) {
        return target[name]($http, env)
      }
    })
  }
}
