export default {
  SHOW_LIST () {
    return this.$api.resources
      .profiles
      .get()
  },
  GET_AVATARS ({ commit }) {
    return this.$api.resources
      .profiles
      .getAvatars()
      .then((avatars) => commit('SET_AVATARS', avatars))
  },
  GET_PROFILE ({ commit }, id) {
    return this.$api.resources
      .profiles
      .get(id)
      .then((profile) => commit('user/UPDATE_PROFILE', profile, { root: true }))
  },
  UPDATE_PROFILE ({ commit }, { profile }) {
    return this.$api.resources
      .profiles
      .update(profile)
      .then((profile) => commit('user/UPDATE_PROFILE', profile, { root: true }))
  },
  ADD_PROFILE ({ commit }, { profile }) {
    return this.$api.resources
      .profiles
      .post(profile)
      .then(profile => commit('user/ADD_PROFILE', profile, { root: true }))
  },
  DELETE_PROFILE ({ commit }, { profileId }) {
    return this.$api.resources
      .profiles
      .delete(profileId)
      .then(() => commit('user/DELETE_PROFILE', profileId, { root: true }))
  }
}
