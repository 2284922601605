export function loadExternalFile (url) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`[src='${url}']`)) {
      return resolve()
    }

    const scriptTag = document.createElement('script')
    scriptTag.src = url
    scriptTag.onload = () => resolve()
    scriptTag.onreadystatechange = () => resolve()
    scriptTag.onerror = () => reject(new Error('Script load failed'))
    document.body.appendChild(scriptTag)
  })
}
