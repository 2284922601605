import { API_DEVICE_HEADER } from '@/api/enums'

export default {
  GET_DAI_VMAP ({ commit, rootState, rootGetters }, { programmeId }) {
    const abtShieldId = rootState.tracking.iuid
    const profileId = rootGetters['user/GET_ACTIVE_PROFILE']?.id

    if (!programmeId) {
      return Promise.reject(new Error('programmeId is required'))
    }

    return this.$api.resources
      .adverts
      .VMAP
      .DAI
      .get({ programmeId, profileId, abtShieldId })
      .then(vmap => {
        const adBreaks = vmap.adBreaks
        commit('SET_AD_BREAKS', { adBreaks, programmeId })
        commit('SET_AD_BREAKS_TIMELINE', { adBreaks, programmeId })
      }).catch((e) => console.warn('Failed to fetch VMAP', e))
  },

  GET_NORMAL_VMAP_URL ({ rootGetters, rootState }, programId) {
    const abtShieldId = rootState.tracking.iuid
    const profileId = rootGetters['user/GET_ACTIVE_PROFILE']?.id
    const tcString = rootState.cmp.TCString

    return this.$api.resources
      .adverts
      .VMAP
      .url
      .get({ programId, profileId, abtShieldId, tcString })
  },

  GET_VASTS ({ state }, { adBreakId, programmeId }) {
    // console.warn('programmeId', programmeId)
    const uris = state.adBreaks.find(item => item.programmeId === programmeId)
      ?.adBreaks
      ?.filter(({ breakId }) => breakId === adBreakId)
      ?.map(({ adSource }) => adSource.adTagURI.uri)
    // console.warn(uris)

    return Promise.all(uris.map(uri => this.$axios.get(uri, {
      transformRequest: (data, headers) => {
        delete headers[API_DEVICE_HEADER]
      }
    })))
  },

  SEND_AD_BREAK_TO_PROXY ({ dispatch }, { adSessionId, adBreakId, programmeId }) {
    const t0 = performance.now()
    return dispatch('GET_VASTS', { adBreakId, programmeId })
      .then(vasts => Promise.all(vasts.map(vast => {
        return this.$api.resources
          .adverts
          .vast
          .proxy
          .post(vast, { adSessionId, adBreakId })
      }))
        .then(() => {
            const t1 = performance.now()
            console.warn('Post to VAST proxy took: ' + (t1 - t0) + ' ms')
        }))
      .catch((e) => console.warn('Failed to fetch VASTS', e))
  },

  SEND_CURRENT_AD_BREAK_TO_PROXY ({ getters, state, commit, dispatch, rootState }, { currentTime, nextProgrammeId = null }) {
    const programmeId = nextProgrammeId || rootState.epg.currentProgramme?.id

    const adBreak = nextProgrammeId
      ? state.timeline
        ?.find(item => item.programmeId === programmeId)
        ?.adBreaks
        ?.find(({ timeOffset }) => {
          return timeOffset >= 0 && timeOffset < 60
        })
      : getters.GET_UPCOMING_AD_BREAK(currentTime, programmeId)

    if (adBreak && (adBreak.breakId !== state.upcomingAdBreak.breakId)) {
      console.warn('Sending break offset: ' + adBreak?.timeOffsetAbsolute?.substr(0, 8) + ', id: ' + adBreak.breakId)
      console.warn('Current offset: ' + new Date(this.$time().diff(rootState.epg.currentProgramme?.since)).toISOString().substr(11, 8))
      commit('SET_UPCOMING_AD_BREAK', adBreak)
      dispatch('SEND_AD_BREAK_TO_PROXY', {
        programmeId,
        adSessionId: state.adSessionId,
        adBreakId: adBreak.breakId
      })
    }
  }
}
