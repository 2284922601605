export function Payments ($http) {
  const BASE_URL = '/payments'

  return {
    buyBundle ({ productId, scheduleId, cardId, agreementIds }) {
      if (!agreementIds) {
        agreementIds = []
      }

      return $http.post(`${BASE_URL}/products/${productId}/schedules/${scheduleId}/channels/CARD/systems/P24`, { cardId, agreementIds })
    },
    buySingleProduct ({ productId, scheduleId, agreementIds }) {
      if (!agreementIds) {
        agreementIds = []
      }

      return $http.post(`${BASE_URL}/products/${productId}/schedules/${scheduleId}/channels/PREPAID_ONLINE/systems/P24`, { agreementIds, promotionApply: false })
    },
    card: {
      delete (cardId) {
        return $http.delete(`${BASE_URL}/cards/${cardId}`)
      },
      get () {
        return $http.get(`${BASE_URL}/cards`)
      },
      getStatus (cardId) {
        return $http.get(`${BASE_URL}/cards/${cardId}`)
      },
      post () {
        return $http.post(`${BASE_URL}/cards/P24`, {})
      }
    },
    single: {
      get (paymentId) {
        return $http.get(`${BASE_URL}/${paymentId}`, { progress: false })
      }
    }
  }
}
