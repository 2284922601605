import { EPISODE, PROGRAMME, LIVE, SERIAL, VOD } from '@/assets/javascript/enums/product-types'

export function Products ($http) {
  const BASE_URL = '/products'

  return {
    get ({ productId, type }) {
      const obj = {
        [EPISODE]: () => $http.get(`${BASE_URL}/vods/${productId}`),
        [VOD]: () => $http.get(`${BASE_URL}/vods/${productId}`),
        [SERIAL]: () => $http.get(`${BASE_URL}/vods/serials/${productId}`),
        [LIVE]: () => $http.get(`${BASE_URL}/lives/${productId}`),
        [PROGRAMME]: () => $http.get(`${BASE_URL}/lives/programmes/${productId}`)
      }
      return obj[type]()
    },
    serials: {
      getSeasons (serialId) {
        return $http.get(`${BASE_URL}/vods/serials/${serialId}/seasons/`)
      },
      getEpisodes ({ serialId, seasonId }) {
        return $http.get(`${BASE_URL}/vods/serials/${serialId}/seasons/${seasonId}/episodes`)
          .then(episodes => [...episodes].sort((a, b) => a.episode - b.episode))
      }
    },
    lives: {
      get () {
        return $http.get(`${BASE_URL}/lives`)
      },
      recordings: {
        delete (id) {
          return $http.delete(`${BASE_URL}/lives/recordings/${id}`)
        },
        get () {
          return $http.get(`${BASE_URL}/lives/recordings/directories`)
        },
        record (id) {
          return $http.post(`${BASE_URL}/lives/recordings/programmes/${id}/directories`)
        }
      }
    },
    programmes: {
      get (productIds) {
        return $http.get(`${BASE_URL}/lives/programmes/list`, {
          params: { productIds }
        })
      },
      profiles: {
        get (programmeId) {
          return $http.get(`${BASE_URL}/lives/programmes/${programmeId}/broadcast/profiles`)
        }
      }
    },
    sections: {
      get ({ label, kids, firstResult, maxResults, elementsLimit }) {
        const params = {
          firstResult,
          maxResults,
          elementsLimit
        }

        if (kids) {
          params.kids = true
        }

        return $http.get(`${BASE_URL}/sections/${label}`, { params })
      }
    },
    bundles: {
      getAll () {
        return $http.get(`${BASE_URL}/bundles`)
      }
    },
    vods: {
      get ({
        productId,
        categoryId,
        mainCategoryId,
        itemType,
        sort,
        order,
        vodFilter,
        ratingFilter,
        kids,
        firstResult,
        maxResults,
        cancelToken
      }) {
        const KIDS = 'KIDS'
        const filters = vodFilter ? [...vodFilter] : []

        if (kids) {
          filters.push(KIDS)
        }

        const params = {
          productId,
          sort,
          order,
          vodFilter: filters.length ? filters : undefined,
          ratingFilter,
          firstResult,
          maxResults
        }

        if (categoryId) {
            categoryId = Array.isArray(categoryId) ? categoryId : [categoryId]

            if (categoryId.length) {
                params.categoryId = categoryId
            }
        }

        if (mainCategoryId) {
          mainCategoryId = Array.isArray(mainCategoryId) ? mainCategoryId : [mainCategoryId]

          if (mainCategoryId.length) {
            params.mainCategoryId = mainCategoryId
          }
        }

        if (itemType && itemType.length) {
          params.itemType = itemType
        }

        return $http.get(`${BASE_URL}/vods`, {
          params,
          cancelToken
        })
      }
    }
  }
}
