import Vue from 'vue'

export default ({ app, store }) => {
  Vue.mixin({
    beforeRouteEnter (to, from, next) {
      const { $data, $window, $tracking } = app
      const { events } = $tracking || {}
      if (!events) {
        return
      }

      const { priorities } = events
      const { MY_ACCOUNT, MY_ACCOUNT_PROFILE, MY_LIST } = app.$dict.routes

      // → Don't want to execute this on routes including router-view - it's called twice due to this component
      const isSecondExecutionOnRoutesIncludesPageView = [MY_ACCOUNT, MY_ACCOUNT_PROFILE, MY_LIST].includes(to.name) &&
        events.getEventsOnPageRefreshPriority() === priorities.MEDIUM

      if (isSecondExecutionOnRoutesIncludesPageView) {
        return next()
      }

      events.setEventsOnPageRefreshPriority(priorities.MEDIUM)

      next(() => {
        events.eventsOnPageRefresh({
          $route: to,
          product: $data,
          priority: priorities.MEDIUM,
          window: $window
        })
      })
    }
  })
}
