export default {
  GET_ALL ({ commit }) {
    return this.$axios
      .get('/subscribers/reminders', {
        params: {
          page: 1,
          firstResult: 0,
          maxResults: 20
        }
      })
      .then(data => {
        commit('SET_REMINDERS', data)
        return data
      })
  },
  REMIND ({ commit }, { id, title }) {
    return this.$axios
      .post('/subscribers/reminders', { liveEpgProgrammeId: id })
      .then(
        data => {
          commit('ADD', data)
          this.$toast.info(`Dodano przypomnienie: ${title}`)
          return data
        },
        () => this.$toast.error('Wystąpił błąd przy próbie ustawienia przypomnienia')
      )
  },
  DELETE ({ commit, state }, { id, title }) {
    return this.$axios
      .delete(`/subscribers/reminders/${id}`)
      .then(
        () => {
          commit('REMOVE', id)
          this.$toast.info(`Usunięto przypomnienie: ${title}`)
        },
        () => this.$toast.error('Wystąpił błąd przy próbie usuwania przypomnienia')
      )
  }
}
