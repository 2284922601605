import Vue from 'vue'
import VueToastr from '@deveodk/vue-toastr'

Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right'
})

export default ({ store }) => {
  Vue.prototype.$toast = store.$toast = {
    error (m) {
      return Vue.prototype.$toastr('error', m)
    },
    success (m) {
      return Vue.prototype.$toastr('success', m)
    },
    info (m) {
      return Vue.prototype.$toastr('info', m)
    },
    warning (m) {
      return Vue.prototype.$toastr('warning', m)
    },
    permissionDenied (msg) {
      return Vue.prototype.$toastr('add', {
        msg,
        timeout: 0,
        position: 'toast-bottom-right',
        type: 'warning'
      })
    }
  }
}
