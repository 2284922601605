export function Profiles ($http) {
  const BASE_URL = '/subscribers/profiles'
  return {
    get (id) {
      return $http.get(`${BASE_URL}/${id}`)
    },
    getAvatars () {
      return $http.get(`${BASE_URL}/avatars`)
    },
    update (profile) {
      return $http.put(`${BASE_URL}/${profile.id}`, profile)
    },
    post (profile) {
      return $http.post(`${BASE_URL}`, profile)
    },
    delete (profileId) {
      return $http.delete(`${BASE_URL}/${profileId}`)
    }
  }
}
