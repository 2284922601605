import { profilesCategories } from './dictionaries/playerProfiles'
import { TRANSLATION, TURN_OFF } from './dictionaries/playerLanguageSettings'

export function mapAudioTracks (audioTracks) {
  return audioTracks
    .map((audioTrack) => {
      return {
        ...audioTrack,
        name: TRANSLATION[audioTrack.lang]
      }
    })
    .filter((element) => element.name)
}

export function mapSubtitles (subtitles) {
  const newSubtitles = subtitles
    .map((subtitle) => {
      const mapped = {
        ...subtitle,
        name: TRANSLATION[subtitle.language || subtitle.label]
      }

      if (!mapped.language) {
        mapped.language = subtitle.language
      }

      return mapped
    }).filter((element) => element.name)
  if (newSubtitles.length !== 0) {
    newSubtitles.push(TURN_OFF)
  }
  return newSubtitles
}

export function mapProfiles (profiles) {
  const profilesGroupedByCategories = groupBy(profiles)
  return getBestProfilesInCategory(profilesGroupedByCategories)
}

function groupBy (profiles) {
  return profiles.reduce((profilesGroupedByCategories, profile) => {
    const profileCategory = getProfileCategory(profile.height)
    const profilesGroupedByCategory = profilesGroupedByCategories
      .find(({ category }) => category === profileCategory.category)
    if (!profilesGroupedByCategory) {
      profilesGroupedByCategories.push({
        ...profileCategory,
        profiles: [profile]
      })
    } else {
      profilesGroupedByCategory.profiles.push(profile)
    }
    return profilesGroupedByCategories
  }, [])
}

export function getProfileCategory (height) {
  return profilesCategories
    .sort((a, b) => b.minHeight - a.minHeight)
    .find(({ minHeight }) => height >= minHeight)
}

function getBestProfilesInCategory (profilesGroupedByCategories) {
  return profilesGroupedByCategories.map(category => {
    const highestProfilesInCategory = filterArrayByMaxPropertyValue(category.profiles, 'height')
    const bestByBandwidth = filterArrayByMaxPropertyValue(highestProfilesInCategory, 'bandwidth')
    const bestProfileInCategory = bestByBandwidth[0]
    bestProfileInCategory.name = category.name
    return bestProfileInCategory
  })
}

function filterArrayByMaxPropertyValue (array, property) {
  if (!array || array.length === 0) {
    return []
  }

  const maxValue = array.reduce((max, element) => {
    return element[property] > max ? element[property] : max
  }, array[0][property])

  return array.filter(({ [property]: value }) => value === maxValue)
}
