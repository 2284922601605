import { SYNC_CLIENT_TIME_WITH_BO_TIME } from '@/api/enums'

export function addSyncClientWithServerInterceptor (interceptors) {
  interceptors.response
    .use(
      ({ data, status, statusText, headers, config }) => {
        let dataWithDate
        if (config.headers[SYNC_CLIENT_TIME_WITH_BO_TIME]) {
          dataWithDate = { data, serverTime: headers.date }
        }
        return status >= 200 && status < 400
          ? dataWithDate || data
          : { data, status, statusText }
      })
}
