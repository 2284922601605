import { state as defaultState } from './index'

export default {
  CLEAR_AD_BREAKS_FOR_PROGRAMME (state, programmeId) {
    const index = state.adBreaks.findIndex(item => item.programmeId === programmeId)

    if (index !== -1) {
      state.adBreaks.splice(index, 1)
    }
  },

  CLEAR_AD_BREAKS_TIMELINE_FOR_PROGRAMME (state, programmeId) {
    const index = state.timeline.findIndex(item => item.programmeId === programmeId)

    if (index !== -1) {
      state.timeline.splice(index, 1)
    }
  },

  SET_AD_BREAKS (state, { adBreaks, programmeId }) {
    state.adBreaks.push({
      programmeId,
      adBreaks
    })
  },

  SET_UPCOMING_AD_BREAK (state, adBreak) {
    state.upcomingAdBreak = adBreak
  },

  SET_AD_BREAKS_TIMELINE (state, { adBreaks, programmeId }) {
    state.timeline.push({
      programmeId,
      adBreaks: adBreaks.filter(({ breakId }, index) => adBreaks.findIndex((adBreak) => breakId === adBreak.breakId) === index)
          .map((adBreak) => {
            return {
              ...adBreak,
              timeOffsetAbsolute: adBreak.timeOffset,
              timeOffset: adBreak.timeOffset.split(':').reduce((acc, time) => (60 * acc) + +time)
            }
          })
    })
  },

  CLEAR_ADVERTS (state) {
    const freshState = defaultState()
    Object
      .entries(freshState)
      .forEach(([property, value]) => {
        if (property !== 'adSessionId') {
          state[property] = value
        }
      })
  },

  SET_AD_SESSION_ID (state, adSessionId) {
    if (!state.adSessionId) {
      state.adSessionId = adSessionId
    }
  }
}
