import TvodCardFactory from '@/assets/javascript/play/factory/tvod-card'

export default {
  GET ({ commit, rootState }, { id }) {
    return this.$axios
      .get(`/products/tvods/${id}`, {
        withPriceStrategy: true,
        withWallet: true
      })
      .then(data => ({
        ...TvodCardFactory(data, rootState.user),
        // background: data?.billboards?.[ASPECT_7X3]?.[0]?.url,
        trailer: data.trailer
      }))
  }
}
