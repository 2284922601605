export default {
  BUY_BUNDLE (_, { productId, scheduleId, cardId, agreementIds }) {
    return this.$api
      .resources
      .payments
      .buyBundle({ productId, scheduleId, cardId, agreementIds })
  },
  BUY_SINGLE_PRODUCT (_, { productId, scheduleId, agreementIds }) {
    return this.$api
      .resources
      .payments
      .buySingleProduct({ productId, scheduleId, agreementIds })
  },
  GET_CARD_STATUS (_, cardId) {
    return this.$api
      .resources
      .payments
      .card
      .getStatus(cardId)
  },
  GET_CARDS ({ commit }) {
    return this.$api
      .resources
      .payments
      .card
      .get()
      .then(cards => {
        commit('SET_CARDS', cards)
        return cards
      })
  },
  POST_CARD () {
    return this.$api
      .resources
      .payments
      .card
      .post()
  },
  REMOVE_CARD ({ commit }, cardId) {
    return this.$api
      .resources
      .payments
      .card
      .delete(cardId)
      .then(() => {
        commit('SET_CARDS', {})
      })
  },
  GET_PAYMENT (_, paymentId) {
    return this.$api
      .resources
      .payments
      .single
      .get(paymentId)
  }
}
