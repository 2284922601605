export default function getValidSchedules (product, time, type) {
  const validSchedules = []
  const date = new Date()
  const now = time(date).format('YYYY-MM-DD HH:mm:00')

  let priceSchedules = product.paymentSchedules

  if (type) {
    priceSchedules = product.paymentSchedules?.filter((schedule) => schedule.type === type)
  }

  if (!priceSchedules?.length) {
    return []
  }

  priceSchedules.map(item => {
    const since = time(item.since).format('YYYY-MM-DD HH:mm:00')

    if (item.till && time().isBetween(item.since, item.till)) {
      return validSchedules.push(item)
    }

    if (now >= since) {
      return validSchedules.push(item)
    }
  })

  return validSchedules
}
