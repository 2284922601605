function checkInputValidation ({ value, validationRules }) {
  let isValid = true

  for (const rule of validationRules) {
    isValid = rule.test(value)
    if (!isValid) {
      break
    }
  }

  return isValid
}

function checkInputCharacters ({ value, maxLength, restrictionRules }) {
  if (maxLength && value.length > maxLength) {
    value = value.substring(0, maxLength)
  }

  for (const rule of restrictionRules) {
    value = value.replace(rule, '')
    if (!value) {
      break
    }
  }

  return value
}

export { checkInputValidation, checkInputCharacters }
