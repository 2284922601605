export default {
  GET_CMP_INFO () {
    const { cmpUrl, tenant } = this.state.api
    return this.$api.resources
      .cmp
      .getCmpInfo(cmpUrl, tenant)
  },
  GET_CONSENT_LIST (store, cmpUrl) {
    const { tenant } = this.state.api
    return this.$api.resources
      .cmp
      .getConsentList(tenant, cmpUrl)
  },
  GET_GVL () {
    return this.$api.resources
      .cmp
      .getGVL()
  },
  GET_TRANSLATIONS () {
    return this.$api.resources
      .cmp
      .getTranslations()
  },
  SET_DEVICE_UID ({ commit }, deviceUID) {
    commit('SET_DEVICE_UID', deviceUID)
  },
  UPDATE_TC_STRING ({ commit }, { payload, cmpUrl }) {
    const { tenant } = this.state.api
    return this.$api.resources
      .cmp
      .updateTCString(payload, cmpUrl, tenant)
      .then(TCString => commit('SET_TC_STRING', TCString))
  }
}
