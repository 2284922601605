export default {
  SET_SCORES (state, scores) {
    state.scores = scores
  },
  ADD_SCORE (state, score) {
    state.scores.items.push({
      item: {
        id: score.id
      },
      playTime: score.playTime
    })
  },
  REMOVE_SCORE (state, score) {
    const scoreIndex = state.scores.items
      .findIndex(scoreToDelete => scoreToDelete.item.id === score.itemId)
    state.scores.items.splice(scoreIndex, 1)
  }
}
