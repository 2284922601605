export function SecondaryEventAnswers ($http) {
  const BASE_URL = '/int/items/secondary/events'
  return {
    question: {
      post ({ eventId, answer }) {
        return $http.post(`${BASE_URL}/${eventId}/answers`, answer)
      }
    }
  }
}
