import Vue from 'vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)

export default ({ app, store }) => {
  Vue.prototype.$time = function (param) {
    if (param) {
      return dayjs(param)
    }
    return dayjs().subtract(store.state.api.clientServerDelay, 'milliseconds').clone()
  }
  Vue.filter('dateFormat', (val, format) => dayjs(val).format(format))
  store.$time = app.$time = Vue.prototype.$time
}
