import md5 from 'blueimp-md5'
import { API_DEVICE_HEADER, API_PROFILE_HEADER } from '@/api/enums'

const SECRET_KEY = process.env.RECOMMENDATIONS_SECRET_KEY
const RECOMMENDATIONS_ENDPOINT = process.env.RECOMMENDATIONS_ENDPOINT
const VODS = 'vods'
const RECOMMENDATIONS_COUNT = 20

function getSignature ({
  numberOfRecommendations,
  timestamp,
  userId,
  itemType,
  iuid = '',
  lastSeenItem = '',
  tags = ''
}) {
  return md5(`${userId};${iuid};${itemType};${tags};${numberOfRecommendations};${lastSeenItem};${timestamp};${SECRET_KEY}`)
}

export function Recommendations ($http) {
  return {
    edge: {
      get ({ timestamp, userId, abtShieldId, lastSeenItem, itemType = VODS }) {
        const params = {
          timestamp,
          userId,
          abtShieldId,
          lastSeenItem,
          itemType,
          numberOfRecommendations: RECOMMENDATIONS_COUNT
        }

        if (!RECOMMENDATIONS_ENDPOINT) {
          console.error('There is no "RECOMMENDATIONS_ENDPOINT" variable in env')
          return Promise.resolve()
        }

        return $http.get(RECOMMENDATIONS_ENDPOINT, {
          transformRequest: (data, headers) => {
            delete headers[API_DEVICE_HEADER]
            delete headers[API_PROFILE_HEADER]
            delete headers.common[API_DEVICE_HEADER]
            delete headers.common[API_PROFILE_HEADER]
          },
          params: {
            ...params,
            signature: getSignature(params)
          }
        })
      }
    }
  }
}
