import {
  FAVOURITE,
  RATING,
  WATCHED
} from '@/assets/javascript/enums/bookmarks-types'

import { API_PROFILE_HEADER } from '../enums'

export function Subscribers ($http) {
  const BASE_URL = '/subscribers'
  return {
    agreements: {
      get () {
        return $http.get(`${BASE_URL}/agreements`)
      },
      post (payload) {
        return $http.post(`${BASE_URL}/agreements`, payload)
      }
    },
    bookmarks: {
      delete (payload) {
        return $http.delete('int/subscribers/bookmarks', {
          params: payload
        })
      },
      get (payload) {
        return $http.get(`${BASE_URL}/bookmarks`, {
          params: payload
        })
      },
      post (payload, params) {
        return $http.post('int/subscribers/bookmarks',
          payload, params, {
            transformRequest: (data, headers) => {
              delete headers.common[API_PROFILE_HEADER]
            }
          })
      },
      favourites: {
        get () {
          return $http.get(`${BASE_URL}/bookmarks?type=${FAVOURITE}`)
        }
      },
      watched: {
        get () {
          return $http.get(`${BASE_URL}/bookmarks?type=${WATCHED}`)
        }
      },
      scores: {
        get () {
          return $http.get(`${BASE_URL}/bookmarks?type=${RATING}`)
        }
      }
    },
    changePassword (payload) {
      return $http.put(`${BASE_URL}/password?isCaptchaV3=true`, payload)
    },
    changePhone (payload) {
      return $http.put(`${BASE_URL}/phone`, payload)
    },
    cyclic: {
      payments: {
        delete ({ cyclicPaymentId }) {
          return $http.delete(`${BASE_URL}/cyclic/payments/${cyclicPaymentId}`)
        },
        get ({ firstResult, maxResults }) {
          return $http.get(`${BASE_URL}/cyclic/payments`, {
            params: {
              firstResult,
              maxResults
            },
            progress: false
          })
        }
      }
    },
    deletePhone () {
      return $http.delete(`${BASE_URL}/phone`)
    },
    detail: {
      get () {
        return $http.get(`${BASE_URL}/detail`)
      }
    },
    devices: {
      get () {
        return $http.get(`${BASE_URL}/devices`)
      },
      delete (uid) {
        return $http.delete(`${BASE_URL}/devices`, {
          params: { uid }
        })
      },
      oneTimeCode: {
        put (code) {
          return $http.put(`${BASE_URL}/devices/otc`, { code })
        }
      }
    },
    favourites: {
      get () {
        return $http.get(`${BASE_URL}/favourites`)
      }
    },
    login (payload) {
      return $http.post(`${BASE_URL}/login`, payload)
    },
    logout () {
      return $http.post(`${BASE_URL}/logout`)
    },
    register (payload) {
      return $http.post(`${BASE_URL}/register?isCaptchaV3=true`, payload)
    },
    resetPassword (payload) {
      return $http.put(`${BASE_URL}/password/reset?isCaptchaV3=true`, payload)
    },
    setNewPassword (payload) {
      return $http.put(`${BASE_URL}/password/reset/token`, payload)
    },
    payments: {
      get ({ firstResult, maxResults }) {
        return $http.get(`${BASE_URL}/payments`, {
          params: {
            firstResult,
            maxResults
          }
        })
      }
    },
    notifications: {
      get (since) {
        return $http.get(`${BASE_URL}/notifications?since=${since}`)
      },
      subscribe (type, itemId) {
        return $http.post(`${BASE_URL}/notifications/${type.toLowerCase()}s/${itemId}/subscribe`)
      },
      unsubscribe (type, itemId) {
        return $http.delete(`${BASE_URL}/notifications/${type.toLowerCase()}s/${itemId}/unsubscribe`)
      },
      updateToken (fcmToken) {
        return $http.put(`${BASE_URL}/notifications/token`, { token: fcmToken })
      }
    },
    resendPhoneCode ({ phone }) {
      return $http.post(`${BASE_URL}/phone/verify/resend?phone=${phone}`)
    },
    verify (token) {
      return $http.post(`${BASE_URL}/mail/verify`, { verifyMailToken: token })
    },
    verifyPhone (payload) {
      return $http.post(`${BASE_URL}/phone/verify`, payload)
    },
    products: {
      available: {
        get () {
          return $http.get(`${BASE_URL}/products/available/list`)
        }
      }
    },
    session: {
      put () {
        const RETRY_TIMEOUTS_ON_ERROR = [1000, 60000, 180000, 300000, 600000, 900000]

        return $http.put(`${BASE_URL}/httpsession`, {}, {
          progress: false,
          validateStatus: status => status >= 200 && status < 400,
          retry: {
            retries: RETRY_TIMEOUTS_ON_ERROR.length,
            retryDelay: (retryCount) => {
              return RETRY_TIMEOUTS_ON_ERROR[Math.min(retryCount, RETRY_TIMEOUTS_ON_ERROR.length - 1)]
            }
          }
        })
      }
    },
    vouchers: {
      add (code) {
        return $http.post(`${BASE_URL}/promotions`, code)
      }
    }
  }
}
