import { RATING } from '@/assets/javascript/enums/bookmarks-types'

export default {
  GET_SCORES ({ commit }) {
    return this.$api
      .resources
      .subscribers
      .bookmarks
      .scores
      .get()
      .then(data => {
        commit('SET_SCORES', data)
      })
  },
  ADD_SCORE ({ dispatch, commit, rootState }, { id, playTime, profile, videoType }) {
    return dispatch('user/POST_BOOKMARK', {
      id,
      videoType,
      profile,
      playTime,
      type: RATING
    }, { root: true })
      .then(
        () => {
          commit('ADD_SCORE', { id, playTime })
        },
        () => this.$toast.error(this.$i18n.t('scores.errorOnAdd'))
      )
  },
  REMOVE_SCORE ({ commit, dispatch }, { id, profileId, profileToken }) {
    return dispatch('user/DELETE_BOOKMARK', {
      id,
      profileId,
      profileToken,
      type: RATING
    }, {
      root: true
    })
      .then(
        () => {
          commit('REMOVE_SCORE', { itemId: id })
        },
        () => this.$toast.error(this.$i18n.t('scores.errorOnDelete'))
      )
  }
}
