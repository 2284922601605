import PacketFactory from '@/assets/javascript/play/factory/packet'

export default {
  GET ({ rootState }, { id }) {
    return this.$axios
      .get(`/products/packets/${id}`)
      .then(data => PacketFactory(data, rootState.user))
  },
  GET_ALL ({ rootState }) {
    return this.$axios
      .get('/products/packets', {
        withPacketFamily: true,
        params: {
          availablePacketId: rootState.user.products?.packetIds
        }
      })
      .then(data => data.map(packet => PacketFactory(packet, rootState.user)))
  }
}
