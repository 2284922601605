export default {
  ADD_DEVICE: 'add-device',
  ADD_PROFILE: 'add-profile',
  BOUGHT: 'bought',
  BUY_AND_WATCH: 'buy-and-watch',
  BROADCAST: 'broadcast',
  BROADCAST_PROFILE: 'broadcast-profile',
  CATALOG: 'catalog',
  COMPATIBILITY_TEST: 'compatibility-test',
  CONTACT: 'contact',
  DEVICES: 'devices',
  DOCUMENT: 'document',
  EDIT_PROFILE: 'edit-profile',
  FAVOURITES: 'favourites',
  GOOGLE_LOGIN: 'google-login',
  HOME: 'home',
  ISSUES: 'issues',
  LOGIN: 'login',
  MANAGE_PROFILES: 'profile',
  MESSAGES: 'messages',
  MY_ACCOUNT: 'my-account',
  MY_ACCOUNT_PROFILE: 'my-account-profile',
  MY_LIST: 'my-list',
  PAYMENT_CONFIRMED: 'payment-confirmed',
  PAYMENTS: 'payments',
  RECORDINGS: 'recordings',
  REDIRECT: 'redirect',
  REGISTER: 'register',
  RESET_PASSWORD: 'reset-password',
  SET_NEW_PASSWORD: 'set-new-password',
  SEARCH: 'search',
  SECTIONS: 'sections',
  SHOW_PROFILES: 'show-profiles',
  STATIC_PAGE: 'static-page',
  TRANSACTIONS: 'transactions',
  TRANSMISSION: 'transmission',
  VERIFY: 'verify',
  WATCH: 'watch',
  WATCH_ON_MOBILE: 'watch-on-mobile',
  WATCHED: 'watched'
}
