export const NONE = undefined

const metricsHelper = metricsFunction => (data = {}, { fixed, remove } = {}) => {
  let metrics = {
    ...metricsFunction(data)
  }

  if (fixed) {
    metrics = {
      ...metrics,
      ...fixed
    }
  }

  if (remove) {
    remove.forEach(property => {
      delete metrics[property]
    })
  }
  return metrics
}

const pageEventMetrics = data => ({
  action: data.action,
  placement: data.placement || 'Section',
  positionInSection: data.position || 1,
  pageCategory: data.pageCategory,
  itemId: data.id,
  sectionId: data.sectionId,
  e2Id: data.e2Id || NONE,
  detailAction: data.action,
  sectionOrder: data.sectionPosition,
  displayWindowType: NONE // todo: obsługiwać to property
})

const formMetrics = data => ({
  action: data.action,
  formName: data.formName,
  validationErrorFailed: data.validationError || NONE,
  submissionError: data.submissionError || NONE
})

const videoMetrics = data => ({
  itemId: data.itemId, /* id itemu */
  videoOffset: Math.round(data.progress).toString(), /* moment wystąpienia zdarzenia - czas liczony od początku filmu */
  sessionOffset: Math.round(data.sessionDurationInMs / 1000), /* czas liczony od początku sesji */
  trackQuality: data.bandwidth, /* bitrate w kbps */
  isResumed: data.isResumed, /* czy rozpoczął odtwarzanie od momentu gdzie ostatnio skończył */
  epg_item_id: data.liveItemId, /* w przypadku live */
  pageViewId: data.pageViewId, /* potrzebujemy robić preserve tego data na poziomie video ze względu na event: CLOSED */
  streamType: data.type.toLowerCase(),
  // poniższe tylko dla event typu "PLAYING":
  ...(
    data.isPlayingAction
      ? {
        delay: data.bufferingDurationInMs !== undefined
          ? Math.round(data.bufferingDurationInMs / 1000)
          : NONE, /* czas buforowania przed uruchomieniem filmu w milisekundach */
        maxAvailableBitrate: data.minAvailableBandwidth || NONE, /* maksymalna dostępna reprezentacja kB */
        minAvailableBitrate: data.maxAvailableBandwidth || NONE /* minimalna dostępna reprezentacja kB */
      }
      : {}
  )
})

const ecommerceMetrics = (data = {}) => {
  return {
    packetId: data.id || NONE,
    cycle: data.cycle || NONE,
    billingCycle: data.cycle || NONE,
    price: data.price || NONE
  }
}
const actionMetrics = (data = {}) => {
  return {
    action: data.action || NONE,
    itemId: data.id || NONE,
    commentId: data.commentId || NONE, // todo brak funkcjonalności
    reactionId: data.reactionId || NONE, // todo brak funkcjonalności
    labelId: data.labelId || NONE // todo brak funkcjonalności
  }
}
const userMetrics = (data = {}) => {
  return {
    action: data.action,
    loginProvider: data.loginProvider
  }
}

const searchMetrics = (data = {}) => {
  return {
    action: data.action,
    searchValue: data.searchValue
  }
}

const secondaryMetrics = data => {
  return {
    action: data.action,
    itemId: parseInt(data.itemId, 10),
    displayTime: data.displayTime,
    videoSessionId: data.videoSessionId,
    value: data.value,
    videoOffset: Math.round(data.progress).toString(),
    url: data.url
  }
}

export const getPageEventMetrics = metricsHelper(pageEventMetrics)
export const getFormMetrics = metricsHelper(formMetrics)
export const getVideoMetrics = metricsHelper(videoMetrics)
export const getUserMetrics = metricsHelper(userMetrics)
export const getSearchMetrics = metricsHelper(searchMetrics)
export const getActionMetrics = metricsHelper(actionMetrics)
export const getEcommerceMetrics = metricsHelper(ecommerceMetrics)
export const getSecondaryMetrics = metricsHelper(secondaryMetrics)
