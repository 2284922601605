import PlayCardFactory from '@/assets/javascript/play/factory/card'
import TvodCardFactory from '@/assets/javascript/play/factory/tvod-card'
import { TVOD, VOD, EPISODE } from '@/assets/javascript/enums/product-types'

const VODS = 'vods'
const BASE_PATH = '/recommendations'

export default {
  GET_FROM_EDGE ({ commit, dispatch, rootState }, { timestamp, userId, lastSeenItem, itemType }) {
    const abtShieldId = rootState.tracking.iuid

    return this.$api
      .resources
      .recommendations
      .edge
      .get({ timestamp, userId, abtShieldId, lastSeenItem, itemType: itemType ? itemType.toLowerCase() + 's' : VODS })
      .then(async (data) => {
        if (!data.length) {
          return
        }

        const [vods, programmes] = await Promise.all([
          dispatch('products/GET_VODS', { productId: data, itemType: [VOD, EPISODE] }, { root: true }),
          dispatch('products/GET_PROGRAMMES', data, { root: true })
        ])

        const items = vods.items

        if (programmes.items) {
          items.push.programmes.items
        }

        if (!lastSeenItem) {
          commit('SET_EDGE_RECOMMENDATIONS', items.map(item => ({ item })))
          return items
        }

        return items
      })
  },
  GET (_, recommendationId = []) {
    return this.$axios
      .get(BASE_PATH, {
        params: {
          recommendationId,
          maxResults: 20
        }
      })
  },
  GET_FOR_PRODUCT ({ rootState }, { productId, maxResults = 15 }) {
    return this.$axios
      .get(`${BASE_PATH}/products/${productId}`, {
        params: {
          maxResults
        },
        withPriceStrategy: true
      })
      .then(recommendations => recommendations.map(recommendation => {
        if (recommendation.type === TVOD) {
          return TvodCardFactory(recommendation, rootState.user)
        }
        return PlayCardFactory(recommendation)
      }))
  }
}
