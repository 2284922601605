import { API_DEVICE_HEADER } from '@/api/enums'

export function Events ($http) {
  return {
    send (data) {
      return $http.get(process.env.EVENTS_ENDPOINT, {
        transformRequest: (data, headers) => {
          delete headers[API_DEVICE_HEADER]
        },
        params: data
      })
    }
  }
}
