import getValidSchedules from '@/assets/javascript/helpers/get-valid-schedules'

export default function getLowestSchedule (product, time, type) {
  const validSchedules = getValidSchedules(product, time, type)

  if (!validSchedules.length) {
    return
  }

  return validSchedules.sort((a, b) => a.prices.PREPAID_ONLINE - b.prices.PREPAID_ONLINE)[0]
}
