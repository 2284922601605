import { API_PROFILE_UID } from '@/assets/javascript/enums/headers'
import { RECORDING } from '@/assets/javascript/enums/video-types'

import { asyncLeadingThrottle } from '@/assets/javascript/utils'

export const DEFAULT_MAX_PAYMENTS = 20

const MIN_DELAY_IN_SEC_BETWEEN_SENDING_BOOKMARKS = 3

const postBookmark = ({ api, id, type, playTime, videoType, profile }) => {
  return api
      .resources
      .subscribers
      .bookmarks
      .post({
        itemId: id,
        playTime,
        videoType,
        profile
      }, {
        progress: false,
        params: {
          type: type,
          recording: videoType === RECORDING
        }
      })
}

const postBookmarkThrottled = asyncLeadingThrottle(postBookmark, MIN_DELAY_IN_SEC_BETWEEN_SENDING_BOOKMARKS * 1000)
const ITEM = 'item'

export default {
  ADD_TO_NOTIFICATIONS ({ commit }, notification) {
    return this.$api
        .resources
        .subscribers
        .notifications
        .subscribe(ITEM, notification.id)
        .then(() => {
          commit('ADD_NOTIFICATION', notification)
          this.$toast.success(this.$i18n.t('notifications.added', { title: notification.title }))
        })
        .catch(() => this.$toast.error(this.$i18n.t('notifications.errorOnAdd')))
  },
  REMOVE_FROM_NOTIFICATIONS ({ commit }, item) {
    return this.$api
        .resources
        .subscribers
        .notifications
        .unsubscribe(ITEM, item.id)
        .then(() => {
          commit('REMOVE_NOTIFICATION', item.id)
          this.$toast.success(this.$i18n.t('notifications.deleted', { title: item.title }))
        })
        .catch(() => this.$toast.error(this.$i18n.t('notifications.errorOnDelete')))
  },
  CLEAR_USER_CTX ({ commit }) {
    this.$cookie.delete('rememberMe')
    window.localStorage.setItem('vuex', '')
    commit('SET_AVAILABLE_PRODUCTS', [])
    commit('SET_NOTIFICATIONS', [])
    commit('SET_USER', {})
    commit('SET_ACTIVE_SUBSCRIPTIONS', [])
    commit('SET_AGREEMENTS', [])
    commit('watched/SET', [], { root: true })
    commit('favourites/SET', [], { root: true })
    commit('recordings/SET_RECORDINGS', [], { root: true })
    commit('payments/SET_CARDS', [], { root: true })
    commit('broadcast/SET_BROADCAST_PROFILE', {}, { root: true })
    commit('broadcast-profiles/SET_BROADCAST_PROFILES', [], { root: true })
    commit('scores/SET_SCORES', [], { root: true })
  },
  DELETE_BOOKMARK (_, { id, type, profileId, profileToken }) {
    return this.$api
        .resources
        .subscribers
        .bookmarks
        .delete({
          itemId: [id],
          type,
          token: profileToken,
          profileId: profileId
        })
  },
  GET_BOOKMARKS (_, { type, firstResult = 0, maxResults = 20 }) {
    return this.$api
        .resources
        .subscribers
        .bookmarks
        .get({ type, firstResult, maxResults })
        .then(data => data.items)
  },
  GET_DEVICES ({ commit }) {
    return this.$api
        .resources
        .subscribers
        .devices
        .get()
        .then(data => {
          commit('user/SET_DEVICES', data, { root: true })
        })
        .catch(() => {})
  },
  DELETE_DEVICE (_, uid) {
    return this.$api
      .resources
      .subscribers
      .devices
      .delete(uid)
  },
  GET_NOTIFICATIONS ({ commit }, since) {
    return this.$api
        .resources
        .subscribers
        .notifications
        .get(since)
        .then((notifications) => commit('SET_NOTIFICATIONS', notifications.items))
  },
  CHECK_ONE_TIME_CODE (_, code) {
    return this.$api
        .resources
        .subscribers
        .devices
        .oneTimeCode
        .put(code)
  },
  DELETE_CYCLIC_PAYMENT ({ commit }, { cyclicPaymentId }) {
    return this.$api
        .resources
        .subscribers
        .cyclic
        .payments
        .delete({ cyclicPaymentId })
        .then(() => {
          commit('SET_SUBSCRIPTION_AS_TERMINATED', cyclicPaymentId)
        })
  },
  GET_CYCLIC_PAYMENTS ({ commit }, { firstResult = 0, maxResults = DEFAULT_MAX_PAYMENTS }) {
    return this.$api
        .resources
        .subscribers
        .cyclic
        .payments
        .get({ firstResult, maxResults })
        .then(payments => {
          commit('SET_ACTIVE_SUBSCRIPTIONS', payments?.items)
          return payments
        })
        .catch(() => {})
  },
  GET_PAYMENTS ({ commit }, { firstResult = 0, maxResults = DEFAULT_MAX_PAYMENTS }) {
    return this.$api
      .resources
      .subscribers
      .payments
      .get({ firstResult, maxResults })
      .then(payments => {
        commit('SET_PAYMENTS', payments.items)
        return payments
      })
  },
  async GET_USER_CTX ({ dispatch }) {
    try {
      const format = 'YYYY-MM-DDTHH:mmZZ'
      // TODO Find better solution to check if user is still loggedIn
      await Promise.all([
        dispatch('GET_AVAILABLE_PRODUCTS'),
        dispatch('GET_NOTIFICATIONS', encodeURIComponent(this.$time().format(format))),
        dispatch('GET_AGREEMENTS'),
        this.cache.dispatch('user/GET_DETAILS'),
        dispatch('favourites/GET_FAVOURITES', {}, { root: true }),
        dispatch('watched/GET_WATCHED', {}, { root: true }),
        dispatch('playlist/GET_PLAYLIST', {}, { root: true }),
        dispatch('payments/GET_CARDS', {}, { root: true }),
        dispatch('recordings/GET_RECORDINGS', {}, { root: true }),
        dispatch('scores/GET_SCORES', {}, { root: true })
      ])
    } catch (e) {
      if (e.status === 403) {
        dispatch('CLEAR_USER_CTX', {})
      }
    }
  },
  CHANGE_PASSWORD (_, { captcha, oldPassword, newPassword }) {
    return this.$api.resources
      .subscribers
      .changePassword({ captcha, oldPassword, newPassword })
  },
  CHANGE_PHONE ({ commit }, { password, phone }) {
    return this.$api.resources
      .subscribers
      .changePhone({ password, phone })
      .then(() => {
        commit('SET_USER_PHONE', phone)
      })
  },
  DELETE_PHONE ({ commit }) {
    return this.$api.resources
      .subscribers
      .deletePhone()
      .then(() => {
        commit('SET_USER_PHONE', '')
        commit('SET_USER_PHONE_VERIFIED', false)
        commit('SET_USER_PHONE_TIMEOUT', false)
      })
  },
  GET_FAVOURITES_OPTIONS ({ commit }) {
    return this.$api
      .resources
      .subscribers
      .favourites
      .get()
      .then((data) => commit('SET_FAVOURITES_OPTIONS', data))
  },
  LOGIN ({ commit, rootState }, { email, password, rememberMe }) {
    return this.$api.resources
      .subscribers
      .login({ email, password, rememberMe, appVersion: rootState.api.appVersion })
      .then((data) => {
        // TODO this shouldn't be here
        this.$axios.setHeader(API_PROFILE_UID, data.profile.uid)
        commit('SET_USER', data)
      })
  },
  LOGIN_SOCIAL ({ commit }, { externalToken, externalAuthProvider }) {
    return this.$api.resources
      .subscribers
      .login({ externalToken, externalAuthProvider })
      .then((data) => commit('SET_USER', data))
  },
  LOGOUT ({ dispatch }, { timestamp }) {
    return this.$api.resources
      .subscribers
      .logout()
      .finally(() => {
        dispatch('CLEAR_USER_CTX', {})
        this.cache.delete('user/GET_USER_CTX')
        this.cache.dispatch('recommendations/GET_FROM_EDGE', {
          timestamp,
          userId: ''
        })
      })
  },
  POST_BOOKMARK ({ getters }, { id, type, playTime, videoType, profile, force = false }) {
    if (!getters.LOGGED_IN) {
      return
    }

    const params = { api: this.$api, id, type, playTime, videoType, profile }
    if (force) {
      return postBookmark(params)
    }
    return postBookmarkThrottled.call(this, params)
  },
  REGISTER (_, { agreements = [], captcha, email, password, phone, repeatedPassword, name }) {
    return this.$api.resources
      .subscribers
      .register({ agreements, captcha, email, password, repeatedPassword, name, ...(phone && { phone }) })
  },
  RESET_PASSWORD (_, { captcha, email }) {
    return this.$api.resources
      .subscribers
      .resetPassword({ captcha, email })
  },
  SET_FCM_TOKEN (_, fcmToken) {
    return this.$api.resources
        .subscribers
        .notifications
        .updateToken(fcmToken)
  },
  SET_NEW_PASSWORD (_, { newPassword, passwordResetToken }) {
    return this.$api.resources
      .subscribers
      .setNewPassword({ newPassword, passwordResetToken })
  },
  GET_AGREEMENTS ({ commit }) {
    return this.$api.resources
      .subscribers
      .agreements
      .get()
      .then(agreements => {
        commit('SET_AGREEMENTS', agreements)
        return agreements
      })
  },
  POST_AGREEMENTS ({ commit }, updatedAgreements) {
    return this.$api.resources
      .subscribers
      .agreements
      .post({ agreements: updatedAgreements })
      .then(() => commit('SET_AGREEMENTS', updatedAgreements))
  },
  GET_DETAILS ({ commit }) {
    return this.$api.resources
      .subscribers
      .detail
      .get()
      .then(data => {
        commit('SET_USER', data)
        return data
      })
  },
  GET_AVAILABLE_PRODUCTS ({ commit }) {
    return this.$api
      .resources
      .subscribers
      .products
      .available
      .get()
      .then(products => {
        const productIds = products.map(({ productId }) => productId)

        commit('SET_AVAILABLE_PRODUCTS', productIds)
        return productIds
      })
      .catch(() => {})
  },
  RESEND_PHONE_CODE (_, { phone }) {
    return this.$api
      .resources
      .subscribers
      .resendPhoneCode({ phone })
  },
  SUBSCRIBE_NOTIFICATION (_, { type, itemId }) {
    return this.$api
        .resources
        .subscribers
        .notifications
        .subscribe(type, itemId)
  },
  UNSUBSCRIBE_NOTIFICATION (_, { type, itemId }) {
    return this.$api
        .resources
        .subscribers
        .notifications
        .unsubscribe(type, itemId)
  },
  VERIFY_PHONE ({ commit }, { verifyPhoneToken }) {
    return this.$api
      .resources
      .subscribers
      .verifyPhone({ verifyPhoneToken })
      .then(() => commit('SET_USER_PHONE_VERIFIED', true))
  },
  ADD_VOUCHER (_, code) {
    return this.$api
      .resources
      .subscribers
      .vouchers
      .add(code)
  }
}
