const UID = 'uid'
const IUID = 'iuid'

const mutateId = (state, idKey, idValue) => {
  const localStorage = window.localStorage
  if (![UID, IUID].includes(idKey)) {
    return
  }

  if (state[idKey] !== idValue) {
    state[idKey] = idValue

    if (localStorage) {
      if (idValue) {
        localStorage.setItem(idKey, idValue)
      } else {
        localStorage.removeItem(idKey)
      }
    }
  }
}

export default {
  CLEAR_EVENT_DATA (state, { event }) {
    state.collection = state.collection.filter(item => item.event !== event)
  },
  SET_EVENT_DATA (state, { event, data }) {
    if (!event) {
      return
    }

    const existingData = state.collection
      .find(item => item.event === event)

    if (existingData) {
      state.collection = state.collection
        .map(element => {
          if (element.event === event) {
            return { event, data }
          } else {
            return element
          }
        })
    } else {
      state.collection = [...state.collection, { event, data }]
    }
  },
  SET_IUID (state, iuid) {
    mutateId(state, IUID, iuid)
  },
  SET_UID (state, uid) {
    const newUid = state[UID] !== uid && (window.localStorage && window.localStorage.getItem(UID) !== uid)
    mutateId(state, UID, uid)
    if (newUid) {
      mutateId(state, IUID)
    }
  },
  SET_SESSID (state, sessid) {
    state.sessid = sessid
  }
}
