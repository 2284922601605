import UAParser from 'ua-parser-js'
import compareVersions from 'compare-versions'

const uaString = window.navigator.userAgent
const uaParser = new UAParser(uaString)
const device = uaParser.getDevice()
const browser = uaParser.getBrowser()

const DeviceTypes = {
  desktop: 'Desktop',
  tablet: 'Tablet',
  mobile: 'Smartfon',
  tv: 'Tv'
}

const CHROME_MIN_VERSION = '59'
const FF_MIN_VERSION = '54'
const OPERA_MIN_VERSION = '46'
const FF_WITHOUT_WV_VERSION = '47'
const PLAYER_RWD_CHROME_MIN_VERSION = '57'
const PLAYER_RWD_IOS_MIN_VERSION = '11.2'
const MIN_MAC_OS_VERSION = '10.12'

const ORSAY_USER_AGENT = ['SMART-TV', 'SMARTTV']
const SONY_USER_AGENT = ['BRAVIA', 'SONYDTV', 'PIXEL_FREEVIEW']
const PHILIPS_USER_AGENT = ['PHILIPS']
const TIZEN_USER_AGENT = ['TIZEN']
const OTHER_TV_USER_AGENT = ['ANDR0ID', 'HBBTV', 'VIERA', 'PIXEL_UNICORN2']
const TWITTER_BOT = ['TWITTER']
const FB_BOT = ['FACEBOOK']

const findInAgentString = (userAgents) => {
  return userAgents.some((e) => {
    return navigator.userAgent.toUpperCase().indexOf(e) >= 0
  })
}

const DeviceInfo = {
  getDeviceType () {
    return DeviceTypes[device.type] || DeviceTypes.desktop
  },
  getOs () {
    return uaParser.getOS() || {}
  },
  getBrowserName () {
    return browser && browser.name
  },
  getBrowserVersion () {
    return browser && browser.version
  },
  getSystemName () {
    return this.getOs().name || ''
  },
  getSystemVersion () {
    return this.getOs().version
  },
  isElephant () {
    const ua = uaParser.getUA()
    return ua.indexOf('Googlebot') > -1 || ua.indexOf('Google Search') > -1
  },
  isWindows () {
    return this.getSystemName() === 'Windows'
  },
  isXp () {
    return this.isWindows() && this.getOs().version === 'XP'
  },
  isVista () {
    return this.isWindows() && this.getOs().version === 'Vista'
  },
  isWin7 () {
    return this.isWindows() && this.getOs().version === '7'
  },
  isMacOs () {
    return this.getSystemName() === 'Mac OS'
  },
  isFF () {
    return this.getBrowserName() === 'Firefox'
  },
  isSafari () {
    return this.getBrowserName() === 'Safari'
  },
  isChrome () {
    return this.getBrowserName() === 'Chrome'
  },
  isIE () {
    return this.getBrowserName() === 'IE'
  },
  isOpera () {
    return this.getBrowserName() === 'Opera'
  },
  isMobileSafari () {
    return this.getBrowserName() === 'Mobile Safari'
  },
  isAndroid () {
    return this.isMobile() && this.getSystemName().toUpperCase() === 'ANDROID'
  },
  isIOS () {
    return this.isMobile() && this.getSystemName().toUpperCase() === 'IOS'
  },
  isDesktop () {
    return device.type === 'desktop'
  },
  isTablet () {
    return device.type === 'tablet'
  },
  isPhone () {
    return device.type === 'mobile'
  },
  isTV () {
    return device.type === 'tv'
  },
  isTizen () {
    return this.getSystemName().toUpperCase() === 'TIZEN' || findInAgentString(TIZEN_USER_AGENT)
  },
  isOrsay () {
    return findInAgentString(ORSAY_USER_AGENT)
  },
  isSony () {
    return findInAgentString(SONY_USER_AGENT)
  },
  isPhilips () {
    return findInAgentString(PHILIPS_USER_AGENT)
  },
  isOtherTVDevice () {
    return findInAgentString(OTHER_TV_USER_AGENT)
  },
  isSmartTv () {
    return this.isTizen() || this.isOrsay() || this.isSony() || this.isPhilips() || this.isOtherTVDevice()
  },
  isMobile () {
    return !this.isTizen() && (this.isTablet() || this.isPhone())
  },
  isFbBot () {
    return findInAgentString(FB_BOT)
  },
  isTwitterBot () {
    return findInAgentString(TWITTER_BOT)
  },
  isMobileEligibleToPlay () {
    const version = browser.version
    const osVersion = this.getSystemVersion()

    return (this.isIOS() && compareVersions(osVersion, PLAYER_RWD_IOS_MIN_VERSION) >= 0 && this.isMobileSafari()) || (this.isMobile() && !this.isIOS() && this.isChrome() && compareVersions(version, PLAYER_RWD_CHROME_MIN_VERSION) >= 0)
  },
  isTouchDevice () {
    return 'ontouchstart' in document.documentElement || this.isMobile()
  },
  isSupported () {
    // We display old browser page for:

    // All IE versions
    if (this.isIE()) {
      return false
    }

    // Older Firefox without widevine support
    return !(this.isFF() && compareVersions(browser.version, FF_WITHOUT_WV_VERSION) < 0)
  },
  isOnlyPartiallySupported () {
    const version = browser.major

    if (!this.isMobile() && ((this.isChrome() && compareVersions(version, CHROME_MIN_VERSION) < 0) || (this.isFF() && compareVersions(version, FF_MIN_VERSION) < 0) || (this.isOpera() && compareVersions(version, OPERA_MIN_VERSION) < 0))
    ) {
      return true
    }

    return this.isSafari() || this.isMobileSafari()
  },
  isUnsupportedOS () {
    const isUnsupportedMacOs = this.isMacOs() && compareVersions(this.getSystemVersion(), MIN_MAC_OS_VERSION) < 0
    return (this.isXp() || this.isVista() || this.isWin7() || isUnsupportedMacOs)
  }
}

export { DeviceInfo, uaString }
