export function Items ($http) {
  const BASE_URL = '/items'

  return {
    additionalMedia: {
      get (itemId) {
        return $http.get(`${BASE_URL}/${itemId}/additional/medias`)
      }
    },
    categories: {
      get (categoryId, kids) {
        const params = {
          mainCategoryId: categoryId
        }

        if (kids) {
          params.kids = true
        }

        return $http.get(`${BASE_URL}/categories`, { params })
      }
    },
    secondaryEvents: {
      get (productId) {
        return $http.get(`${BASE_URL}/${productId}/secondary/events`)
      }
    },
    secondaryEvent: {
      get (id, type) {
        return $http.get(`${BASE_URL}/secondary/events/${id}/${type}`)
      }
    }
  }
}
