import MetaData from '@/assets/javascript/factories/metadata'
import BaseProduct from '@/assets/javascript/factories/product/base'
import Image from '@/assets/javascript/factories/image'

export default function (product, extend) {
  const image = Image(product)
  const Card = {
    ...BaseProduct(product),
    description: product.description,
    metadata: MetaData(product),
    thumbnail: image?.url,
    ...(product.rating && { rating: product.rating }),
    ...(product.urlWeb && { urlWeb: product.urlWeb })
  }

  if (extend instanceof Function) {
    return { ...Card, ...extend(product) }
  }

  return Card
}
