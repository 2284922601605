export default {
  GET_API_INFO ({ commit }) {
    return this.$api.resources
      .info
      .get()
      .then(({ data, serverTime }) => {
        commit('SET_CLIENT_SERVER_DELAY', serverTime)
        commit('SET_MAX_PROFILES_COUNT', data.maxProfilesCount)
        commit('SET_CMP_URL', data.cmp.infoUrl)
        commit('SET_CMP_TENANT', data.cmp.tenantUid)
      })
      .then(() => commit('SET_APP_VERSION', process.env.CURRENT_APP_VERSION))
  }
}
