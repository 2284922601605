import { addAuthRequiredInterceptorHandler } from '@/api/interceptors/handle-auth-required'
import { addDefaultPlatformInterceptor } from '@/api/interceptors/default-platform'
import { addSyncClientWithServerInterceptor } from '@/api/interceptors/sync-client-with-server'
import { addDefaultHeadersInterceptor } from '@/api/interceptors/default-headers'

export const interceptors = [
  addAuthRequiredInterceptorHandler,
  addDefaultPlatformInterceptor,
  addSyncClientWithServerInterceptor,
  addDefaultHeadersInterceptor
]
