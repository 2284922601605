export default {
  head () {
    const appName = 'WOW'
    const deeplink = 'ottwow://ottwow.com/'
    const deeplinkIOS = 'pl.festival.mobile.ios://ottwow.com/'
    const imageURL = 'https://r-scale-a5.dcs.redcdn.pl/scale/fg/wow/upload/vod/974737/images/4038456?dsth=1080&dstw=1920&srcmode=0&srcx=0&srcy=0&quality=65&type=1&srcw=1/1&srch=1/1'

    return {
      meta: [
        {
          property: 'al:android:app_name',
          content: appName
        },
        {
          property: 'al:android:package',
          content: process.env.ANDROID_PACKAGE_NAME
        },
        {
          property: 'al:android:url',
          content: deeplink
        },
        {
          property: 'twitter:app:name:googleplay',
          content: appName
        },
        {
          property: 'twitter:app:id:googleplay',
          content: process.env.ANDROID_PACKAGE_NAME
        },
        {
          property: 'twitter:app:url:googleplay',
          content: deeplink
        },
        {
          property: 'al:ios:app_name',
          content: appName
        },
        {
          property: 'al:ios:app_store_id',
          content: process.env.IOS_APP_STORE_ID
        },
        {
          property: 'al:ios:url',
          content: deeplinkIOS
        },
        {
          property: 'twitter:app:name:iphone',
          content: appName
        },
        {
          property: 'twitter:app:id:iphone',
          content: process.env.IOS_APP_STORE_ID
        },
        {
          property: 'twitter:app:url:iphone',
          content: deeplinkIOS
        },
        {
          property: 'og:url',
          content: window.location.href
        },
        {
          property: 'og:title',
          content: appName
        },
        {
          property: 'og:description',
          content: appName
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: imageURL
        },
        {
          property: 'og:site_name',
          content: appName
        },
        {
          property: 'twitter:title',
          content: appName
        },
        {
          property: 'twitter:description',
          content: appName
        },
        {
          property: 'twitter:image',
          content: imageURL
        },
        {
          property: 'twitter:card',
          content: 'app'
        }
      ]
    }
  }
}
