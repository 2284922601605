import Vue from 'vue'
import _throttle from 'lodash.throttle'
import _debounce from 'lodash.debounce'
import 'intersection-observer'

const VISIBILITY_LEVEL = 0.9
const GLOBAL_EVENT_TIMEOUT = 20
Vue.prototype.$window = new Vue({
  data () {
    return {
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      scrollX: window.scrollX,
      scrollY: window.scrollY,
      iObserver: new IntersectionObserver(this.launchObserve, { threshold: VISIBILITY_LEVEL }),
      timeoutId: null
    }
  },
  created () {
    this.handleScroll = _throttle(() => {
      this.scrollX = window.scrollX
      this.scrollY = window.scrollY
    }, GLOBAL_EVENT_TIMEOUT)
    this.handleResize = _debounce(() => {
      this.innerHeight = window.innerHeight
      this.innerWidth = window.innerWidth
    }, GLOBAL_EVENT_TIMEOUT)
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    launchObserve (entries) {
      if (this.timeoutId) { clearTimeout(this.timeoutId) }
      this.timeoutId = setTimeout(() => {
        entries.forEach(entry => {
          const target = entry.target
          if (entry.isIntersecting) {
            target.onIntersect()
          } else {
            target.onOutersect()
          }
        })
      }, GLOBAL_EVENT_TIMEOUT)
    }
  }
})

export default ({ app, store }) => {
  store.$window = app.$window = Vue.prototype.$window
}
